import React, { Component } from 'react';
import { API, Auth } from 'aws-amplify';
import { Button, Layout, Row, Card } from 'antd';
import { LeftOutlined } from '@ant-design/icons';

import DisableModal from '../../../components/UI/Modals/DisableModal/DisableModal';

const { Header, Content } = Layout;
const ButtonGroup = Button.Group


class Detail extends Component {
    state = {
        loading: true,
        modalVisible: false,
        modalConfirmLoading: false,
        locationName: "",
        locationHotel: "",
        locationCreateDate: null,
        locationLastEditDate: null,
        locationEnabled: false
    }

    idToken = '';

    loadData() {
        this.setState({loading: true});
        const id = this.props.match.params.id;

        API.get('benackoAPI', '/product-locations/' + id, {
                headers: {
                    Authorization: this.idToken
                }
            })
            .then( async response => {
                const name = response[0].name;
                const hotel = await this.getHotelName(response[0].hotelId);
                const createDate = new Date(response[0].created).toLocaleString();
                const lastEditDate = new Date(response[0].updated).toLocaleString();
                const enabled = response[0].enabled;

                this.setState({
                    loading: false,
                    locationName: name,
                    locationHotel: hotel,
                    locationCreateDate: createDate,
                    locationLastEditDate: lastEditDate,
                    locationEnabled: enabled
                });
            })
            .catch( errItemLocation => {
                console.log('Error loading item location:', errItemLocation);

                this.setState({ loading: false });
            });
    }

    async getHotelName(hotelId) {
        let hotel = await API.get('benackoAPI', '/hotels/' + hotelId, { headers: { Authorization: this.idToken } });
        let hotelName = hotel[0].name;

        return hotelName;
    }

    componentDidMount() {
        Auth.currentSession()
            .then( response => {
                this.idToken = response.idToken.jwtToken;

                this.loadData();
            })
            .catch( error => {
                console.log(error);
            });
    }

    goBackHandler = () => {
        this.props.history.goBack();
    }

    goEditHandler = () => {
        this.props.history.push('/app/items/locations/details/' + this.props.match.params.id + '/edit')
    }

    showDisableModal = () => {
        this.setState({modalVisible: true});
    }

    disableItemLocation = () => {
        this.setState({modalConfirmLoading: true});
        const id = this.props.match.params.id;

        API.del('benackoAPI', '/product-locations/' + id, {
                headers: {
                    Authorization: this.idToken
                }
            })
            .then( () => {
                this.setState({
                    modalVisible: false,
                    modalConfirmLoading: false,
                });

                this.props.history.goBack();
            })
            .catch( error => {
                console.log(error);

                this.setState({
                    modalVisible: false,
                    modalConfirmLoading: false,
                });
            });
    }

    closeDisableModal = () => {
        this.setState({modalVisible: false});
    }

    render() {
        return(
            <Layout>
                <Header style={{ background: '#fff', padding: 0 }}>
                    <Row>
                        <ButtonGroup>
                            <Button type="primary" onClick={this.goBackHandler}>
                                <LeftOutlined />Regresar
                            </Button>
                            <Button type="primary" onClick={this.goEditHandler} disabled={!this.state.locationEnabled}>
                                Editar
                            </Button>
                            <Button type="danger" onClick={this.showDisableModal} disabled={!this.state.locationEnabled}>
                                Deshabilitar
                            </Button>
                        </ButtonGroup>
                    </Row>
                </Header>
                <Content style={{ margin: 0, padding: 0, background: '#ffff', paddingTop: 15 }}>
                    <Card loading={this.state.loading} bordered={false} title={this.state.locationName}>
                        <p><strong>Hotel:</strong> {this.state.locationHotel}</p>
                        <p><strong>Fecha de creación:</strong> {this.state.locationCreateDate}</p>
                        <p><strong>Última actualización:</strong> {this.state.locationLastEditDate}</p>
                    </Card>
                </Content>
                <DisableModal 
                    visible={this.state.modalVisible}
                    confirmLoading={this.state.modalConfirmLoading}
                    onCancel={this.closeDisableModal}
                    onOk={this.disableItemLocation}
                    modalText="¿Deseas deshabilitar la ubicación de artículos?"
                />
            </Layout>
        );
    }
}


export default Detail;