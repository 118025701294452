import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Select } from 'antd';

import { switchHotel } from '../../../store/actions';

const { Option } = Select;

const HotelSelector = () => {
    const dispatch = useDispatch();

    const [hotelOptions, setHotelOptions] = useState([]);
    const [isDisabled, setIsDisabled] = useState(false);
    const [isVisible, setIsVisible] = useState('hidden');
    
    const userHotels = useSelector(state => state.base.hotels ?? []);
    const selectedHotel = useSelector(state => state.ui.selectedHotel);
    const isEnabled = useSelector(state => state.ui.hotelSelectorEnabled);

    useEffect(() => {
        if (userHotels != null && selectedHotel != null && selectedHotel > 0) {
            setHotelOptions([]);
            userHotels.forEach(hotel => {
                setHotelOptions(ho => [...ho, <Option key={hotel.Key} value={hotel.Key}>{hotel.Name}</Option>]);
            });
            
            if (hotelOptions.length === 1) {
                setIsDisabled(true);
            }
        }
    }, [userHotels, selectedHotel]);

    useEffect(() => {
        if (isEnabled) {
            setIsVisible('visible');
        } else {
            setIsVisible('hidden');
        }
    }, [isEnabled]);

    return (
        <div style={{width: '100%', visibility: isVisible}}>
            <Select value={selectedHotel} style={{width: '100%'}} bordered={false} onChange={(e) => dispatch(switchHotel(e))} disabled={isDisabled}>
                {hotelOptions}
            </Select>
        </div>
    );
}

export default HotelSelector;
