import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Table,  Button } from 'antd';

import { enableHotelSelector, disableHotelSelector, getUtilityEvents } from '../../../../store/actions';
import ButtonGroup from 'antd/lib/button/button-group';
import SectionTitle from '../../../../components/UI/Panels/SectionTitle/SectionTitle';


const UtilityEvents = ({utility = ''}) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const util = useLocation().search;
    const hid = useSelector(state => state.auth.selectedHotel);
    const allEvents = useSelector(state => state.maintenance.utilityEvents);

    const [utilityType, setUtilityType] = useState(null);
    const [loading, setLoading] = useState(true);

    const dataColumns = [
        {title: 'Marca de tiempo', dataIndex: 'CreatedOn', defaultSortOrder: 'ascend', sorter: (a,b) => a.CreatedOn < b.CreatedOn, render: (a) => a.slice(0, 19).replace('T', ' ')},
        {title: 'Datos', dataIndex: 'Data'},
        {title: 'Unidades', dataIndex: 'Units'}
    ];


    useEffect(() => {
        const utilType = util.substring(6);

        if (utilType === 'Water') {
            setUtilityType('Agua');
        } else if (utilType === 'Electricity') {
            setUtilityType('Electricidad');
        } else if (utilType === 'Gas') {
            setUtilityType('Gas');
        }
    }, [util]);


    useEffect(() => {
        dispatch(enableHotelSelector());

        return () => {
            dispatch(disableHotelSelector());
        };
    }, []);

    useEffect(() => {
        if (hid != null && utility != null) {
            setLoading(true);
            dispatch(getUtilityEvents(hid, utility))
                .catch(err => console.log(err));
        }

        setLoading(false);
    }, [dispatch, hid, utility, allEvents]);


    const goToAdd = () => {
        history.push('/app/maintenance/utilities/create');
    }


    return (
        <Row style={{display: 'flex', flexFlow: 'column', height: '100%'}}>
            <Row style={{width: '100%', height: '80px'}}>
                <Col offset={1} span={3} style={{display: 'flex', alignItems: 'center'}}>
                    <SectionTitle title={utilityType}/>
                </Col>
                <Col offset={14} span={4} style={{display: 'flex', alignItems: 'center'}}>
                    <ButtonGroup>
                        <Button type="primary" onClick={() => history.goBack()} loading={loading}>Regresar</Button>
                        <Button type="primary" onClick={goToAdd} loading={loading}>Agregar</Button>
                    </ButtonGroup>
                </Col>
            </Row>
            <Row style={{width: '100%', flexGrow: 1}}>
                <Col offset={1} span={22}>
                    <Table
                        className="all-users-table"
                        size="middle"
                        loading={loading}
                        columns={dataColumns}
                        rowKey={'SK'}
                        dataSource={allEvents}
                        scroll={{y: 440}}
                        locale={{
                            emptyText: 'No se encontraron datos',
                            cancelSort: 'Haz clic para cancelar ordenar',
                            triggerAsc: 'Haz clic para ordenar - ascendente',
                            triggerDesc: 'Haz clic para ordenar - descendente'
                        }}
                    />
                </Col>
            </Row>
        </Row>
    );
}

export default UtilityEvents;