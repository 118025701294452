import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Row } from 'antd';

import UtilityPanel from '../../components/UI/Panels/UtilityPanel/UtilityPanel';

import { disableHotelSelector, enableHotelSelector, getCurrentUtilities } from '../../store/actions';


const Utilities = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const selectedHotel = useSelector(state => state.auth.selectedHotel);
    const currentUtilities = useSelector(state => state.maintenance.currentUtilities);


    useEffect(() => {
        dispatch(enableHotelSelector());

        return () => {
            dispatch(disableHotelSelector());
        };
    }, []);

    useEffect(() => {
        setLoading(true);
        dispatch(getCurrentUtilities(selectedHotel))
            .then(() => setLoading(false))
            .catch(err => console.log('Error loading current utilities:', err));
    }, [selectedHotel]);


    const goToAdd = () => {
        history.push('/app/maintenance/utilities/create');
    }

    const goToUtility = (utility) => {
        history.push('/app/maintenance/utilities/details?util=' + utility);
    }


    return (
        <Row style={{display: 'flex', flexFlow: 'column', height: '100%'}}>
            <Row style={{marginLeft: 20}}>
                <Col><Button type="primary" onClick={goToAdd} loading={loading}>Agregar</Button></Col>
            </Row>
            <Row style={{width: '95.5%', marginTop: 50, marginLeft: 20, marginRight: 20}}>
                <UtilityPanel
                    loading={loading}
                    utility="Agua"
                    data={currentUtilities.water?.Data ?? ''}
                    unit={currentUtilities.water?.Units ?? ''}
                    timestamp={currentUtilities.water?.CreatedOn ?? ''}
                    onClick={() => goToUtility('Water')}
                />
                <UtilityPanel
                    loading={loading}
                    utility="Electricidad"
                    data={currentUtilities.electricity?.Data ?? ''}
                    unit={currentUtilities.electricity?.Units ?? ''}
                    timestamp={currentUtilities.electricity?.CreatedOn ?? ''}
                    onClick={() => goToUtility('Electricity')}
                />
                <UtilityPanel
                    loading={loading}
                    utility="Gas"
                    data={currentUtilities.gas?.Data ?? ''}
                    unit={currentUtilities.gas?.Units ?? ''}
                    timestamp={currentUtilities.gas?.CreatedOn ?? ''}
                    onClick={() => goToUtility('Gas')}
                />
            </Row>
        </Row>
    );
}

export default Utilities;