import React, { Component } from 'react';
import { API, Auth } from 'aws-amplify';
import { Button, Layout, Row, Cardcon } from 'antd';
import { LeftOutlined } from '@ant-design/icons';

import DisableModal from '../../../components/UI/Modals/DisableModal/DisableModal';

const { Header, Content } = Layout;
const ButtonGroup = Button.Group


class Detail extends Component {
    state = {
        loading: true,
        modalVisible: false,
        modalConfirmLoading: false,
        promotionName: '',
        promotionRoomType: 0,
        promotionDaysCondition: 0,
        promotionPrice: 0,
        promotionCreateDate: null,
        promotionEnabled: false
    }

    idToken = '';

    loadData() {
        this.setState({loading: true});
        const id = this.props.match.params.id;

        API.get('benackoAPI', '/room-promotions/' + id, {
                headers: {
                    Authorization: this.idToken
                }
            })
            .then( async response => {
                this.setState({
                    loading: false,
                    promotionName: response[0].name,
                    promotionRoomType: await this.getRoomTypeName(response[0].roomTypeId),
                    promotionDaysCondition: response[0].daysCondition,
                    promotionPrice: response[0].price,
                    promotionCreateDate: new Date(response[0].created).toLocaleString(),
                    promotionEnabled: response[0].enabled
                });
            })
            .catch( error => {
                console.log(error);
                this.setState({loading: false});
            });
    }

    async getRoomTypeName(roomTypeId) {
        const roomType = await API.get('benackoAPI', '/room-types/' + roomTypeId, { headers: { Authorization: this.idToken } });
        const roomTypeName = roomType[0].name;

        return roomTypeName;
    }

    componentDidMount() {
        Auth.currentSession()
            .then( response => {
                this.idToken = response.idToken.jwtToken;

                this.loadData();
            })
            .catch( error => {
                console.log(error);
            });
    }

    goBackHandler = () => {
        this.props.history.goBack();
    }

    goEditHandler = () => {
        this.props.history.push('/app/rooms/promotions/details/' + this.props.match.params.id + '/edit')
    }

    showDisableModal = () => {
        this.setState({modalVisible: true});
    }

    disablePromotion = () => {
        this.setState({modalConfirmLoading: true});
        
        const id = this.props.match.params.id;

        API.del('benackoAPI', '/room-promotions/' + id, {
                headers: {
                    Authorization: this.idToken
                }
            })
            .then(() => {
                this.setState({
                    modalVisible: false,
                    modalConfirmLoading: false,
                });

                this.props.history.goBack();
            })
            .catch(function (error) {
                console.log(error);

                this.setState({
                    modalVisible: false,
                    modalConfirmLoading: false,
                });
            });
    }

    closeDisableModal = () => {
        this.setState({modalVisible: false});
    }

    render() {
        return(
            <Layout>
                <Header style={{ background: '#fff', padding: 0 }}>
                    <Row>
                        <ButtonGroup>
                            <Button type="primary" onClick={this.goBackHandler}>
                                <LeftOutlined />Regresar
                            </Button>
                            <Button type="primary" onClick={this.goEditHandler} disabled={!this.state.promotionEnabled}>
                                Editar
                            </Button>
                            <Button type="danger" onClick={this.showDisableModal} disabled={!this.state.promotionEnabled}>
                                Deshabilitar
                            </Button>
                        </ButtonGroup>
                    </Row>
                </Header>
                <Content style={{ margin: 0, padding: 0, background: '#ffff', paddingTop: 15 }}>
                    <Card loading={this.state.loading} bordered={false} title={this.state.promotionName}>
                        <p><strong>Tipo de habitación:</strong> {this.state.promotionRoomType}</p>
                        <p><strong>Número de días:</strong> {this.state.promotionDaysCondition}</p>
                        <p><strong>Precio:</strong> ${this.state.promotionPrice}</p>
                        <p><strong>Creada:</strong> {this.state.promotionCreateDate}</p>
                        <p><strong>Estado:</strong> {(this.state.promotionEnabled) ? "Habilitada" : "Deshabilitada"}</p>
                    </Card>
                </Content>
                <DisableModal 
                    visible={this.state.modalVisible}
                    confirmLoading={this.state.modalConfirmLoading}
                    onCancel={this.closeDisableModal}
                    onOk={this.disablePromotion}
                    modalText="¿Deseas deshabilitar la promoción?"
                />
            </Layout>
        );
    }
}

export default Detail;