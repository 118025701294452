import React, { Component } from 'react';
import { API, Auth } from 'aws-amplify';
import { Button, Layout, Row, Card, Tabs, Table } from 'antd';
import { LeftOutlined } from '@ant-design/icons';

import DisableModal from '../../../components/UI/Modals/DisableModal/DisableModal';

const { Header, Content } = Layout;
const ButtonGroup = Button.Group
const TabPane = Tabs.TabPane;


const columns = [{
    title: 'Tipo de renta',
    dataIndex: 'rentType',
    key: 'rentType'
}, {
    title: 'Estado',
    dataIndex: 'status',
    key: 'status'
}];

class Detail extends Component {
    state = {
        loading: true,
        modalVisible: false,
        modalConfirmLoading: false,
        roomName: '',
        roomType: '',
        roomStatus: '',
        roomHotel: '',
        roomCreateDate: null,
        roomLastEditDate: null,
        roomEnabled: false,
        rentTypes: []
    }

    idToken = '';

    loadData() {
        this.setState({loading: true});
        const id = this.props.match.params.id;

        API.get('benackoAPI', '/room-types', {
                headers: {
                    Authorization: this.idToken
                }
            })
            .then(resRT => {
                API.get('benackoAPI', '/v2/rooms/configurations/' + id, {
                        headers: {
                            Authorization: this.idToken
                        }
                    })
                    .then(async res => {
                        var configs = [];
                        if (res !== undefined && res !== null) {
                            res.forEach(type => {
                                const rentType = resRT.find(rt => {return rt.id === type.RentTypeId});
                                const rentTypeName = (rentType === null || rentType === undefined) ? '' : rentType.name;
                                const status = (type.IsAllowed === 1) ? 'Habilitado' : 'Deshabilitado';

                                configs.push({
                                    key: type.RentTypeId,
                                    rentType: rentTypeName,
                                    status: status
                                });
                            });
                        }

                        this.setState({rentTypes: configs});
                    })
                    .catch(err => {console.log('Error loading room rent type configuration', err);});
            })
            .catch(errRT => {console.log('Error loading room types', errRT);});
        

        API.get('benackoAPI', '/rooms/' + id, {
                headers: {
                    Authorization: this.idToken
                }
            })
            .then( async response => {
                const name = response[0].name;
                const hotel = await this.getHotelName(response[0].hotelId);
                const createDate = new Date(response[0].created).toLocaleString();
                const lastEditDate = new Date(response[0].updated).toLocaleString();
                const enabled = response[0].enabled;

                this.setState({
                    loading: false,
                    roomName: name,
                    roomHotel: hotel,
                    roomCreateDate: createDate,
                    roomLastEditDate: lastEditDate,
                    roomEnabled: enabled
                });
            })
            .catch( errRoom => {
                console.log('Error loading room:', errRoom);

                this.setState({ loading: false });
            });
    }

    async getRoomStatusName(roomStatusId) {
        let status = await API.get('benackoAPI', '/room-status/' + roomStatusId, { headers: { Authorization: this.idToken } });
        let statusName = status[0].name;

        return statusName;
    }

    async getHotelName(hotelId) {
        let hotel = await API.get('benackoAPI', '/hotels/' + hotelId, { headers: { Authorization: this.idToken } });
        let hotelName = hotel[0].name;

        return hotelName;
    }

    componentDidMount() {
        Auth.currentSession()
            .then( response => {
                this.idToken = response.idToken.jwtToken;

                this.loadData();
            })
            .catch( error => {
                console.log(error);
            });
    }

    goBackHandler = () => {
        this.props.history.goBack();
    }

    goEditHandler = () => {
        this.props.history.push('/app/rooms/details/' + this.props.match.params.id + '/edit')
    }

    showDisableModal = () => {
        this.setState({modalVisible: true});
    }

    disableRoom = () => {
        this.setState({modalConfirmLoading: true});
        const id = this.props.match.params.id;

        API.del('benackoAPI', '/rooms/' + id, {
                headers: {
                    Authorization: this.idToken
                }
            })
            .then( () => {
                this.setState({
                    modalVisible: false,
                    modalConfirmLoading: false,
                });

                this.props.history.goBack();
            })
            .catch( error => {
                console.log(error);

                this.setState({
                    modalVisible: false,
                    modalConfirmLoading: false,
                });
            });
    }

    closeDisableModal = () => {
        this.setState({modalVisible: false});
    }

    render() {
        return(
            <Layout>
                <Header style={{ background: '#fff', padding: 0 }}>
                    <Row>
                        <ButtonGroup>
                            <Button type="primary" onClick={this.goBackHandler}>
                                <LeftOutlined />Regresar
                            </Button>
                            <Button type="primary" onClick={this.goEditHandler} disabled={!this.state.roomEnabled}>
                                Tipos de renta
                            </Button>
                            <Button type="danger" onClick={this.showDisableModal} disabled={true}>
                                Deshabilitar
                            </Button>
                        </ButtonGroup>
                    </Row>
                </Header>
                <Content style={{ margin: 0, padding: 0, background: '#ffff', paddingTop: 15 }}>
                    <Card loading={this.state.loading} bordered={false} title={this.state.roomName}>
                        <Tabs defaultActiveKey="1" tabPosition="right">
                            <TabPane tab="General" key="1">
                                <p><strong>Hotel:</strong> {this.state.roomHotel}</p>
                                <p><strong>Fecha de creación:</strong> {this.state.roomCreateDate}</p>
                                <p><strong>Última actualización:</strong> {this.state.roomLastEditDate}</p>
                            </TabPane>
                            <TabPane tab="Tipos de renta" key="2">
                                <Table
                                    columns={columns}
                                    dataSource={this.state.rentTypes}
                                    size="middle"
                                    locale={{emptyText: 'No se encontraron tipos de renta'}}
                                />
                            </TabPane>
                        </Tabs>
                    </Card>
                </Content>
                <DisableModal 
                    visible={this.state.modalVisible}
                    confirmLoading={this.state.modalConfirmLoading}
                    onCancel={this.closeDisableModal}
                    onOk={this.disableRoom}
                    modalText="¿Deseas deshabilitar la habitación?"
                />
            </Layout>
        );
    }
}


export default Detail;