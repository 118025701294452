import { Auth, API } from 'aws-amplify';

class UserService {

    static async getUsers(cid, hid) {
        const idToken = await Auth.currentSession().then(data => {return data.idToken.jwtToken;});
        
        return API.get('usersAPI', '/users?cid=' + cid + '&hid=' + hid, {headers: {Authorization: idToken}})
            .then(response => {
                if (response == null) {
                    throw Error(response ?? 'Respuesta no válida del servidor');
                } else {
                    return response;
                }
            })
            .catch(err => {throw Error(err ?? 'Respuesta no válida del servidor');});
    }

    static async getUserDetails(cid, uid) {
        const idToken = await Auth.currentSession().then(data => {return data.idToken.jwtToken;});
        
        return API.get('usersAPI', '/users/' + uid + '?cid=' + cid, {headers: {Authorization: idToken}})
            .then(response => {
                if (response == null) {
                    throw Error(response ?? 'Respuesta no válida del servidor');
                } else {
                    return response;
                }
            })
            .catch(err => {throw Error(err ?? 'Respuesta no válida del servidor');});
    }

    static async editUserDetails(cid, uid, property, value) {
        const idToken = await Auth.currentSession().then(data => {return data.idToken.jwtToken;});
        const sourceUser = await Auth.currentAuthenticatedUser();

        const body = {
            pn: property,
            pv: value,
            st: 'user',
            sid: sourceUser.username
        };

        return API.put('usersAPI', '/users/' + uid + '?cid=' + cid, {headers: {Authorization: idToken}, body: body})
            .then(response => {
                if (response == null) {
                    throw Error(response ?? 'Respuesta no válida del servidor');
                } else {
                    return response;
                }
            })
            .catch(err => {throw Error(err ?? 'Respuesta no válida del servidor');});
    }

    static async disableUser(cid, uid, username, adminUser) {
        const idToken = await Auth.currentSession().then(data => {return data.idToken.jwtToken;});
        const sourceUser = await Auth.currentAuthenticatedUser();

        const body = {
            username: username,
            adus: adminUser,
            st: 'user',
            sid: sourceUser.username
        };

        return API.put('usersAPI', '/users/' + uid + '/disable?cid=' + cid, {headers: {Authorization: idToken}, body: body})
            .then(response => {
                if (response == null) {
                    throw Error(response ?? 'Respuesta no válida del servidor');
                } else {
                    return response;
                }
            })
            .catch(err => {throw Error(err ?? 'Respuesta no válida del servidor');});
    }

    static async createUser(cid, username, firstName, lastName, roleKey, hotels) {
        const idToken = await Auth.currentSession().then(data => {return data.idToken.jwtToken;});
        const sourceUser = await Auth.currentAuthenticatedUser();

        const adus = !(roleKey === 8 || roleKey === 9);

        const body = {
            cid: cid,
            username: username,
            fn: firstName,
            ln: lastName,
            rid: roleKey,
            adus: adus,
            bsns: hotels,
            st: 'user',
            sid: sourceUser.username
        };

        return API.post('usersAPI', '/users', {headers: {Authorization: idToken}, body: body})
            .then(response => {
                if (response == null) {
                    throw Error('Respuesta no válida del servidor');
                } else {
                    return response;
                }
            })
            .catch(err => {throw Error(err ?? 'Respuesta no válida del servidor');});
    }
};

export default UserService;