import React from 'react';
import { Row, Col, Select } from 'antd';

const { Option } = Select;


const UtilitySelector = ({onChange = () => console.log('No action has been set')}) => {
    
    return (
        <Row style={{width: '100%', height: '60px'}}>
            <Col span={5} style={{display: 'flex', alignItems: 'center', height: '100%'}}>
                <span style={{marginLeft: 40}}>Servicio</span>
            </Col>
            
            <Col span={19} style={{display: 'flex', alignItems: 'center', height: '100%'}}>
                <Select style={{marginLeft: 20, width: '400px'}} onChange={(ev) => onChange(ev)}>
                    <Option key={'Water'} value={'Water'}>Agua</Option>
                    <Option key={'Electricity'} value={'Electricity'}>Electricidad</Option>
                    <Option key={'Gas'} value={'Gas'}>Gas</Option>
                </Select>
            </Col>
        </Row>
    );
}

export default UtilitySelector;