import React, { Component } from 'react';
import { API } from 'aws-amplify';
import { Button, Form, InputNumber, Layout, Row, Col, Spin } from 'antd'
import { LeftOutlined, TableOutlined } from '@ant-design/icons';

const { Header, Content } = Layout;
const ButtonGroup = Button.Group;


class FolioEdit extends Component {
    state = {
        loading: false,
        folioNumber: 0
    }

    componentDidMount() {
        this.loadData();
    }

    loadData() {
        const id = this.props.match.params.id;

        this.setState({loading: true});

        API.get('benackoAPI', '/system/tickets/' + id)
            .then( (response) => {
                const folioNumber = response[0].folio_number;

                this.setState({folioNumber: folioNumber, loading: false});
            })
            .catch( error => {
                console.log(error);

                this.setState({loading: false});
            });
    }

    editFolioHandler = (event) => {
        this.setState({loading: true});

        const id = this.props.match.params.id;

        API.put('benackoAPI', '/system/tickets/' + id, {
            body: {
                folioNumber: this.state.folioNumber
            }
        })
        .then(() => {
            this.setState({loading: false});
            this.props.history.goBack();
        })
        .catch(function (error) {
            this.setState({loading: false});
            console.log(error);
        });
    }

    goBackHandler = () => {
        this.props.history.goBack();
    }

    render() {
        return(
            <Layout>
                <Header style={{background: '#fff', padding: 0, paddingLeft: 20}}>
                    <Row>
                        <ButtonGroup>
                            <Button type="primary" onClick={this.goBackHandler}>
                                <LeftOutlined />Regresar
                            </Button>
                        </ButtonGroup>
                    </Row>
                </Header>
                <Content style={{margin: 0, padding: 0, background: '#ffff', paddingLeft: 20}}>
                    <Spin spinning={this.state.loading} size="large">
                        <Form className="login-form">
                            <Row style={{marginTop: 10}}>
                                <Col span={20} offset={2}>
                                    <p>Serie:</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={20} offset={2}>
                                    <InputNumber defaultValue={this.state.folioNumber} min={0} onChange={(e) => this.setState({folioNumber: e})} prefix={<TableOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Serie" />
                                </Col>
                            </Row>
                            <Row style={{marginTop: 20}}>
                                <Col span={4} offset={2}>
                                    <Button type="primary" 
                                        onClick={this.editFolioHandler}
                                        disabled={this.state.folioNumber == null} className="login-form-button"
                                    >
                                        Editar
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </Spin>
                </Content>
            </Layout>
        );
    }
}

export default FolioEdit;