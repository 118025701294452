import React, { Component } from 'react';
import { API, Auth } from 'aws-amplify';
import { Button, Col, Layout, Row, Card, InputNumber } from 'antd';
import { LeftOutlined } from '@ant-design/icons';

const { Header, Content } = Layout;
const ButtonGroup = Button.Group


class Create extends Component {
    state = {
        loading: true,
        scheduledEntity: null,
        entityId: null,
        hotelId: null,
        scheduleType: null,
        scheduleDefaultValue: null,
        scheduleName: null
    }

    idToken = '';

    async loadData() {
        this.setState({loading: true});

        const id = parseInt(this.props.match.params.id, 10);
        const type = parseInt(this.props.match.params.type, 10);
        
        const scheduledEntity = 2;
        const scheduleType = type;
        const entityId = id;
        const hotelId = await this.getRoomTypeHotelId( id );

        var scheduleName;
        var scheduleDefaultValue;
        if (scheduleType === 2) {
            scheduleName = 'Horas';
            scheduleDefaultValue = 4;
        }

        if (scheduleType === 3) {
            scheduleName = 'Precio';
            scheduleDefaultValue = 100;
        }

        this.setState({
            scheduledEntity: scheduledEntity,
            entityId: entityId,
            scheduleType: scheduleType,
            hotelId: hotelId,
            scheduleName: scheduleName,
            scheduleDefaultValue: scheduleDefaultValue,
            loading: false
        });
    }

    async getRoomTypeHotelId( roomTypeId ) {
        let roomType = await API.get('benackoAPI', '/room-types/' + roomTypeId, { headers: { Authorization: this.idToken } } );
        let hotelId = roomType[0].hotelId;

        return hotelId;
    }

    componentDidMount() {
        Auth.currentSession()
            .then( response => {
                this.idToken = response.idToken.jwtToken;

                this.loadData();
            })
            .catch( error => {
                console.log(error);
            });
    }

    reloadData = () => {
        this.loadData();
    }

    goBackHandler = () => {
        this.props.history.goBack();
    }

    createPayload(scheduleType) {
        if (scheduleType === 2) {
            const payload = [
                { day: 1, hours: document.getElementById('day1').value },
                { day: 2, hours: document.getElementById('day2').value },
                { day: 3, hours: document.getElementById('day3').value },
                { day: 4, hours: document.getElementById('day4').value },
                { day: 5, hours: document.getElementById('day5').value },
                { day: 6, hours: document.getElementById('day6').value },
                { day: 7, hours: document.getElementById('day7').value }
            ]

            return payload;
        } else if (scheduleType === 3) {
            const payload = [
                { day: 1, price: document.getElementById('day1').value },
                { day: 2, price: document.getElementById('day2').value },
                { day: 3, price: document.getElementById('day3').value },
                { day: 4, price: document.getElementById('day4').value },
                { day: 5, price: document.getElementById('day5').value },
                { day: 6, price: document.getElementById('day6').value },
                { day: 7, price: document.getElementById('day7').value }
            ]

            return payload;
        }
    }

    storeRoomSchedule = () => {
        this.setState({loading: true});

        const payload = this.createPayload(this.state.scheduleType);

        API.post('benackoAPI', '/room-schedules', {
                body: {
                    scheduledEntity: this.state.scheduledEntity,
                    entityId: this.state.entityId,
                    hotelId: this.state.hotelId,
                    scheduleType: this.state.scheduleType,
                    days: payload
                },
                headers: {
                    Authorization: this.idToken
                }
            })
            .then( () => {
                this.setState({loading: false});
                this.props.history.goBack();
            })
            .catch( errRoomTypeSched => {
                console.log('Error creating room type schedule:', errRoomTypeSched);
                this.setState({loading: false});
            });
    }

    render() {
        return(
            <Layout>
                <Header style={{ background: '#fff', padding: 0 }}>
                    <Row>
                        <ButtonGroup>
                            <Button type="primary" onClick={this.goBackHandler}>
                                <LeftOutlined />Regresar
                            </Button>
                        </ButtonGroup>
                    </Row>
                </Header>
                <Content style={{ margin: 0, padding: 0, background: '#ffff', paddingTop: 15 }}>
                    <Card loading={this.state.loading} bordered={false} title={this.state.scheduleName}>
                        <Row style={{ marginBottom: 20 }}>
                            <Col span={12}>
                                Lunes:
                            </Col>
                            <Col span={12}>
                                <InputNumber id="day1" min={0} step={1} defaultValue={this.state.scheduleDefaultValue} />
                            </Col>
                        </Row>
                        <Row style={{ marginBottom: 20 }}>
                            <Col span={12}>
                                Martes:
                            </Col>
                            <Col span={12}>
                                <InputNumber id="day2" min={0} step={1} defaultValue={this.state.scheduleDefaultValue} />
                            </Col>
                        </Row>
                        <Row style={{ marginBottom: 20 }}>
                            <Col span={12}>
                                Miércoles:
                            </Col>
                            <Col span={12}>
                                <InputNumber id="day3" min={0} step={1} defaultValue={this.state.scheduleDefaultValue} />
                            </Col>
                        </Row>
                        <Row style={{ marginBottom: 20 }}>
                            <Col span={12}>
                                Jueves:
                            </Col>
                            <Col span={12}>
                                <InputNumber id="day4" min={0} step={1} defaultValue={this.state.scheduleDefaultValue} />
                            </Col>
                        </Row>
                        <Row style={{ marginBottom: 20 }}>
                            <Col span={12}>
                                Viernes:
                            </Col>
                            <Col span={12}>
                                <InputNumber id="day5" min={0} step={1} defaultValue={this.state.scheduleDefaultValue} />
                            </Col>
                        </Row>
                        <Row style={{ marginBottom: 20 }}>
                            <Col span={12}>
                                Sábado:
                            </Col>
                            <Col span={12}>
                                <InputNumber id="day6" min={0} step={1} defaultValue={this.state.scheduleDefaultValue} />
                            </Col>
                        </Row>
                        <Row style={{ marginBottom: 20 }}>
                            <Col span={12}>
                                Domingo:
                            </Col>
                            <Col span={12}>
                                <InputNumber id="day7" min={0} step={1} defaultValue={this.state.scheduleDefaultValue} />
                            </Col>
                        </Row>
                            
                        <Button type="primary" style={{ marginTop: 30 }} onClick={this.storeRoomSchedule}>
                            Guardar calendario
                        </Button>
                    </Card>
                </Content>
            </Layout>
        );
    }
}


export default Create;