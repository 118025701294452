import React, { Component } from 'react';
import { API } from 'aws-amplify';
import { Button, Form, Input, Layout, Row, Col, Spin } from 'antd';
import { LeftOutlined, TableOutlined } from '@ant-design/icons';

const { Header, Content } = Layout;
const ButtonGroup = Button.Group;


class ItemEdit extends Component {
    state = {
        loading: false,
        standardQuantity: 0,
        minimumQuantity: 0,
        //itemName: ''
    }

    loadData() {
        const id = this.props.match.params.id;

        this.setState({loading: true});

        API.get('benackoAPI', '/inventory-items/' + id)
            .then( async response => {
                const name = await this.getItemName(response[0].itemId);
                const stdQty = response[0].standardQuantity;
                const minQty = response[0].minimumQuantity;

                this.setState({itemName: name, standardQuantity: stdQty, minimumQuantity: minQty, loading: false});
            })
            .catch( error => {
                    console.log(error);
                    this.setState({loading: false});
            });
    }

    componentDidMount() {
        this.loadData();
    }

    async getItemName(itemId) {
        let item = await API.get('benackoAPI', '/items/' + itemId, { headers: { Authorization: this.idToken } });
        let itemName = item[0].name;

        return itemName;
    }

    editInventoryItemHandler = () => {
        this.setState({loading: true});

        
        API.put('benackoAPI', '/inventory-items/' + this.props.match.params.id, {
                body: {
                    standardQuantity: parseInt(this.state.standardQuantity, 10),
                    minimumQuantity: parseInt(this.state.minimumQuantity, 10)
                }
            })
            .then(() => {
                this.setState({loading: false});
                this.props.history.goBack();
            })
            .catch(function (error) {
                this.setState({loading: false});
                console.log(error);
            });
    }

    goBackHandler = () => {
        this.props.history.goBack();
    }

    render() {
        return(
            <Layout>
                <Header style={{width: '100%', background: '#fff', padding: 0, paddingLeft: 20}}>
                    <Row>
                        <ButtonGroup>
                            <Button type="primary" onClick={this.goBackHandler}>
                                <LeftOutlined />Regresar
                            </Button>
                        </ButtonGroup>
                    </Row>
                </Header>
                <Content style={{width: '100%', margin: 0, padding: 0, background: '#fff', paddingLeft: 20}}>
                    <Spin spinning={this.state.loading} size="large">
                        <Form className="login-form">
                            <Row style={{marginTop: 10}}>
                                <Col span={20} offset={2}>
                                    <p>Cantidad estándar:</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={20} offset={2}>
                                    <Input style={{width: '100%'}} onChange={(e) => this.setState({standardQuantity: e.target.value})} prefix={<TableOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Cantidad estándar" />
                                </Col>
                            </Row>
                            <Row style={{marginTop: 10}}>
                                <Col span={20} offset={2}>
                                    <p>Cantidad mínima:</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={20} offset={2}>
                                    <Input style={{width: '100%'}} onChange={(e) => this.setState({minimumQuantity: e.target.value})} prefix={<TableOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Cantidad mínima" />
                                </Col>
                            </Row>
                            <Row style={{marginTop: 20}}>
                                <Col span={4} offset={2}>
                                    <Button type="primary" 
                                        onClick={this.editInventoryItemHandler}
                                        disabled={this.state.itemName == null || this.state.itemName.length <= 1} className="login-form-button"
                                    >
                                        Editar
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </Spin>
                </Content>
            </Layout>
        );
    }
}

export default ItemEdit;