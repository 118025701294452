import { Auth } from 'aws-amplify';
import UserService from '../../services/users';
import * as actionTypes from './actionTypes';


export const loadAuthUserData = () => {
    return dispatch => {
        return new Promise(async (resolve, reject) => {
            let username = '';
            let uid = '';
            let cid = '';
            let dbid = '';

            await Auth.currentAuthenticatedUser()
                .then(user => {
                    username = user.attributes.email;
                    uid = user.username;
                    cid = user.attributes['custom:cid']
                    dbid = user.attributes['custom:dbid'];
                    dbid = parseInt(dbid);
                })
                .catch(err => {
                    dispatch(loadAuthUserDataFail(err.message));
                    reject(err.message);
                });

            if (username == null || uid == null || cid == null) {
                const errorMessage = 'Could not get authenticated user data';
                dispatch(loadAuthUserDataFail(errorMessage));
                reject(errorMessage);
            }
            

            let firstName = '';
            let lastName = '';
            let roleKey = 0;
            let roleName = '';
            let roleLevel = 10000;
            let entitlements = [];
            let hotels = [];
            let selectedHotel = {};

            await UserService.getUserDetails(cid, dbid)
                .then(user => {
                    if (!user?.Enabled) {
                        const errorMessage = 'User is disabled';
                        dispatch(loadAuthUserDataFail(errorMessage));
                        reject(errorMessage);
                    }

                    firstName = user.FirstName;
                    lastName = user.LastName;
                    roleKey = user.RoleKey;
                    roleName = user.RoleName;
                    roleLevel = user.RoleLevel;
                    entitlements = user.Entitlements;
                    hotels = user.Hotels;
                    selectedHotel = hotels[0];

                    return user;
                })
                .catch(err => {
                    dispatch(loadAuthUserDataFail(err.message));
                    reject(err.message);
                });

            const initials = firstName.charAt(0) + lastName.charAt(0);

            dispatch(loadAuthUserDataSuccess(uid, dbid, username, firstName, lastName,
                initials, roleKey, roleName, roleLevel, entitlements, hotels, cid));

            const hid = parseInt(selectedHotel.Key) ?? 1;
            resolve(hid);
        });
    };
};

export const loadAuthUserDataSuccess = (uid, dbid, username, firstName, lastName, initials, roleKey, roleName, roleLevel, entitlements, hotels, cid) => {
    return {
        type: actionTypes.GET_AUTH_USER_DATA,
        uid: uid,
        dbid: dbid,
        username: username,
        firstName: firstName,
        lastName: lastName,
        initials: initials,
        roleKey: roleKey,
        roleName: roleName,
        roleLevel: roleLevel,
        entitlements: entitlements,
        hotels: hotels,
        cid: cid
    }
};

export const loadAuthUserDataFail = (error) => {
    return {
        type: actionTypes.GET_AUTH_USER_DATA_FAIL,
        error: error
    }
};


export const clearAuthUserData = () => {
    return dispatch => {
        return new Promise((resolve) => {
            dispatch(clearAuthUserDataComplete());
            resolve();
        });
    };
};

export const clearAuthUserDataComplete = () => {
    return {
        type: actionTypes.CLEAR_AUTH_USER_DATA
    }
};
