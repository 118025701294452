import React, { Component } from 'react';
import { API } from 'aws-amplify';
import { Button, Layout, Row, Table } from 'antd'


const { Header, Content } = Layout;
const ButtonGroup = Button.Group;

const columns = [
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name'
    },
    {
        title: 'Created on',
        dataIndex: 'createDate',
        key: 'createDate'
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status'
    }
];


class RoomPermissions extends Component {
    state = {
        loading: true,
        data: []
    }

    loadData() {
        this.setState({loading: true});

        
        API.get('benackoAPI', '/room-permissions')
            .then( response => {
                var permissions = [];

                for (var permission of response) {
                    permissions.push({
                        key: permission.id,
                        name: permission.name,
                        createDate: new Date(permission.createdOn).toLocaleString(),
                        status: permission.enabled ? 'Enabled' : 'Disabled'
                    });
                }

                this.setState({data: permissions, loading: false});
            })
            .catch( error => {
                console.log(error);
                this.setState({loading: false});
            });
    }

    createPermissionHandler = () => {
        this.props.history.push('/app/rooms/permissions/create');
    }

    componentDidMount() {
        this.loadData();
    }

    render() {
        return(
            <Layout>
                <Header style={{ background: '#fff', padding: 0 }}>
                    <Row>
                        <ButtonGroup>
                            <Button type="primary" onClick={this.createPermissionHandler}>Create</Button>
                            <Button type="primary" 
                                onClick={this.loadData.bind(this)} 
                                disabled={this.state.loading}
                            >
                                Refresh
                            </Button>
                        </ButtonGroup>
                    </Row>
                </Header>
                <Content style={{ margin: 0, padding: 0, background: '#fff' }}>
                <Table 
                        columns={columns} 
                        dataSource={this.state.data}
                        onRow={ (record) => {
                            return {
                                onClick: () => {
                                    this.props.history.push('/app/rooms/permissions/details/' + record.key);
                                }
                            };
                        }}
                        size="middle" 
                        pagination={{
                            pageSize: 8,
                            showTotal: (total) => `Total ${total} items`
                        }}
                        locale={{
                            emptyText: 'No room permissions retrieved'
                        }} 
                        loading={{
                            spinning: this.state.loading,
                            delay: 500,
                            size: 'large'
                        }}/>
                </Content>
            </Layout>
        );
    }
}

export default RoomPermissions;