import { API } from "aws-amplify";


class MaintenanceService {

    static getUtilitiesCurrentValue(hid) {
        let url = '/maintenance/utilities/' + hid + '/latest'

        return API.get('benackoAPI', url)
                    .then(response => {
                        if (response.hasOwnProperty('data')) {
                            if (response.statusCode === 400) {
                                throw new Error('Request sent to server is not valid');
                            } else {
                                if (response.statusCode === 200) {
                                    return response.data;
                                } else {
                                    throw new Error(response.data.code);
                                }
                            }
                        } else {
                            throw new Error('Invalid response from server');
                        }
                    })
                    .catch(err => Promise.reject(err));
    }

    static createUtilityEvent(hid, utility, data) {
        const date = new Date();
        const today = new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString();
        
        let url = '/maintenance/utilities/' + hid;

        let units = '';
        switch (utility) {
            case 'Water':
                units = 'm^3'
                break;
            case 'Electricity':
                units = 'kW';
                break;
            case 'Gas':
                units = '%';
                break;
            default:
                break;
        }

        const newEvent = {
            utility: utility,
            data: data,
            units: units,
            date: today
        };

        return API.post('benackoAPI', url, {
                        body: newEvent
                    })
                    .then(response => {
                        if (response.hasOwnProperty('data')) {
                            if (response.statusCode === 400) {
                                throw new Error('Request sent to server is not valid');
                            } else {
                                if (response.statusCode === 200) {
                                    return response.data;
                                } else {
                                    throw new Error(response.data.code);
                                }
                            }
                        } else {
                            throw new Error('Invalid response from server');
                        }
                    })
                    .catch(err => Promise.reject(err));
    }

    static getUtilityEvents(hid, utility) {
        const today = new Date();
        const processedToday = today.getFullYear() + '-' + ("0" + (today.getMonth() + 1)).slice(-2) + '-' + ("0" + today.getDate()).slice(-2);

        let twoMonthsAgo = new Date();
        twoMonthsAgo.setDate(today.getDate() - 60);
        const processedRange = twoMonthsAgo.getFullYear() + '-' + ("0" + (twoMonthsAgo.getMonth() + 1)).slice(-2) + '-' + ("0" + twoMonthsAgo.getDate()).slice(-2);
        let url = '/maintenance/utilities/' + hid + '?utility=' + utility + '&rangeDate=' + processedToday + '&date=' + processedRange;

        return API.get('benackoAPI', url)
                    .then(response => {
                        if (response.hasOwnProperty('data')) {
                            if (response.statusCode === 400) {
                                throw new Error('Request sent to server is not valid');
                            } else {
                                if (response.statusCode === 200 && response.data.hasOwnProperty('events')) {
                                    return response.data.events;
                                } else {
                                    throw new Error(response.data.code);
                                }
                            }
                        } else {
                            throw new Error('Invalid response from server');
                        }
                    })
                    .catch(err => Promise.reject(err));
    }
}

export default MaintenanceService;