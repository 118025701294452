import React, { Component } from 'react';
import { connect } from 'react-redux';
import { API, Auth } from 'aws-amplify';
import { Button, Layout, Row, Steps, Input, InputNumber, Col, Spin, Select, Table } from 'antd';
import { LeftOutlined } from '@ant-design/icons';

import InventoryTransactionTicket from '../../../components/UI/Modals/InventoryTransactionTicket/InventoryTransactionTicket';

const { Header, Content } = Layout;
const ButtonGroup = Button.Group
const Step = Steps.Step;
const Option = Select.Option;

const printColumns = [{
    title: 'Artículo',
    dataIndex: 'item'
}, {
    title: 'Cantidad mermada',
    dataIndex: 'suppliedQuantity'
}, {
    title: 'Cantidad final en inventario',
    dataIndex: 'inventoryQuantity'
}, {
    title: 'Razón de merma',
    dataIndex: 'descr'
}];


class Deplete extends Component {
    state = {
        loadingStepOne: false,
        loadingStepTwo: false,
        loadingStepThree: false,
        loadingSupply: false,
        currentStep: 0,
        inventoryItems: [],
        itemIds: new Map(),
        selectedItems: [],
        tableItems: [],
        suppliedItems: new Map(),
        suppliedItemsNames: new Map(),
        suppliedAmounts: new Map(),
        suppliedDescriptions: new Map(),
        disableSupplyButton: true,
        transactionTicket: false,
        transactionTicketItems: [],
        ticketHotel: '',
        ticketInventory: ''
    };

    loadData() {
        this.setState({loadingStepOne: true});

        API.get('benackoAPI', '/v2/inventory-items?inventoryId=' + this.props.match.params.id, {
                headers: {
                    Authorization: this.idToken
                }
            })
            .then( itemsRes => {
                var items = [];
                var itemIds = new Map();

                itemsRes.forEach( async item => {
                    items.push(<Option key={item.id}>{item.name}</Option>);
                    itemIds.set(item.id, item.itemId);
                });

                this.setState({inventoryItems: items, itemIds: itemIds, loadingStepOne: false});
            })
            .then(() => {
                API.get('benackoAPI', '/inventories/' + this.props.match.params.id, {
                        headers: {
                            Authorization: this.idToken
                        }
                    })
                    .then(inventoryRes => {
                        this.setState({
                            ticketHotel: this.props.hotels[inventoryRes[0].hotelId],
                            ticketInventory: inventoryRes[0].name,
                            loadingStepOne: false
                        });
                    })
                    .catch( errInventory => {
                        console.log('Error loading inventory: ', errInventory);
                        this.setState({ loadingStepOne: false });
                    });
            })
            .catch( errInventoryItems => {
                console.log('Error loading inventory items: ', errInventoryItems);
                this.setState({ loadingStepOne: false });
            });
    }

    async getItemName(itemId) {
        let item = await API.get('benackoAPI', '/items/' + itemId, { headers: { Authorization: this.idToken } });
        let itemName = item[0].name;

        return itemName;
    }

    async getItemCurrentQuantity(inventoryItemId) {
        let item = await API.get('benackoAPI', '/inventory-items/' + inventoryItemId, { headers: { Authorization: this.idToken } });
        let itemQty = item[0].quantity;

        return itemQty;
    }

    async getInventoryItemItemName(inventoryItemId) {
        let item = await API.get('benackoAPI', '/inventory-items/' + inventoryItemId, { headers: { Authorization: this.idToken } });
        let itemName = await this.getItemName(item[0].itemId);

        return itemName;
    }

    async getInventoryItemItemKey(inventoryItemId) {
        let item = await API.get('benackoAPI', '/inventory-items/' + inventoryItemId, { headers: { Authorization: this.idToken } });
        let itemId = item[0].itemId;

        return itemId;
    }

    componentDidMount() {
        Auth.currentSession()
            .then( response => {
                this.idToken = response.idToken.jwtToken;

                this.loadData();
            })
            .catch( error => {
                console.log(error);
            });
    }

    clearSupplyMap = () => {
        const myMap = new Map();
        myMap.clear();

        const myNamesMap = new Map();
        myNamesMap.clear();

        const suppliedMap = new Map();
        suppliedMap.clear();

        const descrMap = new Map();
        descrMap.clear();

        this.setState({suppliedItems: myMap, suppliedItemsNames: myNamesMap, suppliedAmounts: suppliedMap, suppliedDescriptions: descrMap});
    }

    goBackHandler = () => {
        this.props.history.goBack();
    }

    selectItemHandler = (value) => {
        this.setState({selectedItems: value});
    }

    goToSecondStep = async () => {
        this.setState({loadingStepTwo: true, loadingSupply: true});
        const items = await Promise.all(
            this.state.selectedItems.map( async item => {
                const itemKey = item.toString();
                const itemQty = await this.getItemCurrentQuantity(item);
                const itemName = await this.getInventoryItemItemName(item);

                return (
                    <Row key={itemKey} style={{ marginTop: 10 }}>
                        <Col span={6} offset={2}>{itemName}</Col>
                        <Col span={4}>{itemQty}</Col>
                        <Col span={6}>
                            <InputNumber
                                key={itemKey}
                                defaultValue="0"
                                step={1}
                                min={0}
                                max={itemQty}
                                onChange={(e) => {
                                    (e === '' || e < 0 || isNaN(e)) ? this.setState({disableSupplyButton: true}) : this.setState({disableSupplyButton: false});
                                    const negE = (e * -1);
                                    this.updateSupplyQuantityHandler(negE + itemQty, itemKey, itemName, e);
                                }}
                            />
                        </Col>
                        <Col span={6}>
                            <Input
                                key={itemKey}
                                onChange={(e) => {this.updateDescription(e, itemKey)}}
                            />
                        </Col>
                    </Row>
                );
            })
        );
        
        this.setState({tableItems: items, currentStep: 1, loadingStepTwo: false, loadingSupply: false});
    }

    updateSupplyQuantityHandler = (value, key, name, suppliedAmount) => {
        var supplyMap = this.state.suppliedItems;
        supplyMap.set(key, value);

        var namesMap = this.state.suppliedItemsNames;
        namesMap.set(key, name);

        var amountsMap = this.state.suppliedAmounts;
        amountsMap.set(key, suppliedAmount);

        this.setState({suppliedItems: supplyMap, suppliedItemsNames: namesMap, suppliedAmounts: amountsMap});
    }

    updateDescription = (event, key) => {
        var descrMap = this.state.suppliedDescriptions;
        descrMap.set(key, event.target.value);

        this.setState({suppliedDescriptions: descrMap});
    }

    goPreviousStep = () => {
        this.clearSupplyMap();

        const newStep = this.state.currentStep - 1;
        this.setState({currentStep: newStep});
    }

    takeOutFromInventory = async (event) => {
        event.preventDefault();
        
        this.setState({loadingStepThree: true, loadingSupply: true});
        
        var transactionTicketItems = [];
        for (const item of this.state.suppliedItems) {
            transactionTicketItems.push({
                key: item[0],
                item: this.state.suppliedItemsNames.get(item[0]),
                suppliedQuantity: this.state.suppliedAmounts.get(item[0]),
                inventoryQuantity: parseInt(item[1], 10),
                descr: (this.state.suppliedDescriptions.get(item[0]) === undefined) ? '-' : this.state.suppliedDescriptions.get(item[0])
            });

            await API.put('benackoAPI','/inventory-items/' + item[0], {
                body: {
                    quantity: parseInt(item[1], 10),
                    supplyFlag: true
                },
                headers: {
                    Authorization: this.idToken
                }
            })
            .then(() => {
                const inventoryAction = {
                    inventoryId: parseInt(this.props.match.params.id, 10),
                    action: 'mermar artículo',
                    itemId: this.state.itemIds.get(parseInt(item[0], 10)),
                    itemName: this.state.suppliedItemsNames.get(item[0]),
                    quantity: this.state.suppliedAmounts.get(item[0]),
                    user: this.props.username,
                    descr: (this.state.suppliedDescriptions.get(item[0]) === undefined) ? '-' : this.state.suppliedDescriptions.get(item[0])
                };

                API.post('benackoAPI', '/v2/inventory-actions', {
                        body: inventoryAction
                    })
                    .then(() => {
                        this.setState({loading: false});
                    })
                    .catch(errInvAction => {
                        console.log('Error loading inventory action:', errInvAction);
                        this.setState({loading: false});
                    });
            })
            .catch(function (error) {
                this.setState({loading: false});
                console.log(error);
            });
        }

        this.setState({loadingSupply: false, loadingStepThree: false, currentStep: 2, transactionTicketItems: transactionTicketItems});
    }

    goToInventoriesComponent = () => {
        this.props.history.goBack();
    }

    printTransactionTicket = () => {
        this.setState({
            transactionTicket: true
        });
    }

    closeTransactionTicket = () => {
        this.setState({
            transactionTicket: false
        });
    }

    render() {

        const stepOne = (
            <Spin spinning={this.state.loadingStepOne} size="large">
                <Row>
                    <Select
                        mode="multiple"
                        style={{ width: '100%' }}
                        placeholder="Selecciona artículos a mermar"
                        onChange={this.selectItemHandler}
                    >
                        {this.state.inventoryItems}
                    </Select>
                </Row>
                <Row style={{ marginTop: 20 }}>
                    <Button type="primary" onClick={this.goToSecondStep} disabled={(this.state.selectedItems.length <= 0) ? true : false}>Siguiente</Button>
                </Row>
            </Spin>
        );

        const stepTwo = (
            <Spin spinning={this.state.loadingStepTwo} size="large">
                <Row style={{ marginBottom: 20 }}>
                    <Col span={6} offset={2}><strong>Artículo</strong></Col>
                    <Col span={4}><strong>Cantidad actual</strong></Col>
                    <Col span={6}><strong>Cantidad a mermar</strong></Col>
                    <Col span={6}><strong>Razón de merma</strong></Col>
                </Row>
                <Row>
                    {this.state.tableItems}
                </Row>
                <Row style={{ marginTop: 40 }}>
                    <ButtonGroup>
                        <Button type="primary" onClick={this.goPreviousStep}>Regresar</Button>
                        <Button type="default" onClick={this.takeOutFromInventory} disabled={this.state.disableSupplyButton}>Mermar</Button>
                    </ButtonGroup>
                </Row>
            </Spin>
        );

        const transactionItemsTable = (
            <Table
                columns={printColumns}
                dataSource={this.state.transactionTicketItems}
                size="large"
                pagination={false}
            />
        );

        const stepThree = (
            <Spin spinning={this.state.loadingStepThree} size="large">
                <Row>
                    {transactionItemsTable}
                </Row>
                <Row style={{ marginTop: 40 }}>
                    <ButtonGroup>
                        <Button type="primary" onClick={this.goToInventoriesComponent}>Ir a inventario</Button>
                        <Button type="default" onClick={this.printTransactionTicket}>Imprimir comprobante de transacción</Button>
                    </ButtonGroup>
                </Row>

                <InventoryTransactionTicket
                    visible={this.state.transactionTicket}
                    onCancel={this.closeTransactionTicket}
                    transactionTitle="Merma de inventario"
                    transactionItems={transactionItemsTable}
                    hotel={this.state.ticketHotel}
                    inventory={this.state.ticketInventory}
                    user={this.props.username}
                />
            </Spin>
        );

        let renderedStep = null;
        if (this.state.currentStep === 0) {
            renderedStep = stepOne;
        }

        if (this.state.currentStep === 1) {
            renderedStep = stepTwo;
        }

        if (this.state.currentStep === 2) {
            renderedStep = stepThree;
        }
        
        return (
            <Layout>
                <Header style={{ background: '#fff', padding: 0 }}>
                    <Row>
                        <ButtonGroup>
                            <Button type="primary" onClick={this.goBackHandler}>
                                <LeftOutlined />Regresar
                            </Button>
                        </ButtonGroup>
                    </Row>
                </Header>
                <Content style={{ margin: 0, padding: 0, background: '#ffff', paddingTop: 15 }}>
                    <Steps size="small" current={this.state.currentStep}>
                        <Step title="Seleccionar" />
                        <Step title="Mermar" />
                        <Step title="Comprobante" />
                    </Steps>
                    <Row style={{marginTop: 50}}>
                        <Spin spinning={this.state.loadingSupply} size="large">
                        <Col span={20} offset={2}>
                            {renderedStep}
                        </Col>
                        </Spin>
                    </Row>
                </Content>
            </Layout>
        );
    }
}

const mapStateToProps = state => {
    return {
        username: state.auth.user.username,
        hotels: state.auth.hotels
    };
};


export default connect(mapStateToProps, null)(Deplete);