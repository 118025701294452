import React, { Component } from 'react';
import { API, Auth } from 'aws-amplify';
import { connect } from 'react-redux';
import { Button, Col, Layout, Row, Select, Steps, Spin, Table } from 'antd'


const { Header, Content } = Layout;
const ButtonGroup = Button.Group;
const Option = Select.Option;
const Step = Steps.Step;

const columns = [
    {
        title: 'Tipo',
        dataIndex: 'type',
        key: 'type'
    },
    {
        title: 'Precio',
        dataIndex: 'price',
        key: 'price'
    },
    {
        title: 'Estado',
        dataIndex: 'enabled',
        key: 'enabled'
    }
];


class RoomTypes extends Component {
    state = {
        loading: false,
        loadingStepOne: false,
        loadingStepTwo: false,
        currentStep: 0,
        hotelsOptions: [],
        selectedHotel: [],
        roomTypesData: []
    }

    idToken = '';
    userHotels = [];

    loadData() {
        this.setState({loading: true});
        this.setHotelsMenu();
        this.setState({loading: false});
    }

    setHotelsMenu() {
        const enabledHotels = this.props.hotels;
        var hotels = [];

        for (var i in enabledHotels) {
            hotels.push(<Option key={i}>{enabledHotels[i]}</Option>);
        }

        this.setState({hotelsOptions: hotels});
    }

    createTypeHandler = () => {
        this.props.history.push('/app/rooms/types/create');
    }

    selectHotelHandler = (value) => {
        this.setState({selectedHotel: value});
    }

    goToSecondStep = async () => {
        this.setState({ loading: true });

        API.get('benackoAPI', '/room-types?hotelId=' + this.state.selectedHotel, {
                headers: {
                    Authorization: this.idToken
                }
            })
            .then( response => {
                var roomTypes = [];

                response.forEach( type => {
                    roomTypes.push({
                        key: type.id,
                        type: type.name,
                        price: '$' + type.price,
                        enabled: type.enabled ? 'Habilitado' : 'Deshabilitado'
                    });
                });

                this.setState({ roomTypesData: roomTypes, currentStep: 1, loading: false });
            })
            .catch( errRoomTypes => {
                console.log('Error loading room types: ', errRoomTypes);

                this.setState({ loading: false });
            });
    }

    goPreviousStep = () => {
        const newStep = this.state.currentStep - 1;
        this.setState({currentStep: newStep});
    }

    componentDidMount() {
        Auth.currentSession()
            .then( response => {
                this.idToken = response.idToken.jwtToken;

                this.loadData();
            })
            .catch( error => {
                console.log(error);
            });
    }

    render() {
        const stepOne = (
            <Spin spinning={this.state.loading} size="large">
                <Row style={{width: 600}}>
                    <Select
                        style={{width: '100%'}}
                        placeholder="Selecciona el hotel"
                        onChange={this.selectHotelHandler}
                    >
                        {this.state.hotelsOptions}
                    </Select>
                </Row>
                <Row style={{ marginTop: 20 }}>
                    <Button type="primary" onClick={this.goToSecondStep} disabled={(this.state.selectedHotel.length <= 0) ? true : false}>Siguiente</Button>
                </Row>
            </Spin>
        );

        const stepTwo = (
            <Spin spinning={this.state.loading} size="large">
                <Row style={{width: 600}}>
                    <Table
                        style={{width: '100%'}}
                        columns={columns} 
                        dataSource={this.state.roomTypesData}
                        onRow={ (record) => {
                            return {
                                onClick: () => {
                                    this.props.history.push('/app/rooms/types/details/' + record.key);
                                }
                            };
                        }}
                        size="middle" 
                        pagination={{
                            pageSize: 8,
                            showTotal: (total) => `Total: ${total} tipos`
                        }}
                        locale={{
                            emptyText: 'No se encontraron tipos de habitación'
                        }} 
                        loading={{
                            spinning: this.state.loadingStepTwo,
                            delay: 500,
                            size: 'large'
                        }}/>
                </Row>
                <Row style={{marginTop: 40}}>
                    <ButtonGroup>
                        <Button type="primary" onClick={this.goPreviousStep}>Regresar</Button>
                    </ButtonGroup>
                </Row>
            </Spin>
        );

        let renderedStep = null;
        if (this.state.currentStep === 0) {
            renderedStep = stepOne;
        }

        if (this.state.currentStep === 1) {
            renderedStep = stepTwo;
        }

        return (
            <Layout>
                <Header style={{width: '100%', background: '#fff', padding: 0, paddingLeft: 20}}>
                    <Row>
                        <ButtonGroup>
                            <Button type="primary" onClick={this.createTypeHandler}>Crear</Button>
                        </ButtonGroup>
                    </Row>
                </Header>
                <Content style={{width: '100%', margin: 0, padding: 0, background: '#fff', paddingTop: 15, paddingLeft: 20}}>
                    <Steps size="small" current={this.state.currentStep}>
                        <Step title="Seleccionar hotel" />
                        <Step title="Tipos de habitación" />
                    </Steps>
                    <Row style={{marginTop: 50}}>
                        <Spin spinning={this.state.loading} size="large">
                        <Col span={20} offset={2}>
                            {renderedStep}
                        </Col>
                        </Spin>
                    </Row>
                </Content>
            </Layout>
        );
    }
}

const mapStateToProps = state => {
    return {
        hotels: state.auth.hotels
    };
};


export default connect(mapStateToProps, null)(RoomTypes);