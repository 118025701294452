import React, { Component } from 'react';
import { API, Auth } from 'aws-amplify';
import { Button, Layout, Row, Card } from 'antd';
import { LeftOutlined } from '@ant-design/icons';

import DisableModal from '../../../components/UI/Modals/DisableModal/DisableModal';

const { Header, Content } = Layout;
const ButtonGroup = Button.Group


class Detail extends Component {
    state = {
        loading: true,
        modalVisible: false,
        modalConfirmLoading: false,
        itemName: '',
        itemUnit: '',
        itemCreateDate: null,
        itemLastEditDate: null,
        itemEnabled: false
    }

    idToken = '';

    loadData() {
        this.setState({loading: true});
        const id = this.props.match.params.id;

        API.get('benackoAPI', '/items/' + id, {
                headers: {
                    Authorization: this.idToken
                }
            })
            .then( async response => {
                const name = response[0].name;
                const unit = await this.getUnitName(response[0].itemUnitId);
                const createDate = new Date(response[0].created).toLocaleString();
                const lastEditDate = new Date(response[0].updated).toLocaleString();
                const enabled = response[0].enabled;

                this.setState({
                    loading: false,
                    itemName: name,
                    itemUnit: unit,
                    itemCreateDate: createDate,
                    itemLastEditDate: lastEditDate,
                    itemEnabled: enabled
                });
            })
            .catch( errItem => {
                console.log('Error loading item:', errItem);

                this.setState({ loading: false });
            });
    }

    async getUnitName(unitId) {
        let unit = await API.get('benackoAPI', '/item-units/' + unitId, { headers: { Authorization: this.idToken } });
        let unitName = unit[0].name;

        return unitName;
    }

    componentDidMount() {
        Auth.currentSession()
            .then( response => {
                this.idToken = response.idToken.jwtToken;

                this.loadData();
            })
            .catch( error => {
                console.log(error);
            });
    }

    goBackHandler = () => {
        this.props.history.goBack();
    }

    goEditHandler = () => {
        this.props.history.push('/app/items/details/' + this.props.match.params.id + '/edit')
    }

    showDisableModal = () => {
        this.setState({modalVisible: true});
    }

    disableItem = () => {
        this.setState({modalConfirmLoading: true});
        const id = this.props.match.params.id;

        API.del('benackoAPI', '/items/' + id, {
                headers: {
                    Authorization: this.idToken
                }
            })
            .then( () => {
                this.setState({
                    modalVisible: false,
                    modalConfirmLoading: false,
                });

                this.props.history.goBack();
            })
            .catch( error => {
                console.log(error);

                this.setState({
                    modalVisible: false,
                    modalConfirmLoading: false,
                });
            });
    }

    closeDisableModal = () => {
        this.setState({modalVisible: false});
    }

    render() {
        return(
            <Layout>
                <Header style={{ background: '#fff', padding: 0 }}>
                    <Row>
                        <ButtonGroup>
                            <Button type="primary" onClick={this.goBackHandler}>
                                <LeftOutlined />Regresar
                            </Button>
                            <Button type="primary" onClick={this.goEditHandler} disabled={!this.state.itemEnabled}>
                                Editar
                            </Button>
                            <Button type="danger" onClick={this.showDisableModal} disabled={!this.state.itemEnabled}>
                                Deshabilitar
                            </Button>
                        </ButtonGroup>
                    </Row>
                </Header>
                <Content style={{ margin: 0, padding: 0, background: '#ffff', paddingTop: 15 }}>
                    <Card loading={this.state.loading} bordered={false} title={this.state.itemName}>
                        <p><strong>Unidad:</strong> {this.state.itemUnit}</p>
                        <p><strong>Fecha de creación:</strong> {this.state.itemCreateDate}</p>
                        <p><strong>Última actualización:</strong> {this.state.itemLastEditDate}</p>
                    </Card>
                </Content>
                <DisableModal 
                    visible={this.state.modalVisible}
                    confirmLoading={this.state.modalConfirmLoading}
                    onCancel={this.closeDisableModal}
                    onOk={this.disableItem}
                    modalText="¿Deseas deshabilitar el artículo?"
                />
            </Layout>
        );
    }
}


export default Detail;