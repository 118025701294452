import React, { Component } from 'react';
import { API } from 'aws-amplify';
import { Button, Layout, Row, Table } from 'antd'

const ButtonGroup = Button.Group;
const { Header, Content } = Layout;

const columns = [
    {
        title: 'Horario',
        dataIndex: 'type',
        key: 'type'
    },
    {
        title: 'Habilitado',
        dataIndex: 'enabled',
        key: 'enabled'
    }
];


class RoomType extends Component {
    state = {
        loading: false,
        schedulesData: [],
        priceSchedule: false,
        hoursSchedule: false
    }

    schedulesTypes = {};

    loadData() {
        this.setState({loading: true});

        API.get('benackoAPI', '/system/rooms/schedules/', { 
                headers: { 
                    Authorization: this.idToken 
                } 
            })
            .then( responseScheduleTypes => {

                responseScheduleTypes.forEach( type => {
                    this.schedulesTypes[type.id] = type.name;
                });
                
                API.get('benackoAPI', '/room-schedules?roomTypeId=' + this.props.match.params.id + '&entityId=2')
                    .then( response => {
                        var roomSchedules = [];
                        
                        response.forEach( schedule => {
                            roomSchedules.push({
                                key: schedule.id,
                                type: this.schedulesTypes[schedule.scheduleType],
                                enabled: (schedule.enabled) ? 'Habilitado' : 'Deshabilitado'
                            });

                            if (schedule.scheduleType === 2) {
                                this.setState({hoursSchedule: true});
                            } else if (schedule.scheduleType === 3) {
                                this.setState({priceSchedule: true});
                            }
                        });
        
                        this.setState({ schedulesData: roomSchedules, loading: false });
                    })
                    .catch( errRoomTypes => {
                        console.log('Error loading room types: ', errRoomTypes);
        
                        this.setState({ loading: false });
                    });
            })
            .catch( errScheduleType => {
                console.log('Error loading schedule types:', errScheduleType);
            });
    }

    async getScheduleTypeName(scheduleTypeId) {
        let schedule = await API.get('benackoAPI', '/system/rooms/schedules/' + scheduleTypeId, { headers: { Authorization: this.idToken } });
        var scheduleTypeName = schedule[0].name;

        return scheduleTypeName;
    }

    componentDidMount() {
        this.loadData();
    }

    goToCreatePriceScheduleHandler = () => {
        this.props.history.push('/app/rooms/types/details/' + this.props.match.params.id + '/schedules/create/3');
    };

    goToCreateHoursScheduleHandler = () => {
        this.props.history.push('/app/rooms/types/details/' + this.props.match.params.id + '/schedules/create/2');
    };

    render() {
        return (
            <Layout>
                <Header style={{width: '100%', background: '#fff', padding: 0, paddingLeft: 20}}>
                    <Row>
                        <ButtonGroup>
                            <Button type="primary" onClick={this.goToCreateHoursScheduleHandler} disabled={this.state.hoursSchedule}>
                                Agregar calendario de horas
                            </Button>
                            <Button type="primary" onClick={this.goToCreatePriceScheduleHandler} disabled={this.state.priceSchedule}>
                                Agregar calendario de precios
                            </Button>
                        </ButtonGroup>
                    </Row>
                </Header>
                <Content style={{width: '100%', margin: 0, padding: 0, background: '#fff', paddingTop: 15, paddingLeft: 20}}>
                    <Row style={{width: '100%', marginTop: 50}}>
                        <Table 
                            style={{width: '100%'}}
                            columns={columns} 
                            dataSource={this.state.schedulesData}
                            onRow={ (record) => {
                                return {
                                    onClick: () => {
                                        this.props.history.push('/app/rooms/types/details/' + this.props.match.params.id + '/schedules/details/' + record.key);
                                    }
                                };
                            }}
                            size="middle" 
                            pagination={{
                                pageSize: 8,
                                showTotal: (total) => `Total: ${total} horarios`
                            }}
                            locale={{
                                emptyText: 'No se encontraron horarios'
                            }} 
                            loading={{
                                spinning: this.state.loading,
                                delay: 500,
                                size: 'large'
                            }}/>
                    </Row>
                </Content>
            </Layout>
        );
    }
}

export default RoomType;