import React from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Layout, Menu } from 'antd';
import { DashboardFilled, BookFilled, WalletFilled, ShopFilled, ShoppingCartOutlined, UserOutlined, SettingFilled, ToolOutlined } from '@ant-design/icons';
import SubMenu from 'antd/lib/menu/SubMenu';
const { Sider } = Layout;

const SideBar = () => {
    const role = useSelector(state => state.auth.role);
    const usersModule = useSelector(state => state.base?.entitlements?.users?.CanAccessModule ?? false);


    return (
        <Sider collapsible trigger={null} style={{backgroundColor: 'black', overflow: 'auto', height: '100vh', position: 'fixed', left: 0, top: 0, bottom: 0}}>
            <div className="logo"></div>
            <Menu
                defaultSelectedKeys={['1']}
                mode="inline"
                theme="dark"
                style={{backgroundColor: 'black'}}
            >
                <Menu.Item key={1} icon={<DashboardFilled />}>
                    <span>Tablero</span>
                    <NavLink to={'/app'} />
                </Menu.Item>
                <Menu.Item key={2} icon={<BookFilled />}>
                    <span>Reportes</span>
                    <NavLink to={'/app/reports'}></NavLink>
                </Menu.Item>
                { (role == null && role.p_currencies === 0 && role.p_vouchers === 0) ? null : <SubMenu key="sub2" title={<span><WalletFilled /><span>Pagos</span></span>}>
                        { (role.p_currencies === 0) ? null : <Menu.Item key="21"><NavLink to={'/app/payments/currencies'}>Tipos de cambio</NavLink></Menu.Item>}
                        { (role.p_vouchers === 0) ? null : <Menu.Item key="22"><NavLink to={'/app/vouchers'}>Comprobantes</NavLink></Menu.Item>}
                    </SubMenu> }
                { (role == null && role.p_items === 0 && role.p_locations === 0 && role.p_inventories === 0 && role.p_products === 0) 
                        ? null : <SubMenu key="sub3" title={<span><ShoppingCartOutlined /><span>Inventarios</span></span>}>
                        { (role.p_items === 0) ? null : <Menu.Item key="31"><NavLink to={'/app/items'}>Artículos</NavLink></Menu.Item> }
                        { (role.p_locations === 0) ? null : <Menu.Item key="32"><NavLink to={'/app/items/locations'}>Ubicaciones</NavLink></Menu.Item> }
                        { (role.p_inventories === 0) ? null : <Menu.Item key="33"><NavLink to={'/app/inventories'}>Inventarios</NavLink></Menu.Item> }
                        { (role.p_products === 0) ? null : <Menu.Item key="34"><NavLink to={'/app/products'}>Productos</NavLink></Menu.Item> }
                    </SubMenu> }

                { (role == null && role.p_rooms === 0 && role.p_roomtypes === 0 && role.p_roompermissions === 0 && role.p_roompromotions === 0) 
                        ? null : <SubMenu key="sub4" title={<span><ShopFilled /><span>Habitaciones</span></span>}>
                        { (role.p_rooms === 0) ? null : <Menu.Item key="41"><NavLink to={'/app/rooms'}>Habitaciones</NavLink></Menu.Item> }
                        { (role.p_roomtypes === 0) ? null : <Menu.Item key="42"><NavLink to={'/app/rooms/types'}>Tipos de habitación</NavLink></Menu.Item> }
                        { (role.p_roompermissions === 0) ? null : <Menu.Item key="43"><NavLink to={'/app/rooms/permissions'}>Permisos</NavLink></Menu.Item> }
                        { (role.p_roompromotions === 0) ? null : <Menu.Item key="44"><NavLink to={'/app/rooms/promotions'}>Promociones</NavLink></Menu.Item> }
                    </SubMenu> }

                {(usersModule == null || usersModule === false) ? null :
                    <Menu.Item key={6} icon={<UserOutlined />}>
                        <span>Usuarios</span>
                        <NavLink to={'/app/users'}></NavLink>
                    </Menu.Item>}

                { (role == null && role.p_schedules === 0) ? null : <SubMenu key="sub6" title={<span><SettingFilled /><span>Ajustes</span></span>}>
                        { (role.p_schedules === 0) ? null : <Menu.Item key="61"><NavLink to={'/app/settings/schedules'}>Horario</NavLink></Menu.Item> }
                </SubMenu> }

                {/* {
                    (role == null && role.p_maintenance === 0) ? null : <SubMenu key="sub7" title={<span><ToolOutlined /><span>Mantenimiento</span></span>}>
                        <Menu.Item key={71}><NavLink to={'/app/maintenance/utilities'}>Servicios</NavLink></Menu.Item>
                    </SubMenu>

                } */}
            </Menu>
        </Sider>
    );
}

export default SideBar;