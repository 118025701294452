import React, { Component } from 'react';
import { connect } from 'react-redux';
import { API, Auth } from 'aws-amplify';
import { Button, Layout, Row, Table } from 'antd';


const { Header, Content } = Layout;
const ButtonGroup = Button.Group;

const columns = [
    {
        title: 'Nombre',
        dataIndex: 'name',
        key: 'name'
    }
];


class Products extends Component {
    state = {
        loading: true,
        data: []
    }

    idToken = '';

    loadData() {
        this.setState({loading: true});

        API.get('benackoAPI', '/v2/products?companyId=' + this.props.companyId, {
                headers: {
                    Authorization: this.idToken
                }
            })
            .then( productsRes => {
                var products = [];

                productsRes.forEach( product => {
                    products.push({
                        key: product.id,
                        name: product.name
                    });
                });

                this.setState({loading: false, data: products});
            })
            .catch( errProducts => {
                console.log('Error loading products:', errProducts);
                this.setState({loading: false});
            });
    }

    componentDidMount() {
        Auth.currentSession()
            .then( response => {
                this.idToken = response.idToken.jwtToken;

                this.loadData();
            })
            .catch( error => {
                console.log(error);
            });
    }

    createProductHandler = () => {
        this.props.history.push('/app/products/create');
    }

    render() {
        return(
            <Layout>
                <Header style={{width: '100%', background: '#fff', padding: 0, paddingLeft: 20}}>
                    <Row>
                        <ButtonGroup>
                            <Button type="primary" onClick={this.createProductHandler}>Crear</Button>
                        </ButtonGroup>
                    </Row>
                </Header>
                <Content style={{width: '100%', margin: 0, padding: 0, background: '#fff', paddingLeft: 20}}>
                <Table 
                        columns={columns} 
                        dataSource={this.state.data}
                        onRow={ (record) => {
                            return {
                                onClick: () => {
                                    this.props.history.push('/app/products/details/' + record.key);
                                }
                            };
                        }}
                        size="middle" 
                        pagination={{
                            pageSize: 15,
                            showTotal: (total) => `Total ${total} productos`
                        }}
                        locale={{
                            emptyText: 'No se encontraron productos'
                        }} 
                        loading={{
                            spinning: this.state.loading,
                            delay: 500,
                            size: 'large'
                        }}/>
                </Content>
            </Layout>
        );
    }
}

const mapStateToProps = state => {
    return {
        idToken: state.auth.idToken,
        companyId: state.auth.companyId
    };
};


export default connect(mapStateToProps, null)(Products);