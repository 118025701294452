import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';

import UsersList from './UsersList/UsersList';
import UsersDetails from './UsersDetails/UsersDetails';
import UsersCreate from './UsersCreate/UsersCreate';


const Users = () => {
    const canAccess = useSelector(state => state.base.entitlements?.users?.CanAccessModule ?? false);

    return (
        <Switch>
            <Route path="/app/users"
                render={({ match: { url } }) => (
                    <div>
                        {canAccess && <Route exact path={`${url}`} component={UsersList} />}
                        {canAccess && <Route exact path={`${url}/create`} component={UsersCreate} />}
                        {canAccess && <Route exact path={`${url}/details/:id`} component={UsersDetails} />}
                    </div>
                )}
            />
        </Switch>
    );
}

export default Users;