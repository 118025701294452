import React, { Component } from 'react';
import { API, Auth } from 'aws-amplify';
import { Button, Form, Input, InputNumber, Layout, Row, Col, Spin } from 'antd';
import { LeftOutlined } from '@ant-design/icons';

const { Header, Content } = Layout;
const FormItem = Form.Item;
const ButtonGroup = Button.Group;

function hasErrors(fieldsError) {
    return Object.keys(fieldsError).some(field => fieldsError[field]);
}

class Edit extends Component {
    state = {
        loading: false
    }

    idToken = '';

    loadData() {
        this.props.form.validateFields();
        const id = this.props.match.params.id;

        this.setState({loading: true});

        API.get('benackoAPI', '/room-promotions/' + id, {
                headers: {
                    Authorization: this.idToken
                }
            })
            .then( (response) => {
                const name = response[0].name;
                const daysCondition = response[0].daysCondition;
                const price = response[0].price;

                this.props.form.setFieldsValue({
                    name: name,
                    daysCondition: daysCondition,
                    price: price
                });
                this.setState({loading: false});
            })
            .catch( error => {
                console.log(error);

                this.setState({loading: false});
            });
    }

    componentDidMount() {
        Auth.currentSession()
            .then( response => {
                this.idToken = response.idToken.jwtToken;

                this.loadData();
            })
            .catch( error => {
                console.log(error);
            });
    }

    editPromotionHandler = (event) => {
        event.preventDefault();

        this.setState({loading: true});

        this.props.form.validateFields((err, values) => {
            if (!err) {
                const id = this.props.match.params.id;
                const beautifiedName = values.name.trim().toLowerCase();

                API.put('benackoAPI', '/room-promotions/' + id, {
                    body: {
                        name: beautifiedName,
                        daysCondition: values.daysCondition,
                        price: values.price
                    },
                    headers: {
                        Authorization: this.idToken
                    }
                })
                .then(() => {
                    this.setState({loading: false});
                    this.props.history.goBack();
                })
                .catch(function (error) {
                    this.setState({loading: false});
                    console.log(error);
                });
            }
        });
    }

    goBackHandler = () => {
        this.props.history.goBack();
    }

    render() {
        const { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched } = this.props.form;

        // Only show error after a field is touched.
        const nameError = isFieldTouched('name') && getFieldError('name');
        const priceError = isFieldTouched('price') && getFieldError('price');
        const daysConditionError = isFieldTouched('daysCondition') && getFieldError('daysCondition');

        return(
            <Layout>
                <Header style={{ background: '#fff', padding: 0 }}>
                    <Row>
                        <ButtonGroup>
                            <Button type="primary" onClick={this.goBackHandler}>
                                <LeftOutlined />Regresar
                            </Button>
                        </ButtonGroup>
                    </Row>
                </Header>
                <Content style={{ margin: 0, padding: 0, background: '#ffff' }}>
                    <Spin spinning={this.state.loading} size="large">
                        <Form onSubmit={this.editPromotionHandler} className="login-form">
                            <Row style={{marginTop: 50}}>
                                <Col span={20} offset={2}>
                                    <p>Nombre:</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={20} offset={2}>
                                    <FormItem
                                        validateStatus={nameError ? 'error' : ''}
                                        help={nameError || ''}
                                    >
                                        {getFieldDecorator('name', {
                                            rules: [{ required: true, message: 'Introduzca un nombre' }]
                                        })(
                                            <Input prefix={<TableOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Nombre" />
                                        )}
                                    </FormItem>
                                </Col>
                            </Row>
                            <Row style={{marginTop: 10}}>
                                <Col span={20} offset={2}>
                                    <p>Precio:</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={20} offset={2}>
                                    <FormItem
                                        validateStatus={priceError ? 'error' : ''}
                                        help={priceError || ''}
                                    >
                                        {getFieldDecorator('price', {
                                            rules: [{ required: true, message: 'Introduce un precio' }],
                                        })(
                                            <InputNumber min={0} prefix={<TableOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Precio" />
                                        )}
                                    </FormItem>
                                </Col>
                            </Row>

                            <Row style={{marginTop: 10}}>
                                <Col span={20} offset={2}>
                                    <p>Número de días:</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={20} offset={2}>
                                    <FormItem
                                        validateStatus={daysConditionError ? 'error' : ''}
                                        help={daysConditionError || ''}
                                    >
                                        {getFieldDecorator('daysCondition', {
                                            rules: [{ required: true, message: 'Introduce un número de días' }],
                                        })(
                                            <InputNumber min={0} prefix={<TableOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Días" />
                                        )}
                                    </FormItem>
                                </Col>
                            </Row>
                            <Row style={{marginTop: 20}}>
                                <Col span={4} offset={2}>
                                    <FormItem>
                                        <Button type="primary" 
                                            htmlType="submit" 
                                            disabled={hasErrors(getFieldsError())} className="login-form-button"
                                        >
                                            Editar
                                        </Button>
                                    </FormItem>
                                </Col>
                            </Row>
                        </Form>
                    </Spin>
                </Content>
            </Layout>
        );
    }
}

export default Edit;