import React, { Component } from 'react';
import { API, Auth } from 'aws-amplify';
import { connect } from 'react-redux';
import { Layout, Row, Tabs, Spin, Button, Table, Card } from 'antd';
import { LeftOutlined, ReloadOutlined } from '@ant-design/icons';

import CommentsBox from '../../../components/UI/CommentsBox/CommentsBox';


const { Header, Content } = Layout;
const ButtonGroup = Button.Group;
const TabPane = Tabs.TabPane;

const incomeColumns = [
    {
        title: '',
        dataIndex: 'type'
    },
    {
        title: 'Monto',
        dataIndex: 'amount'
    }
];

const depositTicketsColumns = [
    {
        title: 'Concepto',
        dataIndex: 'concept'
    }, {
        title: 'Monto',
        dataIndex: 'amount'
    }
];

const actionsColumns = [
    {
        title: 'Acciones',
        dataIndex: 'actions'
    }, {
        title: 'Cantidad',
        dataIndex: 'amount'
    }, {
        title: 'MXN',
        dataIndex: 'mxn'
    }, {
        title: 'USD',
        dataIndex: 'usd'
    }, {
        title: 'Tarjeta',
        dataIndex: 'card'
    }, {
        title: 'Total',
        dataIndex: 'total'
    }
];

const exchangeRateColumns = [
    {
        title: 'Moneda',
        dataIndex: 'currency'
    },{
        title: 'Tasa',
        dataIndex: 'rate'
    }
];

const roomsStatusEndColumms = [
    {
        title: 'Estado',
        dataIndex: 'status',
    }, {
        title: 'Habitaciones',
        dataIndex: 'rooms',
    }
];

const blockedStatusColumms = [
    {
        title: 'Fecha',
        dataIndex: 'timestamp',
    }, {
        title: 'Habitación',
        dataIndex: 'room',
    }, {
        title: 'Razón',
        dataIndex: 'reason'
    }
];

const roomOrdersColumns = [
    {
        title: 'Habitación',
        dataIndex: 'room',
    }, {
        title: 'Hora de orden',
        dataIndex: 'orderTimestamp'
    }, {
        title: 'Usuario de orden',
        dataIndex: 'orderUser'
    }, {
        title: 'Total orden',
        dataIndex: 'orderTotal'
    }, {
        title: 'MXN',
        dataIndex: 'mxn'
    }, {
        title: 'USD',
        dataIndex: 'usd'
    }, {
        title: 'Tarjeta',
        dataIndex: 'card'
    }, {
        title: 'Voucher',
        dataIndex: 'voucher'
    }, {
        title: 'Hora de pago',
        dataIndex: 'chargeTimestamp'
    }, {
        title: 'Usuario de pago',
        dataIndex: 'chargeUser'
    }
];

const roomOrderDetailsColumns = [
    {
        title: 'Habitación',
        dataIndex: 'room',
    }, {
        title: 'Hora de orden',
        dataIndex: 'orderTimestamp'
    }, {
        title: 'Producto',
        dataIndex: 'product'
    }, {
        title: 'Cantidad',
        dataIndex: 'quantity'
    }
];

const roomRentColumns = [
    {
        title: 'Habitación',
        dataIndex: 'room',
    }, {
        title: 'Entrada',
        dataIndex: 'rentTimestamp'
    }, {
        title: 'Método',
        dataIndex: 'rentMethod'
    }, {
        title: 'Días/Horas',
        dataIndex: 'rentType'
    }, {
        title: 'Tipo',
        dataIndex: 'rentRoomType'
    }, {
        title: 'Cantidad',
        dataIndex: 'rentPeriod'
    }, {
        title: 'Adicionales',
        dataIndex: 'addedGuests'
    }, {
        title: 'Salida',
        dataIndex: 'checkoutTimestamp'
    }
];

const roomRentDetailsColumns = [
    {
        title: 'Habitación',
        dataIndex: 'room',
    }, {
        title: 'Entrada',
        dataIndex: 'rentTimestamp'
    }, {
        title: 'Placas',
        dataIndex: 'customerPlates'
    }, {
        title: 'Total renta',
        dataIndex: 'rentTotal'
    }, {
        title: 'Total adicionales',
        dataIndex: 'guestsTotal'
    }, {
        title: 'MXN',
        dataIndex: 'mxn'
    }, {
        title: 'USD',
        dataIndex: 'usd'
    }, {
        title: 'Tarjeta',
        dataIndex: 'card'
    }, {
        title: 'Voucher',
        dataIndex: 'voucher'
    }
];

const roomDaysAdditionsColumns = [
    {
        title: 'Fecha',
        dataIndex: 'timestamp',
    }, {
        title: 'Habitación',
        dataIndex: 'room',
    }, {
        title: 'Días agregados',
        dataIndex: 'addedDays'
    }, {
        title: 'Nueva salida',
        dataIndex: 'newCheckoutTimestamp'
    }, {
        title: 'Total',
        dataIndex: 'total'
    }, {
        title: 'MXN',
        dataIndex: 'mxn'
    }, {
        title: 'USD',
        dataIndex: 'usd'
    }, {
        title: 'Tarjeta',
        dataIndex: 'card'
    }, {
        title: 'Voucher',
        dataIndex: 'voucher'
    }
];

const roomGuestAdditionsColumns = [
    {
        title: 'Fecha',
        dataIndex: 'timestamp',
    }, {
        title: 'Habitación',
        dataIndex: 'room',
    }, {
        title: 'Huéspedes',
        dataIndex: 'guests'
    }, {
        title: 'Usuario',
        dataIndex: 'user'
    }, {
        title: 'Total',
        dataIndex: 'total'
    }, {
        title: 'MXN',
        dataIndex: 'mxn'
    }, {
        title: 'USD',
        dataIndex: 'usd'
    }, {
        title: 'Tarjeta',
        dataIndex: 'card'
    }, {
        title: 'Voucher',
        dataIndex: 'voucher'
    }
];

const roomChangesColumns = [
    {
        title: 'Habitación fuente',
        dataIndex: 'srcRoom',
    }, {
        title: 'Tipo fuente',
        dataIndex: 'srcRoomType'
    },{
        title: 'Fecha',
        dataIndex: 'timestamp',
    }, {
        title: 'Habitación destino',
        dataIndex: 'destRoom',
    }, {
        title: 'Tipo destino',
        dataIndex: 'destRoomType'
    }, {
        title: 'Usuario',
        dataIndex: 'user'
    }, {
        title: 'Razón',
        dataIndex: 'reason'
    }, {
        title: 'MXN',
        dataIndex: 'mxn'
    }, {
        title: 'USD',
        dataIndex: 'usd'
    }, {
        title: 'Tarjeta',
        dataIndex: 'card'
    }, {
        title: 'Voucher',
        dataIndex: 'voucher'
    }
];

const roomChargesColumns = [
    {
        title: 'Fecha',
        dataIndex: 'timestamp',
    }, {
        title: 'Habitación',
        dataIndex: 'room',
    }, {
        title: 'Usuario',
        dataIndex: 'user'
    }, {
        title: 'Razón',
        dataIndex: 'reason'
    }, {
        title: 'MXN',
        dataIndex: 'mxn'
    }, {
        title: 'USD',
        dataIndex: 'usd'
    }, {
        title: 'Tarjeta',
        dataIndex: 'card'
    }, {
        title: 'Voucher',
        dataIndex: 'voucher'
    }
];

const roomCheckoutsColumns = [
    {
        title: 'Fecha',
        dataIndex: 'timestamp',
    }, {
        title: 'Habitación',
        dataIndex: 'room',
    }, {
        title: 'Usuario',
        dataIndex: 'user'
    }, {
        title: 'Salida esperada',
        dataIndex: 'expectedCheckout'
    }, {
        title: 'Comentarios',
        dataIndex: 'comments'
    }
];

const roomCancellationsColumns = [
    {
        title: 'Fecha',
        dataIndex: 'timestamp',
    }, {
        title: 'Habitación',
        dataIndex: 'room',
    }, {
        title: 'Usuario',
        dataIndex: 'user'
    }, {
        title: 'Razón',
        dataIndex: 'reason'
    }, {
        title: 'Reembolso',
        dataIndex: 'mxn'
    }
];

const roomBlocksColumns = [
    {
        title: 'Fecha',
        dataIndex: 'timestamp',
    }, {
        title: 'Habitación',
        dataIndex: 'room',
    }, {
        title: 'Usuario',
        dataIndex: 'user'
    }, {
        title: 'Razón',
        dataIndex: 'reason'
    }
];

const roomUnblocksColumns = [
    {
        title: 'Fecha',
        dataIndex: 'timestamp',
    }, {
        title: 'Habitación',
        dataIndex: 'room',
    }, {
        title: 'Usuario',
        dataIndex: 'user'
    }, {
        title: 'Comentarios',
        dataIndex: 'comments'
    }
];

const roomCleansColumns = [
    {
        title: 'Habitación',
        dataIndex: 'room',
    }, {
        title: 'Usuario',
        dataIndex: 'user'
    }, {
        title: 'Fecha de terminación',
        dataIndex: 'endTimestamp'
    }, {
        title: 'Fecha de inicio',
        dataIndex: 'startTimestamp',
    }, {
        title: 'Recamarera',
        dataIndex: 'maid'
    }, {
        title: 'Estado previo',
        dataIndex: 'roomStatus'
    }
];

const maidCleansColumns = [
    {
        title: 'Nombre',
        dataIndex: 'name',
    }, {
        title: 'Habitaciones limpiadas',
        dataIndex: 'rooms'
    }, {
        title: 'Previamente sucias',
        dataIndex: 'prevDirty',
    }, {
        title: 'Sucias limpiadas',
        dataIndex: 'prevDirtyRooms'
    }, {
        title: 'Previamente ocupadas',
        dataIndex: 'prevOccupied'
    }, {
        title: 'Ocupadas limpiadas',
        dataIndex: 'prevOccupiedRooms'
    }
];

const nextMaidCleansColumns = [
    {
        title: 'Nombre',
        dataIndex: 'name',
    }, {
        title: 'Habitaciones limpiadas',
        dataIndex: 'rooms'
    }, {
        title: 'Previamente sucias',
        dataIndex: 'nextDirty',
    }, {
        title: 'Sucias limpiadas',
        dataIndex: 'nextDirtyRooms'
    }, {
        title: 'Previamente ocupadas',
        dataIndex: 'nextOccupied'
    }, {
        title: 'Ocupadas limpiadas',
        dataIndex: 'nextOccupiedRooms'
    }
];

const rentedRoomsColumns = [
    {
        title: 'Habitación',
        dataIndex: 'room'
    }, {
        title: 'Tipo de renta',
        dataIndex: 'rentType'
    }, {
        title: 'Tipo de habitación',
        dataIndex: 'roomType'
    }, {
        title: 'Entrada',
        dataIndex: 'rentTimestamp'
    }, {
        title: 'Salida',
        dataIndex: 'checkoutTimestamp'
    }, {
        title: 'Placas',
        dataIndex: 'licensePlate'
    }
];

const pendingReservationsColumns = [
    {
        title: 'Fecha de reservación',
        dataIndex: 'timestamp'
    }, {
        title: 'Cliente',
        dataIndex: 'client'
    }, {
        title: 'Fecha de ingreso',
        dataIndex: 'reservationTimestamp'
    }, {
        title: 'Total restante',
        dataIndex: 'reservationTotal'
    }, {
        title: 'Comentarios',
        dataIndex: 'comments'
    }
];

const reservationTicketsColumns = [
    {
        title: 'Fecha',
        dataIndex: 'timestamp'
    }, {
        title: 'Cliente',
        dataIndex: 'client'
    }, {
        title: 'Cantidad',
        dataIndex: 'amount'
    }, {
        title: 'Concepto',
        dataIndex: 'concept'
    },
];

const reservationsColumns = [
    {
        title: 'Fecha',
        dataIndex: 'timestamp'
    }, {
        title: 'Cliente',
        dataIndex: 'client'
    }, {
        title: 'Fecha de reservación',
        dataIndex: 'reservationTimestamp'
    }, {
        title: 'Total',
        dataIndex: 'total'
    }, {
        title: 'MXN',
        dataIndex: 'mxn'
    }, {
        title: 'USD',
        dataIndex: 'usd'
    }, {
        title: 'Tarjeta',
        dataIndex: 'card'
    }, {
        title: 'Voucher',
        dataIndex: 'voucher'
    }, {
        title: 'Comentarios',
        dataIndex: 'comments'
    }
];

const usedReservatonsColumns = [
    {
        title: 'Habitación',
        dataIndex: 'room',
    }, {
        title: 'Entrada',
        dataIndex: 'rentTimestamp'
    }, {
        title: 'Total renta',
        dataIndex: 'rentTotal'
    }, {
        title: 'Total adicionales',
        dataIndex: 'guestsTotal'
    }, {
        title: 'MXN',
        dataIndex: 'mxn'
    }, {
        title: 'USD',
        dataIndex: 'usd'
    }, {
        title: 'Tarjeta',
        dataIndex: 'card'
    }, {
        title: 'Voucher',
        dataIndex: 'voucher'
    }
];


class ShiftBalanceDetail extends Component {
    state = {
        loading: false,
        currentUser: '',
        sbInfo: [],
        isClosed: false,
        isSigned: false,
        signTimestamp: 0,
        signUsername: '',
        exchangeRateHeaders: null,
        validExchangeRate: 0,
        roomTypeHeaders: null,
        loadingRoomsStatusEnd: false,
        prevSbId: 0,
        nextSbId: 0,
        income: null,
        deposits: null,
        roomTypeTotals: null,
        totals: null,
        actions: null,
        adUsers: [],
        initialRoomsStatusReport: [],
        roomsStatusEndReport: null,
        blockedStatusReport: null,
        initialRentedRoomsStatusReport: null,
        rentedStatusReport: null,
        nextRentedRoomsStatusReport: null,
        sbTotals: null,
        depositTickets: [],
        roomOrders: [],
        roomOrderDetails: [],
        roomRents: [],
        roomRentDetails: [],
        roomDaysAdditions: [],
        roomGuestAdditions: [],
        roomCancellations: [],
        roomChanges: [],
        roomCharges: [],
        roomCheckouts: [],
        roomBlocks: [],
        roomUnblocks: [],
        roomCleans: [],
        maidCleans: [],
        prevRoomCleans: [],
        prevMaidCleans: [],
        nextRoomCleans: [],
        nextMaidCleans: [],
        sbTotalMxn: 0,
        sbTotalUsd: 0,
        sbTotalCard: 0,
        sbTotalDaysAdditions: 0,
        sbTotalGuestAdditions: 0,
        sbTotalChanges: 0,
        sbTotalCharges: 0,
        comments: null,
        generalComments: [],
        initialRoomStatusComments: [],
        initialRentedRoomStatusComments: [],
        roomEndStatusComments: [],
        roomBlockedStatusComments: [],
        rentedRoomsStatusComments: [],
        nextRentedRoomsStatusComments: [],
        roomOrdersComments: [],
        roomRentComments: [],
        daysAdditionsComments: [],
        guestAdditionComments: [],
        roomChangesComments: [],
        roomChargesComments: [],
        roomCheckoutsComments: [],
        roomBlocksComments: [],
        roomUnblocksComments: [],
        roomCleansComments: [],
        prevRoomCleansComments: [],
        nextRoomCleansComments: [],
        loadingComments: false,
        printRoomTypeTable: '',
        endPendingReservations: [],
        startPendingReservationsTotal: 0.0,
        endPendingReservationsTotal: 0.0,
        endPendingReservationsComments: [],
        reservationTickets: [],
        reservationTicketsComments: [],
        reservations: [],
        usedReservations: [],
        reservationsComments: []
    }

    id = '';
    hotelId = '';
    sbType = '';
    idToken = '';


    componentDidMount() {
        Auth.currentSession()
            .then( async response => {
                this.idToken = response.idToken.jwtToken;
                this.id = parseInt(this.props.match.params.id, 10);
                this.hotelId = parseInt(this.props.match.params.hotelId, 10);
                this.sbType = parseInt(this.props.match.params.sbType, 10);

                let user = await Auth.currentAuthenticatedUser();
                this.setState({currentUser: user.attributes.email});

                this.loadData(this.id);
            })
            .catch( error => {
                console.log(error);
            });
    }

    loadData(loadSbId) {
        this.setState({loading: true});
        this.id = loadSbId;

        API.get('benackoAPI', '/v2/reports/shift-balances?hotelId=' + this.hotelId + '&type=' + this.sbType + '&startTimestamp=' + this.id, {
                headers: {
                    Authorization: this.idToken
                }
            })
            .then( sbHeaderResponse => {
                if (sbHeaderResponse.Items.length > 0) {
                    const sbHeader = sbHeaderResponse.Items[0];
                    const isClosed = (sbHeader.endTimestamp === 0) ? false : true; 
                    const isSigned = (sbHeader.signTimestamp === undefined) ? false : true;
                    var signTimestamp = 0;
                    var signUser = '';

                    if (isSigned) {
                        signTimestamp = new Date(sbHeader.signTimestamp).toLocaleString();
                        signUser = sbHeader.signUser;
                    }

                    this.setState({
                        sbInfo: sbHeader,
                        isClosed: isClosed,
                        isSigned: isSigned,
                        signTimestamp: signTimestamp,
                        signUsername: signUser
                    });

                    return sbHeader;
                }
            })
            .then(sbHeader => {
                if (sbHeader.headers !== undefined && sbHeader.headers.length > 0) {
                    const headers = sbHeader.headers;
                    const erHeaders = headers[0];
                    const rtHeaders = headers[1];

                    const validRate = erHeaders[0].rate;
                    var exchangeRates = [];

                    headers[0].forEach(rate => {
                        exchangeRates.push({
                            key: rate.currency,
                            currency: rate.currency,
                            rate: '$' + rate.rate
                        });
                    });

                    var roomTypes = [];
                    rtHeaders.forEach( type => {
                        roomTypes.push({
                            key: type.id,
                            name: type.name,
                            price: '$' + type.price,
                            isTemporary: (type.isTemporary === 1) ? 'Si' : 'No',
                            breakfastNumber: type.breakfastNumber
                        });
                    });

                    this.setState({
                        validExchangeRate: validRate,
                        exchangeRateHeaders: exchangeRates,
                        roomTypeHeaders: roomTypes
                    });
                }

                if (sbHeader.endRoomStatus !== undefined && sbHeader.endRoomStatus.length > 0) {
                    const endRoomStatus = sbHeader.endRoomStatus;

                    var roomsStatus = [];
                    var availableRooms = '';
                    var occupiedRooms = '';
                    var dirtyRooms = '';
                    var blockedRooms = '';
                    var cleaningRooms = '';
                    var paymentPendingRooms = '';

                    endRoomStatus.forEach(room => {
                        const roomStatus = room.status;
                        const roomName = room.room;

                        if (roomStatus === 'disponible') {
                            availableRooms += roomName + ' ';
                            return;
                        }

                        if (roomStatus === 'bloqueada') {
                            blockedRooms += roomName + ' ';
                            return;
                        }

                        if (roomStatus === 'sucia') {
                            dirtyRooms += roomName + ' ';
                            return;
                        }

                        if (roomStatus === 'ocupada') {
                            occupiedRooms += roomName + ' ';
                            return;
                        }

                        if (roomStatus === 'limpieza') {
                            cleaningRooms += roomName + ' ';
                            return;
                        }

                        if (roomStatus === 'pendiente de pago') {
                            paymentPendingRooms += roomName + ' ';
                            return;
                        }
                    });

                    roomsStatus.push({
                        key: 1,
                        status: 'disponible',
                        rooms: availableRooms
                    });

                    roomsStatus.push({
                        key: 2,
                        status: 'bloqueadas',
                        rooms: blockedRooms
                    });

                    roomsStatus.push({
                        key: 3,
                        status: 'sucias',
                        rooms: dirtyRooms
                    });

                    roomsStatus.push({
                        key: 4,
                        status: 'ocupadas',
                        rooms: occupiedRooms
                    });

                    roomsStatus.push({
                        key: 5,
                        status: 'limpieza',
                        rooms: cleaningRooms
                    });

                    roomsStatus.push({
                        key: 7,
                        status: 'pendientes de pago',
                        rooms: paymentPendingRooms
                    });

                    this.setState({roomsStatusEndReport: roomsStatus});
                }

                if (sbHeader.endBlockedRoomStatus !== undefined && sbHeader.endBlockedRoomStatus.length > 0) {
                    const blockedStatusItems = sbHeader.endBlockedRoomStatus;

                    var blockedStatus = [];

                    blockedStatusItems.forEach(block => {
                        blockedStatus.push({
                            key: block.room,
                            timestamp: new Date(block.timestamp).toLocaleString(),
                            room: block.room,
                            reason: block.blockReason
                        });
                    });

                    this.setState({blockedStatusReport: blockedStatus});
                }

                if (sbHeader.endRentedRoomStatus !== undefined && sbHeader.endRentedRoomStatus.length > 0) {
                    const rentedStatusEnd = sbHeader.endRentedRoomStatus;
                    var rentedRoomStatus = [];

                    rentedStatusEnd.forEach(room => {
                        rentedRoomStatus.push({
                            key: room.roomId,
                            room: room.roomId,
                            rentType: (room.rentMode === 1) ? 'días' : 'horas',
                            roomType: room.rentType,
                            rentTimestamp: new Date(room.rentTimestamp).toLocaleString(),
                            checkoutTimestamp: new Date(room.checkoutTimestamp).toLocaleString(),
                            licensePlate: room.customerPlate + '; ' + room.customerState
                        });
                    });

                    this.setState({rentedStatusReport: rentedRoomStatus});
                }

                if (sbHeader.totals !== undefined && sbHeader.totals.length > 0) {
                    const totals = sbHeader.totals;
                    const absTotals = totals[0];
                    const depositTotals = totals[1];
                    const roomTotals = totals[2];
                    const orderTotals = totals[3];
                    const rTypeTotals = totals[4];

                    var income = [];
                    var roomTypeTotals = [];
                    var actions = [];

                    // FOR TOTALS TABLE
                    const printRoomTypeNames = [];
                    const printRoomTypePrices = [];
                    const printRoomTypeNumberOfRents = [];
                    var printSumActionsMxn = 0;
                    var printSumActionsUsd = 0;

                    var difference = absTotals.sbAbsTotal - depositTotals.depositAbsTotal;

                    income.push({ key: 1, type: 'Ingresos mxn', amount: '$' + absTotals.sbTotalMxn });
                    income.push({ key: 2, type: 'Ingresos usd', amount: '$' + absTotals.sbTotalUsd });
                    income.push({ key: 3, type: 'Ingresos tarjeta', amount: '$' + absTotals.sbTotalCard });
                    income.push({ key: 4, type: 'TOTAL INGRESOS', amount: <strong>$ {absTotals.sbAbsTotal}</strong> });
                    income.push({ key: 5, type: '', amount: '' });
                    income.push({ key: 6, type: 'Depósitos mxn', amount: '$' + depositTotals.depositTotalMxn });
                    income.push({ key: 7, type: 'Depósitos usd', amount: '$' + depositTotals.depositTotalUsd });
                    income.push({ key: 8, type: 'Depósitos vales', amount: '$' + depositTotals.depositTotalTickets });
                    income.push({ key: 9, type: 'TOTAL DEPÓSITOS', amount: <strong>$ {depositTotals.depositAbsTotal}</strong> });
                    income.push({ key: 10, type: '', amount: '' });
                    income.push({ key: 11, type: 'Diferencia', amount: <strong>$ {difference}</strong> });

                    var roomTypeIds = new Set();
                    var roomTypeNames = {};
                    var roomTypeNumbers = {};
                    var roomTypeMonies = {};

                    for (var key in rTypeTotals) {
                        const roomTypeId = parseInt(key.split('D').pop(), 10);
                        const roomType = this.state.roomTypeHeaders.find( rt => rt.key === roomTypeId);

                        roomTypeIds.add(roomTypeId);
                        roomTypeNames[roomTypeId] = roomType.name;

                        if (key.includes('rtNumber')) {
                            roomTypeNumbers[roomTypeId] = rTypeTotals[key];

                            //POPULATING PRINT TABLE
                            printRoomTypeNames.push(<th key={key}>{roomType.name}</th>);
                            printRoomTypePrices.push(<td key={key}>{roomType.price}</td>);
                            printRoomTypeNumberOfRents.push(<td key={key}>{rTypeTotals[key]}</td>);
                        }

                        if (key.includes('rtTotal')) {
                            roomTypeMonies[roomTypeId] = rTypeTotals[key];
                        }
                    }

                    roomTypeIds.forEach( rtId => {
                        roomTypeTotals.push({ key: rtId, type: roomTypeNames[rtId], numberOfRents: roomTypeNumbers[rtId], amount: '$' + roomTypeMonies[rtId] });
                    });

                    const sumActionsNumber = orderTotals.numberOfOrders  + roomTotals.numberOfRents 
                        + roomTotals.numberOfDaysAdditions + roomTotals.numberOfGuestAdditions
                        + roomTotals.numberOfChanges + roomTotals.numberOfCharges
                        + roomTotals.numberOfCheckouts + roomTotals.numberOfBlocks
                        + roomTotals.numberOfUnblocks + roomTotals.numberOfCancellations
                        + roomTotals.numberOfCleans + roomTotals.numberOfReservations
                        + roomTotals.numberOfReservationTickets;
                    const sumActionsMxn = orderTotals.orderTotalMxn  + roomTotals.rentTotalMxn 
                        + roomTotals.addDaysTotalMxn + roomTotals.addGuestTotalMxn
                        + roomTotals.changeTotalMxn + roomTotals.chargeTotalMxn
                        + roomTotals.cancelTotalMxn + roomTotals.reservationsTotalMxn
                        + roomTotals.reservationTicketsTotalMxn;
                    const sumActionsUsd = orderTotals.orderTotalUsd  + roomTotals.rentTotalUsd 
                        + roomTotals.addDaysTotalUsd + roomTotals.addGuestTotalUsd
                        + roomTotals.changeTotalUsd + roomTotals.chargeTotalUsd
                        + roomTotals.reservationsTotalUsd;
                    const sumActionsCard = orderTotals.orderTotalCard  + roomTotals.rentTotalCard 
                        + roomTotals.addDaysTotalCard + roomTotals.addGuestTotalCard
                        + roomTotals.changeTotalCard + roomTotals.chargeTotalCard
                        + roomTotals.reservationsTotalCard;
                    const sumAbsActions = orderTotals.orderAbsTotal  + roomTotals.rentAbsTotal 
                        + roomTotals.addDaysAbsTotal + roomTotals.addGuestAbsTotal
                        + roomTotals.changeAbsTotal + roomTotals.chargeAbsTotal
                        + roomTotals.cancelTotalMxn + roomTotals.reservationsAbsTotal
                        + roomTotals.reservationTicketsAbsTotal;
                    actions.push({ key: 1, actions: 'Órdenes', amount: orderTotals.numberOfOrders, mxn: '$' + orderTotals.orderTotalMxn, usd: '$' + orderTotals.orderTotalUsd, card: '$' + orderTotals.orderTotalCard, total: '$' + orderTotals.orderAbsTotal });
                    actions.push({ key: 2, actions: 'Rentas', amount: roomTotals.numberOfRents, mxn: '$' + roomTotals.rentTotalMxn, usd: '$' + roomTotals.rentTotalUsd, card: '$' + roomTotals.rentTotalCard, total: '$' + roomTotals.rentAbsTotal });
                    actions.push({ key: 3, actions: 'Días adicionales', amount: roomTotals.numberOfDaysAdditions, mxn: '$' + roomTotals.addDaysTotalMxn, usd: '$' + roomTotals.addDaysTotalUsd, card: '$' + roomTotals.addDaysTotalCard, total: '$' + roomTotals.addDaysAbsTotal });
                    actions.push({ key: 4, actions: 'Personas agregadas', amount: roomTotals.numberOfGuestAdditions, mxn: '$' + roomTotals.addGuestTotalMxn, usd: '$' + roomTotals.addGuestTotalUsd, card: '$' + roomTotals.addGuestTotalCard, total: '$' + roomTotals.addGuestAbsTotal });
                    actions.push({ key: 5, actions: 'Cambios', amount: roomTotals.numberOfChanges, mxn: '$' + roomTotals.changeTotalMxn, usd: '$' + roomTotals.changeTotalUsd, card: '$' + roomTotals.changeTotalCard, total: '$' + roomTotals.changeAbsTotal });
                    actions.push({ key: 6, actions: 'Cargos', amount: roomTotals.numberOfCharges, mxn: '$' + roomTotals.chargeTotalMxn, usd: '$' + roomTotals.chargeTotalUsd, card: '$' + roomTotals.chargeTotalCard, total: '$' + roomTotals.chargeAbsTotal });
                    actions.push({ key: 7, actions: 'Salidas', amount: roomTotals.numberOfCheckouts, mxn: '', usd: '', card: '', total: '' });
                    actions.push({ key: 8, actions: 'Bloqueos', amount: roomTotals.numberOfBlocks, mxn: '', usd: '', card: '', total: '' });
                    actions.push({ key: 9, actions: 'Desbloqueos', amount: roomTotals.numberOfUnblocks, mxn: '', usd: '', card: '', total: '' });
                    actions.push({ key: 10, actions: 'Cancelaciones', amount: roomTotals.numberOfCancellations, mxn: '$' + roomTotals.cancelTotalMxn, usd: '-', card: '-', total: '$' + roomTotals.cancelTotalMxn });
                    actions.push({ key: 11, actions: 'Limpiezas', amount: roomTotals.numberOfCleans, mxn: '', usd: '', card: '', total: '' });
                    actions.push({ key: 12, actions: 'Reservaciones', amount: roomTotals.numberOfReservations, mxn: '$' + roomTotals.reservationsTotalMxn, usd: '$' + roomTotals.reservationsTotalUsd, card: '$' + roomTotals.reservationsTotalCard, total: '$' + roomTotals.reservationsAbsTotal });
                    actions.push({ key: 13, actions: 'Vales de reservación', amount: roomTotals.numberOfReservationTickets, mxn: '$' + roomTotals.reservationTicketsTotalMxn, usd: '-', card: '-', total: '$' + roomTotals.reservationTicketsAbsTotal });
                    actions.push({ key: 14, actions: 'TOTAL', amount: <strong>{sumActionsNumber}</strong>, mxn: <strong>$ {sumActionsMxn}</strong>, usd: <strong>$ {sumActionsUsd}</strong>, card: <strong>$ {sumActionsCard}</strong>, total: <strong>$ {sumAbsActions}</strong> });
                    
                    printSumActionsMxn = sumActionsMxn + sumActionsCard;
                    printSumActionsUsd = sumActionsUsd;

                    // NOW ADD ABS TOTALS TO PRINTED TABLE
                    printRoomTypeNames.push(<th key={99995}>Adicionales</th>);
                    printRoomTypeNames.push(<th key={99996}>Cargos</th>);
                    printRoomTypeNames.push(<th key={99997}>Bar y otros</th>);
                    printRoomTypeNames.push(<th key={99998}></th>);
                    printRoomTypeNames.push(<th key={99999}></th>);

                    printRoomTypePrices.push(<td key={99995}>$150</td>);
                    printRoomTypePrices.push(<td key={99996}></td>);
                    printRoomTypePrices.push(<th key={99997}></th>);
                    printRoomTypePrices.push(<th key={99998}>Pesos</th>);
                    printRoomTypePrices.push(<th key={99999}>Dólares</th>);

                    printRoomTypeNumberOfRents.push(<td key={99995}>{roomTotals.numberOfGuestAdditions}</td>);
                    printRoomTypeNumberOfRents.push(<td key={99996}>{roomTotals.chargeAbsTotal}</td>);
                    printRoomTypeNumberOfRents.push(<td key={99997}>{orderTotals.orderAbsTotal}</td>);
                    printRoomTypeNumberOfRents.push(<td key={99998}>{printSumActionsMxn}</td>);
                    printRoomTypeNumberOfRents.push(<td key={99999}>{printSumActionsUsd}</td>);

                    var printRoomTypeTable = (
                        <table border="1">
                            <tbody>
                                <tr>{printRoomTypeNames}</tr>
                                <tr>{printRoomTypePrices}</tr>
                                <tr>{printRoomTypeNumberOfRents}</tr>
                            </tbody>
                        </table>
                    );

                    this.setState({
                        income: income,
                        roomTypeTotals: roomTypeTotals,
                        actions: actions,
                        printRoomTypeTable: printRoomTypeTable
                    });
                }

                return sbHeader;
            })
            .then(async sbHeader => {
                if (sbHeader.endTimestamp !== undefined) {
                    await API.get('benackoAPI', '/v2/actions?hotelId=' + this.hotelId + '&type=' + this.sbType + '&startTimestamp=' + this.id + '&endTimestamp=' + sbHeader.endTimestamp, {
                            headers: {
                                Authorization: this.idToken
                            }
                        })
                        .then( actionsRes => {
                            if (actionsRes.Items !== undefined && actionsRes.Items.length > 0) {
                                const actions = actionsRes.Items;
                                var rents = [];
                                var rentDetails = [];
                                var daysAdditions = [];
                                var guestAdditions = [];
                                var changes = [];
                                var charges = [];
                                var checkouts = [];
                                var cancellations = [];
                                var blocks = [];
                                var unblocks = [];
                                var cleans = [];
                                var reservationTickets = [];
                                var reservations = [];
                                var usedReservations = [];//SAVED RENTS WITH RESERVATIONS HERE

                                var maids = new Set();
                                var cleanTotals = {};
                                var cleanTotalDirty = {};
                                var cleanDirtyRooms = {};
                                var cleanTotalOccupied = {};
                                var cleanOccupiedRooms = {};

                                actions.forEach( action => {
                                    const room = (action.room === '0') ? 'Recepción' : action.room;
                                    const user = action.user;


                                    // ROOM RENT
                                    if (action.itemType === 'rentAction') {

                                        rents.push({
                                            key: action.SK,
                                            sortingKey: action.SK,
                                            room: room,
                                            rentTimestamp: new Date(action.SK).toLocaleString(),
                                            rentMethod: (action.rentMethod === 1) ? 'normal' : 'reservación',
                                            rentType: (action.rentType === 1) ? 'días' : 'horas',
                                            rentRoomType: action.rentRoomType,
                                            rentPeriod: action.numberOfDays,
                                            addedGuests: action.numberOfAdditionalGuests,
                                            checkoutTimestamp: new Date(action.checkoutTimestamp).toLocaleString()
                                        });
                                        
                                        rentDetails.push({
                                            key: action.SK,
                                            sortingKey: action.SK,
                                            room: room,
                                            rentTimestamp: new Date(action.SK).toLocaleString(),
                                            customerPlates: action.customerPlate + '; ' + action.customerState,
                                            rentTotal: (action.rentTotalDays === 0) ? '-' : '$' + action.rentTotalDays,
                                            guestsTotal: (action.rentTotalAdditionalGuests === 0) ? '-' : '$' + action.rentTotalAdditionalGuests,
                                            mxn: (action.paymentMxn != null) ? '$' + action.paymentMxn : '-',
                                            usd: (action.paymentUsd != null) ? '$' + action.paymentUsd : '-',
                                            card: (action.paymentCardAmount != null) ? '$' + action.paymentCardAmount : '-',
                                            voucher: (action.paymentCardVoucher != null || action.paymentCardVoucher === 'CASH') ? action.paymentCardVoucher : '-'
                                        });

                                        //if a rent action is done by reservation, store it
                                        if (action.rentMethod !== 1) {
                                            usedReservations.push({
                                                key: action.SK,
                                                sortingKey: action.SK,
                                                room: room,
                                                rentTimestamp: new Date(action.SK).toLocaleString(),
                                                rentTotal: (action.rentTotalDays === 0) ? '-' : '$' + action.rentTotalDays,
                                                guestsTotal: (action.rentTotalAdditionalGuests === 0) ? '-' : '$' + action.rentTotalAdditionalGuests,
                                                mxn: (action.paymentMxn != null) ? '$' + action.paymentMxn : '-',
                                                usd: (action.paymentUsd != null) ? '$' + action.paymentUsd : '-',
                                                card: (action.paymentCardAmount != null) ? '$' + action.paymentCardAmount : '-',
                                                voucher: (action.paymentCardVoucher != null || action.paymentCardVoucher === 'CASH') ? action.paymentCardVoucher : '-'
                                            });
                                        }
                                    }
                                    
                                    // DAYS ADDITION
                                    if (action.itemType === 'roomAdditionAction') {console.log('add new fields for rent extension', action);
                                        daysAdditions.push({
                                            key: action.SK,
                                            sortingKey: action.SK,
                                            timestamp: new Date(action.SK).toLocaleString(),
                                            room: room,
                                            addedDays: action.numberOfDays,
                                            total: '$' + action.daysAdditionTotal,
                                            newCheckoutTimestamp: new Date(action.newCheckoutDate).toLocaleString(),
                                            mxn: (action.paymentMxn != null) ? '$' + action.paymentMxn : '-',
                                            usd: (action.paymentUsd != null) ? '$' + action.paymentUsd : '-',
                                            card: (action.paymentCardAmount != null) ? '$' + action.paymentCardAmount : '-',
                                            voucher: (action.paymentCardVoucher != null || action.paymentCardVoucher === 'CASH') ? action.paymentCardVoucher : '-'
                                        });
                                    }
                                    
                                    // GUEST ADDITION
                                    if (action.itemType === 'guestAdditionAction') {
                                        
                                        guestAdditions.push({
                                            key: action.SK,
                                            sortingKey: action.SK,
                                            timestamp: new Date(action.SK).toLocaleString(),
                                            room: room,
                                            user: user,
                                            guests: action.numberOfGuests,
                                            total: '$' + action.guestAdditionTotal,
                                            mxn: (action.paymentMxn != null) ? '$' + action.paymentMxn : '-',
                                            usd: (action.paymentUsd != null) ? '$' + action.paymentUsd : '-',
                                            card: (action.paymentCardAmount != null) ? '$' + action.paymentCardAmount : '-',
                                            voucher: (action.paymentCardVoucher != null || action.paymentCardVoucher === 'CASH') ? action.paymentCardVoucher : '-'
                                        });
                                    }
                                    
                                    // ROOM CHANGE
                                    if (action.itemType === 'changeAction') {
                                        changes.push({
                                            key: action.SK,
                                            sortingKey: action.SK,
                                            srcRoom: action.srcRoom,
                                            srcRoomType: action.srcRoomType,
                                            timestamp: new Date(action.SK).toLocaleString(),
                                            destRoom: action.destRoom,
                                            destRoomType: action.destRoomType,
                                            user: user,
                                            reason: action.changeReason,
                                            mxn: (action.paymentMxn != null) ? '$' + action.paymentMxn : '-',
                                            usd: (action.paymentUsd != null) ? '$' + action.paymentUsd : '-',
                                            card: (action.paymentCardAmount != null) ? '$' + action.paymentCardAmount : '-',
                                            voucher: (action.paymentCardVoucher != null || action.paymentCardVoucher === 'CASH') ? action.paymentCardVoucher : '-'
                                        });
                                    }

                                    // ROOM CHARGE
                                    if (action.itemType === 'chargeAction') {
                                        charges.push({
                                            key: action.SK,
                                            sortingKey: action.SK,
                                            timestamp: new Date(action.SK).toLocaleString(),
                                            room: room,
                                            user: user,
                                            reason: action.chargeReason,
                                            mxn: (action.paymentMxn != null) ? '$' + action.paymentMxn : '-',
                                            usd: (action.paymentUsd != null) ? '$' + action.paymentUsd : '-',
                                            card: (action.paymentCardAmount != null) ? '$' + action.paymentCardAmount : '-',
                                            voucher: (action.paymentCardVoucher != null || action.paymentCardVoucher === 'CASH') ? action.paymentCardVoucher : '-'
                                        });
                                    }

                                    // ROOM CANCELLATIONS
                                    if (action.itemType === 'cancelAction') {
                                        cancellations.push({
                                            key: action.SK,
                                            sortingKey: action.SK,
                                            timestamp: new Date(action.SK).toLocaleString(),
                                            room: room,
                                            user: user,
                                            mxn: '$' + action.refundMxn,
                                            reason: action.cancelReason
                                        });
                                    }

                                    // ROOM CHECKOUTS
                                    if (action.itemType === 'checkoutAction') {
                                        var timestampLabel = '';

                                        if (action.SK > action.expectedCheckout) {
                                            timestampLabel = new Date(action.SK).toLocaleString() + ' ***';
                                        } else {
                                            timestampLabel = new Date(action.SK).toLocaleString();
                                        }

                                        checkouts.push({
                                            key: action.SK,
                                            sortingKey: action.SK,
                                            timestamp: timestampLabel,
                                            room: room,
                                            user: user,
                                            expectedCheckout: new Date(action.expectedCheckout).toLocaleString(),
                                            comments: action.comments
                                        });
                                    }

                                    // ROOM BLOCKS
                                    if (action.itemType === 'blockAction') {
                                        blocks.push({
                                            key: action.SK,
                                            sortingKey: action.SK,
                                            timestamp: new Date(action.SK).toLocaleString(),
                                            room: room,
                                            user: user,
                                            reason: action.blockReason
                                        });
                                    } 
                                    
                                    // ROOM UNBLOCKS
                                    if (action.itemType === 'unblockAction') {
                                        unblocks.push({
                                            key: action.SK,
                                            sortingKey: action.SK,
                                            timestamp: new Date(action.SK).toLocaleString(),
                                            room: room,
                                            user: user,
                                            comments: action.unblockReason
                                        });
                                    }

                                    // ROOM CLEANS
                                    if (action.itemType === 'cleanAction') {
                                        const maidId = action.maid;
                                        const cleanTimeDifference = action.SK - action.startTimestamp;

                                        const startTime = new Date(action.startTimestamp).toLocaleString();
                                        const endTime = new Date(action.SK).toLocaleString() +
                                            ( (cleanTimeDifference > 30000) ? ' ***' : '' );

                                        cleans.push({
                                            key: action.SK,
                                            sortingKey: action.SK,
                                            startTimestamp: startTime,
                                            room: room,
                                            user: user,
                                            maid: maidId,
                                            endTimestamp: endTime,
                                            roomStatus: action.previousRoomStatus
                                        });

                                        if (maids.has(maidId)) {
                                            cleanTotals[maidId] += 1;
                                        } else {
                                            maids.add(maidId);
                                            cleanTotals[maidId] = 1;
                                        }

                                        if (action.previousRoomStatus === 'sucia') {
                                            
                                            if (cleanTotalDirty[maidId] == null) {
                                                cleanTotalDirty[maidId] = 1;
                                                cleanDirtyRooms[maidId] = room + ' ';
                                            } else {
                                                cleanTotalDirty[maidId] += 1;
                                                cleanDirtyRooms[maidId] += room + ' ';
                                            }
                                        } else {
                                            if (cleanTotalOccupied[maidId] == null) {
                                                cleanTotalOccupied[maidId] = 1;
                                                cleanOccupiedRooms[maidId] = room + ' ';
                                            } else {
                                                cleanTotalOccupied[maidId] += 1;
                                                cleanOccupiedRooms[maidId] += room + ' ';
                                            }
                                        }
                                    }

                                    // RESERVATION TICKETS
                                    if (action.itemType === 'reservationTicket') {
                                        reservationTickets.push({
                                            key: action.SK,
                                            sortingKey: action.SK,
                                            timestamp: new Date(action.SK).toLocaleString(),
                                            client: action.client,
                                            concept: action.concept,
                                            amount: '$' + action.amount
                                        });
                                    }

                                    // RESERVATIONS
                                    if (action.itemType === 'reservationAction') {
                                        reservations.push({
                                            key: action.SK,
                                            sortingKey: action.SK,
                                            timestamp: new Date(action.SK).toLocaleString(),
                                            client: action.client,
                                            reservationTimestamp: new Date(action.reservationTimestamp).toDateString(),
                                            total: '$' + action.reservationTotal,
                                            mxn: '$' + action.paymentMxn,
                                            usd: '$' + action.paymentUsd,
                                            card: '$' + action.paymentCardAmount,
                                            voucher: action.paymentCardVoucher,
                                            comments: action.comments
                                        });
                                    }
                                });

                                var maidCleans = [];
                                maids.forEach( maid => {
                                    maidCleans.push({
                                        key: maid,
                                        name: maid,
                                        rooms: cleanTotals[maid],
                                        prevDirty: cleanTotalDirty[maid],
                                        prevDirtyRooms: cleanDirtyRooms[maid],
                                        prevOccupied: cleanTotalOccupied[maid],
                                        prevOccupiedRooms: cleanOccupiedRooms[maid]
                                    });
                                });

                                this.setState({
                                    roomRents: rents,
                                    roomRentDetails: rentDetails,
                                    roomDaysAdditions: daysAdditions,
                                    roomGuestAdditions: guestAdditions,
                                    roomChanges: changes,
                                    roomCharges: charges,
                                    roomCancellations: cancellations,
                                    roomCheckouts: checkouts,
                                    roomBlocks: blocks,
                                    roomUnblocks: unblocks,
                                    roomCleans: cleans,
                                    maidCleans: maidCleans,
                                    reservationTickets: reservationTickets,
                                    reservations: reservations,
                                    usedReservations: usedReservations
                                });
                            }
                        })
                        .catch( errRoomActions => {
                            console.log('Error loading room actions:', errRoomActions);
                            this.setState({loading: false});
                        });
                }

                return sbHeader;
            })
            .then(async sbHeader => {
                if (sbHeader.endTimestamp !== undefined) {
                    await API.get('benackoAPI', '/v2/actions/service?hotelId=' + this.hotelId + '&type=' + this.sbType + '&startTimestamp=' + this.id + '&endTimestamp=' + sbHeader.endTimestamp, {
                        headers: {
                            Authorization: this.idToken
                        }
                    })
                    .then( ordersRes => {
                        var orders = [];
                        var orderDetails = [];

                        if (ordersRes.Items !== undefined && ordersRes.Items.length > 0) {
                            ordersRes.Items.forEach(action => {
                                orders.push({
                                    key: action.SK,
                                    sortingKey: action.orderTimestamp,
                                    room: action.room,
                                    orderTimestamp: new Date(action.orderTimestamp).toLocaleString(),
                                    orderUser: action.orderUser,
                                    orderTotal: '$' + action.orderTotal,
                                    mxn: (action.paymentMxn != null) ? '$' + action.paymentMxn : '-',
                                    usd: (action.paymentUsd != null) ? '$' + action.paymentUsd : '-',
                                    card: (action.paymentCardAmount != null) ? '$' + action.paymentCardAmount : '-',
                                    voucher: (action.paymentCardVoucher == null || action.paymentCardVoucher === 'CASH') ? '-' : action.paymentCardVoucher,
                                    chargeTimestamp: new Date(action.SK).toLocaleString(),
                                    chargeUser: action.chargeUser,
                                });
    
                                action.products.forEach( product => {
                                    orderDetails.push({
                                        key: action.SK + product.productId,
                                        sortingKey: action.orderTimestamp,
                                        room: action.room,
                                        orderTimestamp: new Date(action.orderTimestamp).toLocaleString(),
                                        product: product.productName,
                                        quantity: product.quantity
                                    });
                                });
                            });

                            orders.sort((a, b) => {return(a.sortingKey > b.sortingKey)});
                            orderDetails.sort((a, b) => {return(a.product > b.product)});
                        }

                        this.setState({
                            roomOrders: orders,
                            roomOrderDetails: orderDetails,
                        });
                    })
                    .catch(errOrders => {
                        console.log('Error loading orders:', errOrders);
                        this.setState({loading: false});
                    });
                }

                return sbHeader;
            })
            .then(async sbHeader => {
                if (sbHeader.endTimestamp !== undefined) {
                API.get('benackoAPI', '/v2/reports/shift-balances/deposits?hotelId=' + this.hotelId + '&type=' + this.sbType + '&startTimestamp=' + this.id + '&endTimestamp=' + sbHeader.endTimestamp, {
                        headers: {
                            Authorization: this.idToken
                        }
                    })
                    .then(depositsRes => {
                        var depositTickets = [];

                        if (depositsRes.Items.length > 0) {
                            var ticketNumber = 0

                            depositsRes.Items.forEach( deposit => {    
                                deposit.tickets.forEach( ticket => {
                                    depositTickets.push({
                                        key: deposit.PK + '' + ticketNumber,
                                        concept: ticket.concept,
                                        amount: '$' + ticket.amount
                                    });
                                    ticketNumber += 1;
                                });
                            });
                        }
                        
                        this.setState({
                            depositTickets: depositTickets
                        });
                    })
                    .catch(errDeposits => {
                        console.log('Error loading deposits:', errDeposits);
                        this.setState({loading: false});
                    });
                }

                return sbHeader;
            })
            .then(async sbHeader => {
                if (sbHeader.endTimestamp !== undefined) {
                    const eprUrl = '/v2/reports/shift-balances/end-pending-reservations?hotelId=' + this.hotelId + '&type=' + this.sbType + '&startTimestamp=' + this.id;
                    await API.get('benackoAPI', eprUrl, {
                            headers: {
                                Authorization: this.idToken
                            }
                        })
                        .then(reservationsRes => {
                            var reservations = [];
                            let newReservationTotal = 0;

                            if (reservationsRes.Items != null && reservationsRes.Items.length > 0) {
                                const pendingReservations = reservationsRes.Items[0].epr;
                                
                                pendingReservations.forEach(reservation => {
                                    newReservationTotal += reservation.reservationTotal;
                                    
                                    reservations.push({
                                        key: reservation.timestamp,
                                        timestamp: new Date(reservation.timestamp).toLocaleDateString(),
                                        client: reservation.client,
                                        reservationTimestamp: new Date(reservation.reservationTimestamp).toLocaleDateString(),
                                        reservationTotal: '$' + reservation.reservationTotal,
                                        comments: reservation.comments
                                    });
                                });
                            }

                            this.setState({endPendingReservations: reservations, endPendingReservationsTotal: newReservationTotal});
                        })
                        .catch(errPendRes => {
                            console.log('Error loading pending reservations:', errPendRes);
                            this.setState({loading: false});
                        });
                }
            })
            .then(async () => {
                await API.get('benackoAPI', '/users/details?companyId=' + this.props.companyId, {
                        headers: {
                            Authorization: this.idToken
                        }
                    })
                    .then( usersRes => {
                        var usersDict = [];

                        usersRes.forEach( user => {
                            usersDict[user.id] = user.username;
                        });

                        this.setState({adUsers: usersDict});
                    })
                    .catch( errAdUsers => {
                        console.log('Error loading admin users:', errAdUsers);
                        this.setState({loading: false});
                    });
            })
            .then(async () => {
                await API.get('benackoAPI', '/v2/reports/shift-balances/prevnext?hotelId=' + this.hotelId + '&type=' + this.sbType + '&startTimestamp=' + this.id, {
                        headers: {
                            Authorization: this.idToken
                        }
                    })
                    .then(prevNextRes => {
                        const prevSbId = (prevNextRes.prev !== 0) ? prevNextRes.prev : false;
                        const nextSbId = (prevNextRes.next !== 0) ? prevNextRes.next : false;

                        this.setState({
                            prevSbId: prevSbId,
                            nextSbId: nextSbId
                        });

                        // WORK ON PREVIOUS
                        if (prevSbId !== false) {
                            API.get('benackoAPI', '/v2/reports/shift-balances?hotelId=' + this.hotelId + '&type=' + this.sbType + '&startTimestamp=' + prevSbId, {
                                    headers: {
                                        Authorization: this.idToken
                                    }
                                })
                                .then(prevRes => {
                                    if (prevRes.Items !== undefined && prevRes.Items.length > 0) {
                                        const sbHeader = prevRes.Items[0];

                                        // INITIAL ROOM STATUS
                                        if (sbHeader.endRoomStatus !== undefined && sbHeader.endRoomStatus.length > 0) {
                                            const endRoomStatus = sbHeader.endRoomStatus;
                        
                                            var roomsStatus = [];
                                            var availableRooms = '';
                                            var occupiedRooms = '';
                                            var dirtyRooms = '';
                                            var blockedRooms = '';
                                            var cleaningRooms = '';
                                            var paymentPendingRooms = '';
                        
                                            endRoomStatus.forEach(room => {
                                                const roomStatus = room.status;
                                                const roomName = room.room;
                        
                                                if (roomStatus === 'disponible') {
                                                    availableRooms += roomName + ' ';
                                                    return;
                                                }
                        
                                                if (roomStatus === 'bloqueada') {
                                                    blockedRooms += roomName + ' ';
                                                    return;
                                                }
                        
                                                if (roomStatus === 'sucia') {
                                                    dirtyRooms += roomName + ' ';
                                                    return;
                                                }
                        
                                                if (roomStatus === 'ocupada') {
                                                    occupiedRooms += roomName + ' ';
                                                    return;
                                                }
                        
                                                if (roomStatus === 'limpieza') {
                                                    cleaningRooms += roomName + ' ';
                                                    return;
                                                }
                        
                                                if (roomStatus === 'pendiente de pago') {
                                                    paymentPendingRooms += roomName + ' ';
                                                    return;
                                                }
                                            });
                        
                                            roomsStatus.push({
                                                key: 1,
                                                status: 'disponible',
                                                rooms: availableRooms
                                            });
                        
                                            roomsStatus.push({
                                                key: 2,
                                                status: 'bloqueadas',
                                                rooms: blockedRooms
                                            });
                        
                                            roomsStatus.push({
                                                key: 3,
                                                status: 'sucias',
                                                rooms: dirtyRooms
                                            });
                        
                                            roomsStatus.push({
                                                key: 4,
                                                status: 'ocupadas',
                                                rooms: occupiedRooms
                                            });
                        
                                            roomsStatus.push({
                                                key: 5,
                                                status: 'limpieza',
                                                rooms: cleaningRooms
                                            });
                        
                                            roomsStatus.push({
                                                key: 7,
                                                status: 'pendientes de pago',
                                                rooms: paymentPendingRooms
                                            });
                        
                                            this.setState({initialRoomsStatusReport: roomsStatus});
                                        }

                                        // INITIAL RENTED ROOM STATUS
                                        if (sbHeader.endRentedRoomStatus !== undefined && sbHeader.endRentedRoomStatus.length > 0) {
                                            const rentedStatusEnd = sbHeader.endRentedRoomStatus;
                                            var rentedRoomStatus = [];
                        
                                            rentedStatusEnd.forEach(room => {
                                                rentedRoomStatus.push({
                                                    key: room.roomId,
                                                    room: room.roomId,
                                                    rentType: (room.rentMode === 1) ? 'días' : 'horas',
                                                    roomType: room.rentType,
                                                    rentTimestamp: new Date(room.rentTimestamp).toLocaleString(),
                                                    checkoutTimestamp: new Date(room.checkoutTimestamp).toLocaleString(),
                                                    licensePlate: room.customerPlate + '; ' + room.customerState
                                                });
                                            });
                        
                                            this.setState({initialRentedRoomsStatusReport: rentedRoomStatus});
                                        }

                                        // INITIAL CLEANS
                                        if (sbHeader.endTimestamp !== undefined) {
                                            API.get('benackoAPI', '/v2/actions?hotelId=' + this.hotelId + '&type=' + this.sbType + '&startTimestamp=' + prevSbId + '&endTimestamp=' + sbHeader.endTimestamp, {
                                                    headers: {
                                                        Authorization: this.idToken
                                                    }
                                                })
                                                .then( actionsRes => {
                                                    const actions = actionsRes.Items;
                                                    var cleans = [];
                        
                                                    var maids = new Set();
                                                    var cleanTotals = {};
                                                    var cleanTotalDirty = {};
                                                    var cleanDirtyRooms = {};
                                                    var cleanTotalOccupied = {};
                                                    var cleanOccupiedRooms = {};
                        
                                                    actions.forEach( action => {
                                                        const room = (action.room === '0') ? 'Recepción' : action.room;
                                                        const user = action.user;
                        
                                                        // ROOM CLEANS
                                                        if (action.itemType === 'cleanAction') {
                                                            const maidId = action.maid;
                                                            const cleanTimeDifference = action.SK - action.startTimestamp;
                        
                                                            const startTime = new Date(action.startTimestamp).toLocaleString();
                                                            const endTime = new Date(action.SK).toLocaleString() +
                                                                ( (cleanTimeDifference > 30000) ? ' ***' : '' );
                        
                                                            cleans.push({
                                                                key: action.SK,
                                                                sortingKey: action.SK,
                                                                startTimestamp: startTime,
                                                                room: room,
                                                                user: user,
                                                                maid: maidId,
                                                                endTimestamp: endTime,
                                                                roomStatus: action.previousRoomStatus
                                                            });
                        
                                                            if (maids.has(maidId)) {
                                                                cleanTotals[maidId] += 1;
                                                            } else {
                                                                maids.add(maidId);
                                                                cleanTotals[maidId] = 1;
                                                            }
                        
                                                            if (action.previousRoomStatus === 'sucia') {
                                                                
                                                                if (cleanTotalDirty[maidId] == null) {
                                                                    cleanTotalDirty[maidId] = 1;
                                                                    cleanDirtyRooms[maidId] = room + ' ';
                                                                } else {
                                                                    cleanTotalDirty[maidId] += 1;
                                                                    cleanDirtyRooms[maidId] += room + ' ';
                                                                }
                                                            } else {
                                                                if (cleanTotalOccupied[maidId] == null) {
                                                                    cleanTotalOccupied[maidId] = 1;
                                                                    cleanOccupiedRooms[maidId] = room + ' ';
                                                                } else {
                                                                    cleanTotalOccupied[maidId] += 1;
                                                                    cleanOccupiedRooms[maidId] += room + ' ';
                                                                }
                                                            }
                                                        }
                                                    });
                        
                                                    var maidCleans = [];
                                                    maids.forEach( maid => {
                                                        maidCleans.push({
                                                            key: maid,
                                                            name: maid,
                                                            rooms: cleanTotals[maid],
                                                            prevDirty: cleanTotalDirty[maid],
                                                            prevDirtyRooms: cleanDirtyRooms[maid],
                                                            prevOccupied: cleanTotalOccupied[maid],
                                                            prevOccupiedRooms: cleanOccupiedRooms[maid]
                                                        });
                                                    });

                                                    cleans.sort((a, b) => {return(a.sortingKey > b.sortingKey)});
                                                    
                                                    this.setState({
                                                        prevRoomCleans: cleans,
                                                        prevMaidCleans: maidCleans
                                                    });
                                                })
                                                .catch( errPrevActions => {
                                                    console.log('Error loading prev actions:', errPrevActions);
                                                    this.setState({loading: false});
                                                });
                                        }

                                        if (sbHeader.endTimestamp != null) {
                                            const eprUrl = '/v2/reports/shift-balances/end-pending-reservations?hotelId=' + this.hotelId + '&type=' + this.sbType + '&startTimestamp=' + prevSbId;
                                            API.get('benackoAPI', eprUrl, {
                                                    headers: {
                                                        Authorization: this.idToken
                                                    }
                                                })
                                                .then(reservationsRes => {
                                                    let newReservationTotal = 0;
                        
                                                    if (reservationsRes.Items != null && reservationsRes.Items.length > 0) {
                                                        const pendingReservations = reservationsRes.Items[0].epr;
                                                        
                                                        pendingReservations.forEach(reservation => {
                                                            newReservationTotal += reservation.reservationTotal;
                                                        });
                                                    }
                        
                                                    this.setState({startPendingReservationsTotal: newReservationTotal});
                                                })
                                                .catch(errPendRes => {
                                                    console.log('Error loading pending reservations:', errPendRes);
                                                    this.setState({loading: false});
                                                });
                                        }
                                    }
                                })
                                .catch(errPrev => {
                                    console.log('Error loading previous:', errPrev);
                                    this.setState({loading: false});
                                });
                        }

                        // WORK ON NEXT
                        if (nextSbId !== false) {
                            API.get('benackoAPI', '/v2/reports/shift-balances?hotelId=' + this.hotelId + '&type=' + this.sbType + '&startTimestamp=' + nextSbId, {
                                    headers: {
                                        Authorization: this.idToken
                                    }
                                })
                                .then(prevRes => {
                                    if (prevRes.Items !== undefined && prevRes.Items.length > 0) {
                                        const sbHeader = prevRes.Items[0];

                                        // NEXT RENTED ROOM STATUS
                                        if (sbHeader.endRentedRoomStatus !== undefined && sbHeader.endRentedRoomStatus.length > 0) {
                                            const rentedStatusEnd = sbHeader.endRentedRoomStatus;
                                            var rentedRoomStatus = [];
                        
                                            rentedStatusEnd.forEach(room => {
                                                rentedRoomStatus.push({
                                                    key: room.roomId,
                                                    room: room.roomId,
                                                    rentType: (room.rentMode === 1) ? 'días' : 'horas',
                                                    roomType: room.rentType,
                                                    rentTimestamp: new Date(room.rentTimestamp).toLocaleString(),
                                                    checkoutTimestamp: new Date(room.checkoutTimestamp).toLocaleString(),
                                                    licensePlate: room.customerPlate + '; ' + room.customerState
                                                });
                                            });
                        
                                            this.setState({nextRentedRoomsStatusReport: rentedRoomStatus});
                                        } else {
                                            this.setState({nextRentedRoomsStatusReport: []});
                                        }

                                        // NEXT CLEANS
                                        if (sbHeader.endTimestamp !== undefined && sbHeader.endTimestamp !== 0) {
                                            API.get('benackoAPI', '/v2/actions?hotelId=' + this.hotelId + '&type=' + this.sbType + '&startTimestamp=' + nextSbId + '&endTimestamp=' + sbHeader.endTimestamp, {
                                                    headers: {
                                                        Authorization: this.idToken
                                                    }
                                                })
                                                .then( actionsRes => {
                                                    const actions = actionsRes.Items;
                                                    var cleans = [];
                        
                                                    var maids = new Set();
                                                    var cleanTotals = {};
                                                    var cleanTotalDirty = {};
                                                    var cleanDirtyRooms = {};
                                                    var cleanTotalOccupied = {};
                                                    var cleanOccupiedRooms = {};
                        
                                                    actions.forEach( action => {
                                                        const room = (action.room === '0') ? 'Recepción' : action.room;
                                                        const user = action.user;
                        
                                                        // ROOM CLEANS
                                                        if (action.itemType === 'cleanAction') {
                                                            const maidId = action.maid;
                                                            const cleanTimeDifference = action.SK - action.startTimestamp;
                        
                                                            const startTime = new Date(action.startTimestamp).toLocaleString();
                                                            const endTime = new Date(action.SK).toLocaleString() +
                                                                ( (cleanTimeDifference > 30000) ? ' ***' : '' );
                        
                                                            cleans.push({
                                                                key: action.SK,
                                                                sortingKey: action.SK,
                                                                startTimestamp: startTime,
                                                                room: room,
                                                                user: user,
                                                                maid: maidId,
                                                                endTimestamp: endTime,
                                                                roomStatus: action.previousRoomStatus
                                                            });
                        
                                                            if (maids.has(maidId)) {
                                                                cleanTotals[maidId] += 1;
                                                            } else {
                                                                maids.add(maidId);
                                                                cleanTotals[maidId] = 1;
                                                            }
                        
                                                            if (action.previousRoomStatus === 'sucia') {
                                                                
                                                                if (cleanTotalDirty[maidId] == null) {
                                                                    cleanTotalDirty[maidId] = 1;
                                                                    cleanDirtyRooms[maidId] = room + ' ';
                                                                } else {
                                                                    cleanTotalDirty[maidId] += 1;
                                                                    cleanDirtyRooms[maidId] += room + ' ';
                                                                }
                                                            } else {
                                                                if (cleanTotalOccupied[maidId] == null) {
                                                                    cleanTotalOccupied[maidId] = 1;
                                                                    cleanOccupiedRooms[maidId] = room + ' ';
                                                                } else {
                                                                    cleanTotalOccupied[maidId] += 1;
                                                                    cleanOccupiedRooms[maidId] += room + ' ';
                                                                }
                                                            }
                                                        }
                                                    });
                        
                                                    var maidCleans = [];
                                                    maids.forEach( maid => {
                                                        maidCleans.push({
                                                            key: maid,
                                                            name: maid,
                                                            rooms: cleanTotals[maid],
                                                            nextDirty: cleanTotalDirty[maid],
                                                            nextDirtyRooms: cleanDirtyRooms[maid],
                                                            nextOccupied: cleanTotalOccupied[maid],
                                                            nextOccupiedRooms: cleanOccupiedRooms[maid]
                                                        });
                                                    });

                                                    cleans.sort((a, b) => {return(a.sortingKey > b.sortingKey)});
                                                    
                                                    this.setState({
                                                        nextRoomCleans: cleans,
                                                        nextMaidCleans: maidCleans
                                                    });
                                                })
                                                .catch( errPrevActions => {
                                                    console.log('Error loading prev actions:', errPrevActions);
                                                    this.setState({loading: false});
                                                });
                                        } else {
                                            this.setState({
                                                nextRoomCleans: [],
                                                nextMaidCleans: []
                                            });
                                        }
                                    }
                                })
                                .catch(errPrev => {
                                    console.log('Error loading previous:', errPrev);
                                    this.setState({loading: false});
                                });
                        }
                    })
                    .catch(errPrevNext => {
                        console.log('Error loading prevNext:', errPrevNext);
                        this.setState({loading: false});
                    });
            })
            .then(() => {
                this.loadComments(this.id);
            })
            .then(() => {
                this.setState({loading: false});
            })
            .catch(errSb => {
                console.log('Error loading shift balance information:', errSb);
                this.setState({loading: false});
            });
    }

    async loadComments(loadSbId) {
        this.setState({loadingComments: true});

        await API.get('benackoAPI', '/v2/reports/shift-balances?hotelId=' + this.hotelId + '&type=' + this.sbType + '&startTimestamp=' + loadSbId, {
            headers: {
                Authorization: this.idToken
            }
        })
        .then( sbHeaderResponse => {
            if (sbHeaderResponse.Items.length > 0 && sbHeaderResponse.Items[0].comments !== undefined && sbHeaderResponse.Items[0].comments.length > 0) {
                const comments = sbHeaderResponse.Items[0].comments;
                var generalComments = [];
                var initialRoomStatusComments = [];
                var initialRentedRoomStatusComments = [];
                var roomEndStatusComments = [];
                var roomBlockedStatusComments = [];
                var rentedRoomsStatusComments = [];
                var nextRentedRoomsStatusComments = [];
                var orderComments = [];
                var rentComments = [];
                var daysAdditionComments = [];
                var guestAdditionComments = [];
                var roomChangesComments = [];
                var roomChargesComments = [];
                var roomCancellationsComments = [];
                var roomCheckoutsComments = [];
                var roomBlocksComments = [];
                var roomUnblocksComments = [];
                var roomCleansComments = [];
                var prevRoomCleansComments = [];
                var nextRoomCleansComments = [];
                var endPendingReservationsComments = [];
                var reservationTicketsComments = [];
                var reservationsComments = [];
                
                if (comments) {
                    comments.forEach( (comment, index) => {
                        var com = {
                            key: index,
                            username: comment.username,
                            timestamp: new Date(comment.timestamp).toLocaleString(),
                            comment: comment.comment
                        };

                        if (comment.section === 'general') {
                            generalComments.push(com);
                        } else if (comment.section === 'initial_room_status') {
                            initialRoomStatusComments.push(com);
                        } else if (comment.section === 'initial_rented_room_status') {
                            initialRentedRoomStatusComments.push(com);
                        } else if (comment.section === 'room_end_status') {
                            roomEndStatusComments.push(com);
                        } else if (comment.section === 'room_blocked_status') {
                            roomBlockedStatusComments.push(com);
                        } else if (comment.section === 'rented_room_status') {
                            rentedRoomsStatusComments.push(com);
                        } else if (comment.section === 'next_rented_room_status') {
                            nextRentedRoomsStatusComments.push(com);
                        } else if (comment.section === 'room_orders') {
                            orderComments.push(com);
                        } else if (comment.section === 'room_rents') {
                            rentComments.push(com);
                        } else if (comment.section === 'days_addition') {
                            daysAdditionComments.push(com);
                        } else if (comment.section === 'guest_addition') {
                            guestAdditionComments.push(com);
                        } else if (comment.section === 'room_changes') {
                            roomChangesComments.push(com);
                        } else if (comment.section === 'room_charges') {
                            roomChargesComments.push(com);
                        } else if (comment.section === 'room_cancellations') {
                            roomCancellationsComments.push(com);
                        } else if (comment.section === 'room_checkouts') {
                            roomCheckoutsComments.push(com);
                        } else if (comment.section === 'room_blocks') {
                            roomBlocksComments.push(com);
                        } else if (comment.section === 'room_unblocks') {
                            roomUnblocksComments.push(com);
                        } else if (comment.section === 'room_cleans') {
                            roomCleansComments.push(com);
                        } else if (comment.section === 'prev_room_cleans') {
                            prevRoomCleansComments.push(com);
                        } else if (comment.section === 'next_room_cleans') {
                            nextRoomCleansComments.push(com);
                        } else if (comment.section === 'end_pending_reservations') {
                            endPendingReservationsComments.push(com);
                        } else if (comment.section === 'reservation_tickets') {
                            reservationTicketsComments.push(com);
                        } else if (comment.section === 'reservations') {
                            reservationsComments.push(com);
                        }
                    });
                }

                this.setState({
                    generalComments: generalComments,
                    initialRoomStatusComments: initialRoomStatusComments,
                    initialRentedRoomStatusComments: initialRentedRoomStatusComments,
                    roomEndStatusComments: roomEndStatusComments,
                    roomBlockedStatusComments: roomBlockedStatusComments,
                    rentedRoomsStatusComments: rentedRoomsStatusComments,
                    nextRentedRoomsStatusComments: nextRentedRoomsStatusComments,
                    roomOrdersComments: orderComments,
                    rentComments: rentComments,
                    daysAdditionComments: daysAdditionComments,
                    guestAdditionComments: guestAdditionComments,
                    roomChangesComments: roomChangesComments,
                    roomChargesComments: roomChargesComments,
                    roomCancellationsComments: roomCancellationsComments,
                    roomCheckoutsComments: roomCheckoutsComments,
                    roomBlocksComments: roomBlocksComments,
                    roomUnblocksComments: roomUnblocksComments,
                    roomCleansComments: roomCleansComments,
                    prevRoomCleansComments: prevRoomCleansComments,
                    nextRoomCleansComments: nextRoomCleansComments,
                    endPendingReservationsComments: endPendingReservationsComments,
                    reservationTicketsComments: reservationTicketsComments,
                    reservationsComments: reservationsComments
                });
            }
        })
        .then(() => {
            this.setState({loadingComments: false});
        })
        .catch( errComments => {
            console.log('Error loading comments:', errComments);
            this.setState({loadingComments: false});
        });
    }

    goBackHandler = () => {
        this.props.history.goBack();
    }

    goToPrevSB = () => {
        this.loadData(this.state.prevSbId);
    }

    goToNextSB = () => {
        this.loadData(this.state.nextSbId);
    }

    async signShiftBalance(loadSbId) {
        this.setState({loading: true});

        API.post('benackoAPI', '/v2/reports/shift-balances/sign?hotelId=' + this.hotelId + '&type=' + this.sbType + '&startTimestamp=' + loadSbId, {
                body: {
                    signUser: this.state.currentUser
                },
                headers: {
                    Authorization: this.idToken
                }
            })
            .then(() => {
                this.setState({loading: false});
            })
            .catch( errSign => {
                console.log('Error signing shift balance:', errSign);
            });

        this.props.history.goBack();
    }

    render() {
        const signatureButton = (
            <Button
                type="danger" 
                style={{ width: '100%' }}
                onClick={ () => this.signShiftBalance(this.id) }//SUBSTITUTED
            >
                Firmar corte
            </Button>
        );

        const signatureInfo = (
            <div>
                <p><strong>Fecha de firma: </strong>{new Date(this.state.signTimestamp).toLocaleString()}</p>
                <p><strong>Usuario: </strong>{this.state.signUsername}</p>
            </div>
        );

        return(
            <Layout>
                <Header style={{ background: '#fff', padding: 10}}>
                    <Row>
                        <ButtonGroup>
                            <Button type="primary" onClick={this.goBackHandler} loading={this.state.loading}>
                                <LeftOutlined />Regresar
                            </Button>
                            <Button type="primary" onClick={() => this.loadComments(this.id)} loading={this.state.loading}>{/*SUBSITUTED*/}
                                <ReloadOutlined />Refrescar comentarios
                            </Button>
                            <Button type="primary" onClick={this.goToPrevSB} disabled={!this.state.prevSbId} loading={this.state.loading}>
                                Corte anterior
                            </Button>
                            <Button type="primary" onClick={this.goToNextSB} disabled={!this.state.nextSbId} loading={this.state.loading}>
                                Corte siguiente
                            </Button>
                        </ButtonGroup>
                    </Row>
                </Header>
                <Content style={{background: '#fff', padding: 10}}>
                    <Spin spinning={this.state.loading} size="large">
                        <Tabs
                            defaultActiveKey="1"
                            tabPosition="top"
                            type="card"
                        >
                            <TabPane tab="Resumen" key="1">
                                <Tabs>
                                    <TabPane tab="General" key="1">
                                        <Card bordered={false}>
                                            <p><strong>Fecha de apertura: </strong>{(this.state.sbInfo) && new Date(this.state.sbInfo.SK).toLocaleString()}</p>
                                            <p><strong>Usuario: </strong>{(this.state.sbInfo) && this.state.sbInfo.startUser}</p>
                                            <br />
                                            { this.state.isClosed &&
                                                <div>
                                                <p><strong>Fecha de cierre: </strong>{(this.state.sbInfo) && new Date(this.state.sbInfo.endTimestamp).toLocaleString()}</p>
                                                <p><strong>Usuario: </strong>{(this.state.sbInfo) && this.state.sbInfo.endUser}</p>
                                                <br />
                                                </div>
                                            }
                                            {this.state.isSigned && signatureInfo}
                                            {(!this.state.isSigned && this.state.isClosed) && signatureButton}
                                        </Card>

                                        <CommentsBox
                                            loading={this.state.loadingComments}
                                            section="general"
                                            hotelId={this.hotelId}
                                            type={this.sbType}
                                            startTimestamp={this.id}
                                            comments={this.state.generalComments}
                                            isClosed={!this.state.isClosed || this.state.isSigned}
                                        />
                                    </TabPane>
                                    <TabPane tab="Totales" key="2">
                                        <Table
                                            columns={incomeColumns} 
                                            dataSource={this.state.income} 
                                            size="small"
                                            bordered={false}
                                            locale={{ emptyText: 'No se encontraron totales' }}
                                            pagination={ false }
                                            style={{ marginBottom: 20 }}
                                        />

                                        {this.state.printRoomTypeTable}
                                    </TabPane>
                                    <TabPane tab="Vales" key="3">
                                        <Table
                                            columns={depositTicketsColumns} 
                                            dataSource={this.state.depositTickets} 
                                            size="small"
                                            bordered={false}
                                            locale={{ emptyText: 'No se encontraron vales' }}
                                            pagination={ false }
                                        />
                                    </TabPane>
                                    <TabPane tab="Acciones" key="4">
                                        <Table
                                            columns={actionsColumns} 
                                            dataSource={this.state.actions} 
                                            size="small"
                                            bordered={false}
                                            locale={{ emptyText: 'No se encontraron acciones' }}
                                            pagination={ false }
                                        />
                                    </TabPane>
                                    <TabPane tab="Tipo de cambio" key="5">
                                        <Table
                                            columns={exchangeRateColumns} 
                                            dataSource={this.state.exchangeRateHeaders} 
                                            size="small"
                                            bordered={false}
                                            locale={{ emptyText: 'No se encontró encabezado' }}
                                            pagination={{ hideOnSinglePage: true }}
                                        />
                                    </TabPane>
                                </Tabs>
                            </TabPane>
                            <TabPane tab="Estado inicial" key="2">
                                <Tabs>
                                    <TabPane tab="Habitaciones" key="1">
                                        <Table
                                            columns={roomsStatusEndColumms} 
                                            dataSource={this.state.initialRoomsStatusReport} 
                                            size="small"
                                            bordered={false}
                                            locale={{ emptyText: 'No se encontró reporte' }}
                                            pagination={{ hideOnSinglePage: true }}
                                        />

                                        <CommentsBox
                                            loading={this.state.loadingComments}
                                            section="initial_room_status"
                                            hotelId={this.hotelId}
                                            type={this.sbType}
                                            startTimestamp={this.id}
                                            comments={this.state.initialRoomStatusComments}
                                            isClosed={!this.state.isClosed || this.state.isSigned}
                                        />
                                    </TabPane>
                                    <TabPane tab="Habitaciones rentadas" key="2">
                                        <Table
                                            columns={rentedRoomsColumns} 
                                            dataSource={this.state.initialRentedRoomsStatusReport} 
                                            size="small"
                                            bordered={false}
                                            locale={{ emptyText: 'No se encontró reporte' }}
                                            pagination={{ hideOnSinglePage: true }}
                                        />

                                        <CommentsBox
                                            loading={this.state.loadingComments}
                                            section="initial_rented_room_status"
                                            hotelId={this.hotelId}
                                            type={this.sbType}
                                            startTimestamp={this.id}
                                            comments={this.state.initialRentedRoomStatusComments}
                                            isClosed={!this.state.isClosed || this.state.isSigned}
                                        />
                                    </TabPane>
                                </Tabs>
                            </TabPane>
                            <TabPane tab="Estado final" key="15">
                                <Tabs>
                                    <TabPane tab="Habitaciones" key="1">
                                        <Table
                                            columns={roomsStatusEndColumms} 
                                            dataSource={this.state.roomsStatusEndReport} 
                                            size="small"
                                            bordered={false}
                                            locale={{ emptyText: 'No se encontró reporte' }}
                                            pagination={{ hideOnSinglePage: true }}
                                        />

                                        <CommentsBox
                                            loading={this.state.loadingComments}
                                            section="room_end_status"
                                            hotelId={this.hotelId}
                                            type={this.sbType}
                                            startTimestamp={this.id}
                                            comments={this.state.roomEndStatusComments}
                                            isClosed={!this.state.isClosed || this.state.isSigned}
                                        />
                                    </TabPane>
                                    <TabPane tab="Bloqueadas" key="2">
                                        <Table
                                            columns={blockedStatusColumms} 
                                            dataSource={this.state.blockedStatusReport} 
                                            size="small"
                                            bordered={false}
                                            locale={{ emptyText: 'No se encontró reporte' }}
                                            pagination={{ hideOnSinglePage: true }}
                                        />

                                        <CommentsBox
                                            loading={this.state.loadingComments}
                                            section="room_blocked_status"
                                            hotelId={this.hotelId}
                                            type={this.sbType}
                                            startTimestamp={this.id}
                                            comments={this.state.roomBlockedStatusComments}
                                            isClosed={!this.state.isClosed || this.state.isSigned}
                                        />
                                    </TabPane>
                                    <TabPane tab="Habitaciones rentadas - turno actual" key="3">
                                        <Table
                                            columns={rentedRoomsColumns} 
                                            dataSource={this.state.rentedStatusReport} 
                                            size="small"
                                            bordered={false}
                                            locale={{ emptyText: 'No se encontró reporte' }}
                                            pagination={{ hideOnSinglePage: true }}
                                        />

                                        <CommentsBox
                                            loading={this.state.loadingComments}
                                            section="rented_room_status"
                                            hotelId={this.hotelId}
                                            type={this.sbType}
                                            startTimestamp={this.id}
                                            comments={this.state.rentedRoomsStatusComments}
                                            isClosed={!this.state.isClosed || this.state.isSigned}
                                        />
                                    </TabPane>
                                    <TabPane tab="Habitaciones rentadas - turno siguiente" key="4">
                                        <Table
                                            columns={rentedRoomsColumns} 
                                            dataSource={this.state.nextRentedRoomsStatusReport} 
                                            size="small"
                                            bordered={false}
                                            locale={{ emptyText: 'No se encontró reporte' }}
                                            pagination={{ hideOnSinglePage: true }}
                                        />

                                        <CommentsBox
                                            loading={this.state.loadingComments}
                                            section="next_rented_room_status"
                                            hotelId={this.hotelId}
                                            type={this.sbType}
                                            startTimestamp={this.id}
                                            comments={this.state.nextRentedRoomsStatusComments}
                                            isClosed={!this.state.isClosed || this.state.isSigned}
                                        />
                                    </TabPane>
                                </Tabs>
                            </TabPane>
                            <TabPane tab="Órdenes" key="3">
                                <Table
                                    columns={roomOrdersColumns} 
                                    dataSource={this.state.roomOrders} 
                                    size="small"
                                    bordered={false}
                                    locale={{ emptyText: 'No se encontraron órdenes' }}
                                    pagination={{
                                        pageSize: 10,
                                        showTotal: (total) => `Total: ${total} órdenes`
                                    }}
                                    style={{ marginBottom: 30 }}
                                />
                                <Table
                                    columns={roomOrderDetailsColumns} 
                                    dataSource={this.state.roomOrderDetails} 
                                    size="small"
                                    bordered={false}
                                    locale={{ emptyText: 'No se encontraron órdenes' }}
                                    pagination={{
                                        pageSize: 10,
                                        showTotal: (total) => `Total: ${total} productos`
                                    }}
                                />

                                <CommentsBox
                                    loading={this.state.loadingComments}
                                    section="room_orders"
                                    hotelId={this.hotelId}
                                    type={this.sbType}
                                    startTimestamp={this.id}
                                    comments={this.state.roomOrdersComments}
                                    isClosed={!this.state.isClosed || this.state.isSigned}
                                />
                            </TabPane>
                            <TabPane tab="Rentas" key="4">
                                <Table
                                    columns={roomRentColumns} 
                                    dataSource={this.state.roomRents} 
                                    size="small"
                                    bordered={false}
                                    locale={{ emptyText: 'No se encontraron rentas' }}
                                    pagination={{
                                        pageSize: 10,
                                        showTotal: (total) => `Total: ${total} rentas`
                                    }}
                                    style={{ marginBottom: 30 }}
                                />
                                <Table
                                    columns={roomRentDetailsColumns} 
                                    dataSource={this.state.roomRentDetails} 
                                    size="small"
                                    bordered={false}
                                    locale={{ emptyText: 'No se encontraron rentas' }}
                                    pagination={{
                                        pageSize: 10,
                                        showTotal: (total) => `Total: ${total} rentas`
                                    }}
                                />

                                <CommentsBox
                                    loading={this.state.loadingComments}
                                    section="room_rents"
                                    hotelId={this.hotelId}
                                    type={this.sbType}
                                    startTimestamp={this.id}
                                    comments={this.state.rentComments}
                                    isClosed={!this.state.isClosed || this.state.isSigned}
                                />
                            </TabPane>
                            <TabPane tab="Días adicionales" key="5">
                                <Table
                                    columns={roomDaysAdditionsColumns} 
                                    dataSource={this.state.roomDaysAdditions} 
                                    size="small"
                                    bordered={false}
                                    locale={{ emptyText: 'No se encontraron días adicionales' }}
                                    pagination={{
                                        pageSize: 10,
                                        showTotal: (total) => `Total: ${total} adicionales`
                                    }}
                                />

                                <CommentsBox
                                    loading={this.state.loadingComments}
                                    section="days_addition"
                                    hotelId={this.hotelId}
                                    type={this.sbType}
                                    startTimestamp={this.id}
                                    comments={this.state.daysAdditionComments}
                                    isClosed={!this.state.isClosed || this.state.isSigned}
                                />
                            </TabPane>
                            <TabPane tab="Personas agregadas" key="6">
                                <Table
                                    columns={roomGuestAdditionsColumns} 
                                    dataSource={this.state.roomGuestAdditions} 
                                    size="small"
                                    bordered={false}
                                    locale={{ emptyText: 'No se encontraron personas adicionales' }}
                                    pagination={{
                                        pageSize: 10,
                                        showTotal: (total) => `Total: ${total} adicionales`
                                    }}
                                />

                                <CommentsBox
                                    loading={this.state.loadingComments}
                                    section="guest_addition"
                                    hotelId={this.hotelId}
                                    type={this.sbType}
                                    startTimestamp={this.id}
                                    comments={this.state.guestAdditionComments}
                                    isClosed={!this.state.isClosed || this.state.isSigned}
                                />
                            </TabPane>
                            <TabPane tab="Cambios" key="7">
                                <Table
                                    columns={roomChangesColumns} 
                                    dataSource={this.state.roomChanges} 
                                    size="small"
                                    bordered={false}
                                    locale={{ emptyText: 'No se encontraron cambios' }}
                                    pagination={{
                                        pageSize: 10,
                                        showTotal: (total) => `Total: ${total} cambios`
                                    }}
                                />

                                <CommentsBox
                                    loading={this.state.loadingComments}
                                    section="room_changes"
                                    hotelId={this.hotelId}
                                    type={this.sbType}
                                    startTimestamp={this.id}
                                    comments={this.state.roomChangesComments}
                                    isClosed={!this.state.isClosed || this.state.isSigned}
                                />
                            </TabPane>
                            <TabPane tab="Cargos" key="8">
                                <Table
                                    columns={roomChargesColumns} 
                                    dataSource={this.state.roomCharges} 
                                    size="small"
                                    bordered={false}
                                    locale={{ emptyText: 'No se encontraron cargos' }}
                                    pagination={{
                                        pageSize: 10,
                                        showTotal: (total) => `Total: ${total} cargos`
                                    }}
                                />

                                <CommentsBox
                                    loading={this.state.loadingComments}
                                    section="room_charges"
                                    hotelId={this.hotelId}
                                    type={this.sbType}
                                    startTimestamp={this.id}
                                    comments={this.state.roomChargesComments}
                                    isClosed={!this.state.isClosed || this.state.isSigned}
                                />
                            </TabPane>
                            <TabPane tab="Salidas" key="9">
                                <Table
                                    columns={roomCheckoutsColumns} 
                                    dataSource={this.state.roomCheckouts} 
                                    size="small"
                                    bordered={false}
                                    locale={{ emptyText: 'No se encontraron salidas' }}
                                    pagination={{
                                        pageSize: 10,
                                        showTotal: (total) => `Total: ${total} salidas`
                                    }}
                                />

                                <CommentsBox
                                    loading={this.state.loadingComments}
                                    section="room_checkouts"
                                    hotelId={this.hotelId}
                                    type={this.sbType}
                                    startTimestamp={this.id}
                                    comments={this.state.roomCheckoutsComments}
                                    isClosed={!this.state.isClosed || this.state.isSigned}
                                />
                            </TabPane>
                            <TabPane tab="Bloqueadas" key="10">
                                <Table
                                    columns={roomBlocksColumns} 
                                    dataSource={this.state.roomBlocks} 
                                    size="small"
                                    bordered={false}
                                    locale={{ emptyText: 'No se encontraron bloqueos' }}
                                    pagination={{
                                        pageSize: 10,
                                        showTotal: (total) => `Total: ${total} bloqueadas`
                                    }}
                                />

                                <CommentsBox
                                    loading={this.state.loadingComments}
                                    section="room_blocks"
                                    hotelId={this.hotelId}
                                    type={this.sbType}
                                    startTimestamp={this.id}
                                    comments={this.state.roomBlocksComments}
                                    isClosed={!this.state.isClosed || this.state.isSigned}
                                />
                            </TabPane>
                            <TabPane tab="Desbloqueadas" key="11">
                                <Table
                                    columns={roomUnblocksColumns} 
                                    dataSource={this.state.roomUnblocks} 
                                    size="small"
                                    bordered={false}
                                    locale={{ emptyText: 'No se encontraron desbloqueos' }}
                                    pagination={{
                                        pageSize: 10,
                                        showTotal: (total) => `Total: ${total} desbloqueadas`
                                    }}
                                />

                                <CommentsBox
                                    loading={this.state.loadingComments}
                                    section="room_unblocks"
                                    hotelId={this.hotelId}
                                    type={this.sbType}
                                    startTimestamp={this.id}
                                    comments={this.state.roomUnblocksComments}
                                    isClosed={!this.state.isClosed || this.state.isSigned}
                                />
                            </TabPane>
                            <TabPane tab="Cancelaciones" key="12">
                                <Table
                                    columns={roomCancellationsColumns} 
                                    dataSource={this.state.roomCancellations} 
                                    size="small"
                                    bordered={false}
                                    locale={{ emptyText: 'No se encontraron cancelaciones' }}
                                    pagination={{
                                        pageSize: 10,
                                        showTotal: (total) => `Total: ${total} cancelaciones`
                                    }}
                                />

                                <CommentsBox
                                    loading={this.state.loadingComments}
                                    section="room_cancellations"
                                    hotelId={this.hotelId}
                                    type={this.sbType}
                                    startTimestamp={this.id}
                                    comments={this.state.roomCancellationsComments}
                                    isClosed={!this.state.isClosed || this.state.isSigned}
                                />
                            </TabPane>
                            <TabPane tab="Limpiezas" key="13">
                                <Tabs>
                                    <TabPane tab="Actual" key="1">
                                        <Table
                                            columns={roomCleansColumns} 
                                            dataSource={this.state.roomCleans} 
                                            size="small"
                                            bordered={false}
                                            locale={{ emptyText: 'No se encontraron limpiezas' }}
                                            pagination={{
                                                pageSize: 10,
                                                showTotal: (total) => `Total: ${total} limpiezas`
                                            }}
                                        />
                                        <br />
                                        <Table
                                            columns={maidCleansColumns} 
                                            dataSource={this.state.maidCleans} 
                                            size="small"
                                            bordered={false}
                                            locale={{ emptyText: 'No se encontraron recamareras' }}
                                            pagination={{ hideOnSinglePage: true }}
                                        />
                                        
                                        <CommentsBox
                                            loading={this.state.loadingComments}
                                            section="room_cleans"
                                            hotelId={this.hotelId}
                                            type={this.sbType}
                                            startTimestamp={this.id}
                                            comments={this.state.roomCleansComments}
                                            isClosed={!this.state.isClosed || this.state.isSigned}
                                        />
                                    </TabPane>
                                    <TabPane tab="Turno anterior" key="2">
                                        <Table
                                            columns={roomCleansColumns} 
                                            dataSource={this.state.prevRoomCleans} 
                                            size="small"
                                            bordered={false}
                                            locale={{ emptyText: 'No se encontraron limpiezas' }}
                                            pagination={{ hideOnSinglePage: true }}
                                        />
                                        <br />
                                        <Table
                                            columns={maidCleansColumns} 
                                            dataSource={this.state.prevMaidCleans} 
                                            size="small"
                                            bordered={false}
                                            locale={{ emptyText: 'No se encontraron recamareras' }}
                                            pagination={{ hideOnSinglePage: true }}
                                        />

                                        <CommentsBox
                                            loading={this.state.loadingComments}
                                            section="prev_room_cleans"
                                            hotelId={this.hotelId}
                                            type={this.sbType}
                                            startTimestamp={this.id}
                                            comments={this.state.prevRoomCleansComments}
                                            isClosed={!this.state.isClosed || this.state.isSigned}
                                        />
                                    </TabPane>
                                    <TabPane tab="Turno posterior" key="3">
                                        <Table
                                            columns={roomCleansColumns} 
                                            dataSource={this.state.nextRoomCleans} 
                                            size="small"
                                            bordered={false}
                                            locale={{ emptyText: 'No se encontraron limpiezas' }}
                                            pagination={{ hideOnSinglePage: true }}
                                        />
                                        <br />
                                        <Table
                                            columns={nextMaidCleansColumns} 
                                            dataSource={this.state.nextMaidCleans} 
                                            size="small"
                                            bordered={false}
                                            locale={{ emptyText: 'No se encontraron recamareras' }}
                                            pagination={{ hideOnSinglePage: true }}
                                        />

                                        <CommentsBox
                                            loading={this.state.loadingComments}
                                            section="next_room_cleans"
                                            hotelId={this.hotelId}
                                            type={this.sbType}
                                            startTimestamp={this.id}
                                            comments={this.state.nextRoomCleansComments}
                                            isClosed={!this.state.isClosed || this.state.isSigned}
                                        />
                                    </TabPane>
                                </Tabs>
                            </TabPane>
                            <TabPane tab="Reservaciones" key="14">
                                <Tabs>
                                    <TabPane tab="Estado" key="1">
                                        <Row style={{marginTop: 20}}>
                                            <strong>Total inicial de reservaciones pendientes: </strong>$ {this.state.startPendingReservationsTotal}
                                        </Row>
                                        <Row style={{marginBottom: 20}}>
                                            <strong>Total final de reservaciones pendientes: </strong>$ {this.state.endPendingReservationsTotal}
                                        </Row>
                                        <Table
                                            columns={pendingReservationsColumns} 
                                            dataSource={this.state.endPendingReservations} 
                                            size="small"
                                            bordered={false}
                                            locale={{ emptyText: 'No se encontraron reservaciones pendientes' }}
                                            pagination={{ hideOnSinglePage: true }}
                                        />
                                        
                                        <CommentsBox
                                            loading={this.state.loadingComments}
                                            section="end_pending_reservations"
                                            hotelId={this.hotelId}
                                            type={this.sbType}
                                            startTimestamp={this.id}
                                            comments={this.state.endPendingReservationsComments}
                                            isClosed={!this.state.isClosed || this.state.isSigned}
                                        />
                                    </TabPane>
                                    <TabPane tab="Hechas" key="2">
                                        <Table
                                            columns={reservationsColumns} 
                                            dataSource={this.state.reservations} 
                                            size="small"
                                            bordered={false}
                                            locale={{ emptyText: 'No se encontraron reservaciones' }}
                                            pagination={{ hideOnSinglePage: true }}
                                        />
                                        
                                        <CommentsBox
                                            loading={this.state.loadingComments}
                                            section="reservations"
                                            hotelId={this.hotelId}
                                            type={this.sbType}
                                            startTimestamp={this.id}
                                            comments={this.state.reservationsComments}
                                            isClosed={!this.state.isClosed || this.state.isSigned}
                                        />
                                    </TabPane>
                                    <TabPane tab="Reservaciones Utilizadas" key="3">
                                        <Table
                                            columns={usedReservatonsColumns} 
                                            dataSource={this.state.usedReservations} 
                                            size="small"
                                            bordered={false}
                                            locale={{ emptyText: 'No se encontraron reservaciones utilizadas' }}
                                            pagination={{ hideOnSinglePage: true }}
                                        />
                                    </TabPane>
                                    <TabPane tab="Vales" key="4">
                                        <Table
                                            columns={reservationTicketsColumns} 
                                            dataSource={this.state.reservationTickets} 
                                            size="small"
                                            bordered={false}
                                            locale={{ emptyText: 'No se encontraron vales de reservación' }}
                                            pagination={{ hideOnSinglePage: true }}
                                        />
                                        
                                        <CommentsBox
                                            loading={this.state.loadingComments}
                                            section="reservation_tickets"
                                            hotelId={this.hotelId}
                                            type={this.sbType}
                                            startTimestamp={this.id}
                                            comments={this.state.reservationTicketsComments}
                                            isClosed={!this.state.isClosed || this.state.isSigned}
                                        />
                                    </TabPane>
                                </Tabs>
                            </TabPane>
                        </Tabs>
                    </Spin>
                </Content>
            </Layout>
        );
    }
}

const mapStateToProps = state => {
    return {
        companyId: state.auth.companyId
    };
};


export default connect(mapStateToProps, null)(ShiftBalanceDetail);