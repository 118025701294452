import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { Layout, List } from 'antd'


const { Content } = Layout;

const data = [
    {
        title: 'Cortes',
        path: '/app/reports/shiftbalances',
        description: 'Reportes de turno',
        permission: 'p_reports_sb'
    }, {
        title: 'Resumen de cortes',
        path: '/app/reports/shiftbalancesummary',
        description: 'Encabezados de reportes de turno',
        permission: 'p_reports_sbs'
    }, {
        title: 'Estado de habitaciones',
        path: '/app/reports/roomstatesdashboard',
        description: 'Tablero con estado actual de habitaciones',
        permission: 'p_dashboard_rs'
    }, {
        title: 'Depósitos',
        path: '/app/reports/deposits',
        description: 'Lista de depósitos',
        permission: 'p_reports_dl'
    }, {
        title: 'Reporte diario - Habitaciones',
        path: '/app/reports/dailyrentreport',
        description: '',
        permission: 'p_reports_drr'
    }, {
        title: 'Reporte diario - Servicios',
        path: '/app/reports/dailyservicereport',
        description: '',
        permission: 'p_reports_dsr'
    }, {
        title: 'Reporte diario - Productos',
        path: '/app/reports/dailyproductsreport',
        description: '',
        permission: 'p_reports_dpr'
    }, {
        title: 'Reporte de eventos - Artículos',
        path: '/app/reports/itemactionsreport',
        description: '',
        permission: 'p_reports_iar'
    }, {
        title: 'Orden de surtido',
        path: '/app/reports/supplyorder',
        description: 'Lista de artículos con cantidad por debajo de la cantidad estándar',
        permission: 'p_reports_so'
    }, {
        title: 'Orden de compra',
        path: '/app/reports/purchaseorder',
        description: 'Lista de artículos con cantidad por debajo de la cantidad mínima',
        permission: 'p_reports_po'
    }, {
        title: 'Sincronización',
        path: '/app/reports/sync',
        description: 'Lista de eventos de sincronización',
        permission: 'p_reports_sync'
    }
];


class Reports extends Component {
    render() {
        return(
            <Layout>
                <Content style={{margin: 0, padding: 0, paddingLeft: 30, background: '#fff'}}>
                    <List
                        itemLayout="horizontal"
                        dataSource={data}
                        renderItem={item => (
                            (this.props.role[item.permission] === 0) ? <div /> : <NavLink to={item.path}>
                                <List.Item>
                                    <List.Item.Meta
                                    title={item.title}
                                    description={item.description}
                                    />
                                </List.Item>
                            </NavLink>
                        )}
                    />
                </Content>
            </Layout>
        );
    }
}

const mapStateToProps = state => {
    return {
        role: state.auth.role
    };
};


export default connect(mapStateToProps, null)(Reports);