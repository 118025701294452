import { Auth, API } from 'aws-amplify';

class RoleService {

    static async getRoles(cid, lvl = -1) {
        const idToken = await Auth.currentSession().then(data => {return data.idToken.jwtToken;});
        
        let url = '/roles?cid=' + cid;
        if (lvl != -1) {
            url += '&lvl=' + lvl;
        }

        return API.get('usersAPI', url, {headers: {Authorization: idToken}})
            .then(response => {
                if (response == null) {
                    throw Error(response ?? 'Respuesta no válida del servidor');
                } else {
                    return response;
                }
            })
            .catch(err => {throw Error(err ?? 'Respuesta no válida del servidor');});
    }
};

export default RoleService;
