import { Auth, API } from 'aws-amplify';

class HotelService {

    static async getHotels(cid) {
        const idToken = await Auth.currentSession().then(data => {return data.idToken.jwtToken;});
        
        return API.get('hotelsAPI', '/hotels?cid=' + cid, {headers: {Authorization: idToken}})
            .then(response => {
                if (response == null) {
                    throw Error(response ?? 'Invalid response from server');
                } else {
                    return response;
                }
            })
            .catch(err => {throw Error(err);});
    }

    static async getHotel(cid, hid) {
        const idToken = await Auth.currentSession().then(data => {return data.idToken.jwtToken;});
        
        return API.get('hotelsAPI', '/hotels/' + hid + '?cid=' + cid, {headers: {Authorization: idToken}})
            .then(response => {
                if (response == null) {
                    throw Error(response ?? 'Invalid response from server');
                } else {
                    return response;
                }
            })
            .catch(err => {throw Error(err);});
    }
};

export default HotelService;