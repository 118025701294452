import React, { Component } from 'react';
import { API } from 'aws-amplify';
import { Button, Form, Input, Layout, Row, Col, Spin } from 'antd';
import { LeftOutlined } from '@ant-design/icons';

const { Header, Content } = Layout;
const FormItem = Form.Item;
const ButtonGroup = Button.Group;

function hasErrors(fieldsError) {
    return Object.keys(fieldsError).some(field => fieldsError[field]);
}

class Edit extends Component {
    state = {
        loading: false
    }

    componentDidMount() {
        this.props.form.validateFields();
        const id = this.props.match.params.id;

        this.setState({loading: true});

        API.get('benackoAPI', '/room-permissions/' + id)
            .then( response => {
                const name = response.Item.name;

                this.props.form.setFieldsValue({
                    name: name
                });
                this.setState({loading: false});
            })
            .catch( error => {
                console.log(error);

                this.setState({loading: false});
            });
    }

    editPermissionHandler = (event) => {
        event.preventDefault();

        this.setState({loading: true});

        this.props.form.validateFields((err, values) => {
            if (!err) {
                const id = this.props.match.params.id;

                API.put('benackoAPI', '/room-permissions/' + id, {
                    body: {
                        name: values.name
                    }
                })
                .then(() => {
                    this.setState({loading: false});
                    this.props.history.goBack();
                })
                .catch(function (error) {
                    this.setState({loading: false});
                    console.log(error);
                });
            }
        });
    }

    goBackHandler = () => {
        this.props.history.goBack();
    }

    render() {
        const { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched } = this.props.form;

        // Only show error after a field is touched.
        const nameError = isFieldTouched('name') && getFieldError('name');

        return(
            <Layout>
                <Header style={{ background: '#fff', padding: 0 }}>
                    <Row>
                        <ButtonGroup>
                            <Button type="primary" onClick={this.goBackHandler}>
                                <LeftOutlined />Back
                            </Button>
                        </ButtonGroup>
                    </Row>
                </Header>
                <Content style={{ margin: 0, padding: 0, background: '#ffff' }}>
                    <Spin spinning={this.state.loading} size="large">
                        <Form onSubmit={this.editPermissionHandler} className="login-form">
                            <Row style={{marginTop: 50}}>
                                <Col span={20} offset={2}>
                                    <p>Please enter the new name of the room permission:</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={20} offset={2}>
                                    <FormItem
                                        validateStatus={nameError ? 'error' : ''}
                                        help={nameError || ''}
                                    >
                                        {getFieldDecorator('name', {
                                            rules: [{ required: true, message: 'Please input a room permission name' }]
                                        })(
                                            <Input prefix={<TableOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Name" />
                                        )}
                                    </FormItem>
                                </Col>
                            </Row>
                            <Row style={{marginTop: 20}}>
                                <Col span={4} offset={2}>
                                    <FormItem>
                                        <Button type="primary" 
                                            htmlType="submit" 
                                            disabled={hasErrors(getFieldsError())} className="login-form-button"
                                        >
                                            Edit
                                        </Button>
                                    </FormItem>
                                </Col>
                            </Row>
                        </Form>
                    </Spin>
                </Content>
            </Layout>
        );
    }
}

export default Edit;