import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { I18n } from 'aws-amplify';
import { Button, Col, Row } from 'antd';

import SectionTitle from '../../../components/UI/Panels/SectionTitle/SectionTitle';
import NumberInput from '../../../components/UI/Panels/NumberInput/NumberInput';
import UtilityUnits from '../../../components/UI/Panels/UtilityUnits/UtilityUnits';
import UtilitySelector from '../../../components/UI/Panels/UtilitySelector/UtilitySelector';

import { enableHotelSelector, disableHotelSelector, createUtilityEvent } from '../../../store/actions';


const CreateUtilityEvent = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const hid = useSelector(state => state.auth.selectedHotel);
    const [utility, setUtility] = useState(null);
    const [dataPoint, setDataPoint] = useState(null);

    const [allowCreation, setAllowCreation] = useState(true);
    const [creating, setCreating] = useState(false);


    useEffect(() => {
        dispatch(enableHotelSelector());

        return () => {
            dispatch(disableHotelSelector());
        };
    }, []);

    useEffect(() => {
        if (hid != null && utility != null && dataPoint != null) {
            setAllowCreation(false);
        } else {
            setAllowCreation(true);
        }
    }, [hid, utility, dataPoint]);

    const create = () => {
        setCreating(true);

        dispatch(createUtilityEvent(hid, utility, dataPoint))
            .then(() => {
                setCreating(false);
                history.goBack();
            })
            .catch(err => {
                console.log('Error creating utility event:', err);
                setCreating(false);
            });
    };

    return (
        <Row style={{display: 'flex', flexFlow: 'column', height: '100%'}}>
            <Row style={{width: '100%', height: '80px'}}>
                <Col offset={20} span={4} style={{display: 'flex', alignItems: 'center'}}>
                    <Button type="primary" onClick={() => history.goBack()}>Regresar</Button>
                </Col>
            </Row>
            <SectionTitle title={'Crear evento de servicio'} />
            <Row style={{display: 'flex', width: '100%', marginTop: 20}}>
                <UtilitySelector minLength={5} onChange={(e) => setUtility(e)}/>
            </Row>
            <Row style={{display: 'flex', width: '100%', marginTop: 20}}>
                <NumberInput minLength={5} label={'Dato'} onChange={(e) => setDataPoint(e)}/>
            </Row>
            <Row style={{display: 'flex', width: '100%', marginTop: 20}}>
                <UtilityUnits utility={utility} />
            </Row>
            <Row style={{display: 'flex', alignItems: 'end', width: '100%', height: '60px'}}>
                <Button type="primary" size="large" style={{marginLeft: 40}} onClick={create} disabled={allowCreation} loading={creating}>
                    Guardar
                </Button>
            </Row>
        </Row>
    );
}

export default CreateUtilityEvent;