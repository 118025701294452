import React, { Component } from 'react';
import { API, Auth } from 'aws-amplify';
import { Button, Layout, Row, Card } from 'antd';
import { LeftOutlined } from '@ant-design/icons';

import DisableModal from '../../../components/UI/Modals/DisableModal/DisableModal';

const { Header, Content } = Layout;
const ButtonGroup = Button.Group


class Detail extends Component {
    state = {
        loading: true,
        modalVisible: false,
        modalConfirmLoading: false,
        typeName: "",
        typeHotel: "",
        typeTemporary: false,
        typePrice: 0,
        typeBreakfastNumber: 0,
        typeCreateDate: null,
        typeLastEditDate: null,
        typeEnabled: false
    }

    idToken = '';

    loadData() {
        this.setState({loading: true});
        const id = this.props.match.params.id;

        API.get('benackoAPI', '/room-types/' + id, {
                headers: {
                    Authorization: this.idToken
                }
            })
            .then( async response => {
                const name = response[0].name;
                const hotel = await this.getHotelName(response[0].hotelId);
                const temporary = response[0].isTemporary;
                const price = response[0].price;
                const breakfastNumber = response[0].breakfastNumber;
                const createDate = new Date(response[0].created).toLocaleString();
                const lastEditDate = new Date(response[0].updated).toLocaleString();
                const enabled = response[0].enabled;

                this.setState({
                    loading: false,
                    typeName: name,
                    typeHotel: hotel,
                    typeTemporary: temporary,
                    typePrice: price,
                    typeBreakfastNumber: breakfastNumber,
                    typeCreateDate: createDate,
                    typeLastEditDate: lastEditDate,
                    typeEnabled: enabled
                });
            })
            .catch( errRoomType => {
                console.log('Error loading room type:', errRoomType);

                this.setState({ loading: false });
            });
    }

    async getHotelName(hotelId) {
        let hotel = await API.get('benackoAPI', '/hotels/' + hotelId, { headers: { Authorization: this.idToken } });
        let hotelName = hotel[0].name;

        return hotelName;
    }

    componentDidMount() {
        Auth.currentSession()
            .then( response => {
                this.idToken = response.idToken.jwtToken;

                this.loadData();
            })
            .catch( error => {
                console.log(error);
            });
    }

    goBackHandler = () => {
        this.props.history.goBack();
    }

    goEditHandler = () => {
        this.props.history.push('/app/rooms/types/details/' + this.props.match.params.id + '/edit')
    }

    goRoomTypeSchedules = () => {
        this.props.history.push('/app/rooms/types/details/' + this.props.match.params.id + '/schedules');
    }

    showDisableModal = () => {
        this.setState({modalVisible: true});
    }

    disableRoomType = () => {
        this.setState({modalConfirmLoading: true});
        const id = this.props.match.params.id;

        API.del('benackoAPI', '/room-types/' + id, {
                headers: {
                    Authorization: this.idToken
                }
            })
            .then( () => {
                this.setState({
                    modalVisible: false,
                    modalConfirmLoading: false,
                });

                this.props.history.goBack();
            })
            .catch( error => {
                console.log(error);

                this.setState({
                    modalVisible: false,
                    modalConfirmLoading: false,
                });
            });
    }

    closeDisableModal = () => {
        this.setState({modalVisible: false});
    }

    render() {
        return(
            <Layout>
                <Header style={{ background: '#fff', padding: 0 }}>
                    <Row>
                        <ButtonGroup>
                            <Button type="primary" onClick={this.goBackHandler}>
                                <LeftOutlined />Regresar
                            </Button>
                            <Button type="primary" onClick={this.goEditHandler} disabled={!this.state.typeEnabled}>
                                Editar
                            </Button>
                            <Button type="primary" onClick={this.goRoomTypeSchedules} disabled={!this.state.typeEnabled}>
                                Horarios
                            </Button>
                            <Button type="danger" onClick={this.showDisableModal} disabled={!this.state.typeEnabled}>
                                Deshabilitar
                            </Button>
                        </ButtonGroup>
                    </Row>
                </Header>
                <Content style={{ margin: 0, padding: 0, background: '#ffff', paddingTop: 15 }}>
                    <Card loading={this.state.loading} bordered={false} title={this.state.typeName}>
                        <p><strong>Precio:</strong> $ {this.state.typePrice}</p>
                        <p><strong>Temporal:</strong> {(this.state.typeTemporary) ? "Si" : "No"}</p>
                        <p><strong>Número de desayunos:</strong> {this.state.typeBreakfastNumber}</p>
                        <p><strong>Hotel:</strong> {this.state.typeHotel}</p>
                        <p><strong>Fecha de creación:</strong> {this.state.typeCreateDate}</p>
                        <p><strong>Última actualización:</strong> {this.state.typeLastEditDate}</p>
                    </Card>
                </Content>
                <DisableModal 
                    visible={this.state.modalVisible}
                    confirmLoading={this.state.modalConfirmLoading}
                    onCancel={this.closeDisableModal}
                    onOk={this.disableRoomType}
                    modalText="¿Deseas deshabilitar el tipo de habitación?"
                />
            </Layout>
        );
    }
}


export default Detail;