import React, { useEffect, useState } from 'react';
import { Row, Col } from 'antd';


const UtilityUnits = ({utility = ''}) => {
    const [label, setLabel] = useState('');

    useEffect(() => {
        switch (utility) {
            case 'Water':
                setLabel('m^3');
                break;
            case 'Electricity':
                setLabel('kW');
                break;
            case 'Gas':
                setLabel('%');
                break;
            default:
                break;
        }
    }, [utility]);


    return (
        <Row style={{width: '100%', height: '60px'}}>
            <Col span={5} style={{display: 'flex', alignItems: 'center', height: '100%'}}>
                <span style={{marginLeft: 40}}>Unidades</span>
            </Col>
            
            <Col span={19} style={{display: 'flex', alignItems: 'center', height: '100%'}}>
                <span style={{marginLeft: 20, width: '400px'}}>{label}</span>
            </Col>
        </Row>
    );
}

export default UtilityUnits;