import React, { Component } from 'react';
import { API } from 'aws-amplify';
import { Button, Form, Input, InputNumber, Layout, Row, Col, Spin, Switch } from 'antd';
import { LeftOutlined, TableOutlined } from '@ant-design/icons';

const { Header, Content } = Layout;
const ButtonGroup = Button.Group;


class Edit extends Component {
    state = {
        loading: false,
        temporary: false,
        name: '',
        price: 0.0,
        breakfastNumber: 0
    }


    loadData() {
        this.setState({loading: true});
        const id = this.props.match.params.id;

        API.get('benackoAPI', '/room-types/' + id)
            .then( response => {
                const isTemporary = response[0].isTemporary ? true : false;
                const name = response[0].name;
                const price = response[0].price;
                const breakfastNumber = response[0].breakfastNumber;

                this.setState({
                    temporary: isTemporary,
                    name: name,
                    price: price,
                    breakfastNumber: breakfastNumber,
                    loading: false
                });
            })
            .catch( error => {
                console.log(error);

                this.setState({loading: false});
            });
    }

    componentDidMount() {
        this.loadData();
    }

    editTypeHandler = () => {
        const id = this.props.match.params.id;

        const beautifiedName = this.state.name.trim().toLowerCase();

        API.put('benackoAPI', '/room-types/' + id, {
                body: {
                    name: beautifiedName,
                    price: this.state.price,
                    breakfastNumber: this.state.breakfastNumber,
                    isTemporary: this.state.temporary
                }
            })
            .then( () => {
                this.setState({loading: false});
                this.props.history.goBack();
            })
            .catch( error => {
                console.log(error);
                this.setState({loading: false});
            });
    }

    goBackHandler = () => {
        this.props.history.goBack();
    }

    render() {
        return(
            <Layout>
                <Header style={{width: '100%', background: '#fff', padding: 0, paddingLeft: 20}}>
                    <Row>
                        <ButtonGroup>
                            <Button type="primary" onClick={this.goBackHandler}>
                                <LeftOutlined />Regresar
                            </Button>
                        </ButtonGroup>
                    </Row>
                </Header>
                <Content style={{width: '100%', margin: 0, padding: 0, background: '#fff', paddingLeft: 20}}>
                    <Spin spinning={this.state.loading} size="large">
                        <Form onSubmit={this.editTypeHandler} className="login-form">
                            <Row style={{marginTop: 30}}>
                                <Col span={20} offset={2}>
                                    <p>Es temporal:</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={20} offset={2}>
                                    <Switch onChange={(e) => this.setState({temporary: e})} defaultChecked={this.state.temporary} />
                                </Col>
                            </Row>
                            <Row style={{marginTop: 10}}>
                                <Col span={20} offset={2}>
                                    <p>Nombre:</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={20} offset={2}>
                                    <Input defaultValue={this.state.name} onChange={(e) => this.setState({name: e.target.value})} prefix={<TableOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Nombre" />
                                </Col>
                            </Row>
                            <Row style={{marginTop: 10}}>
                                <Col span={20} offset={2}>
                                    <p>Precio:</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={20} offset={2}>
                                    <InputNumber style={{width: 150}} onChange={(e) => this.setState({price: e})} min={0} prefix={<TableOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Precio" />
                                </Col>
                            </Row>

                            <Row style={{marginTop: 10}}>
                                <Col span={20} offset={2}>
                                    <p>Número de desayunos:</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={20} offset={2}>
                                    <InputNumber style={{width: 150}} onChange={(e) => this.setState({breakfastNumber: e})} min={0} prefix={<TableOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Número de desayunos" />
                                </Col>
                            </Row>
                            <Row style={{marginTop: 20}}>
                                <Col span={4} offset={2}>
                                    <Button type="primary" 
                                        onClick={this.editTypeHandler}
                                        disabled={this.state.name == null || this.state.name.length <= 1 || this.state.price == null || this.state.price < 0.0 || this.state.breakfastNumber == null || this.state.breakfastNumber < 0} className="login-form-button"
                                    >
                                        Editar
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </Spin>
                </Content>
            </Layout>
        );
    }
}

export default Edit;