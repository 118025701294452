import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { Layout } from 'antd';

import TopBar from '../AppLayout/TopBar/TopBar';
import SideBar from './SideBar/SideBar';

import Users from '../../modules/Users/Users';

import './AppBase.css';


import Dashboard from '../../containers/Dashboard/Dashboard';

import Reports from '../../containers/Reports/Reports';
import ShiftBalances from '../../containers/Reports/ShiftBalances/ShiftBalances';
import ShiftBalanceDetail from '../../containers/Reports/ShiftBalances/ShiftBalanceDetail';
import ShiftBalanceSummary from '../../containers/Reports/ShiftBalanceSummary/ShiftBalanceSummary';
import ShiftBalanceSummaryDetails from '../../containers/Reports/ShiftBalanceSummary/ShiftBalanceSummaryDetails';
import DepositList from '../../containers/Reports/Deposits/DepositList';
import DailyRentReport from '../../containers/Reports/DailyRentReport/DailyRentReport';
import DailyServiceReport from '../../containers/Reports/DailyServiceReport/DailyServiceReport';
import CalculateDailyRentReport from '../../containers/Reports/DailyRentReport/Calculate/Calculate';
import ViewDailyRentReport from '../../containers/Reports/DailyRentReport/View/View';
import SupplyOrder from '../../containers/Reports/SupplyOrder/SupplyOrder';
import PurchaseOrder from '../../containers/Reports/PurchaseOrder/PurchaseOrder';
import DailyProductsReport from '../../containers/Reports/DailyProductsReport/DailyProductsReport';
import DailyProductsReportDetails from '../../containers/Reports/DailyProductsReport/DailyProductsReportDetails';
import ItemActionsReport from '../../containers/Reports/ItemActionsReport/ItemActionsReport';
import ItemActionsReportDetails from '../../containers/Reports/ItemActionsReport/ItemActionsReportDetails';
import RoomStatesDashboard from '../../containers/Reports/RoomStatesDashboard/RoomStatesDashboard';
import RoomStatesDashboardDetail from '../../containers/Reports/RoomStatesDashboard/RoomStatesDashboardDetail';

import Vouchers from '../../containers/Vouchers/Vouchers';
import Folios from '../../containers/Vouchers/Folios/Folios';
import FolioDetails from '../../containers/Vouchers/FolioDetails/FolioDetails';
import FolioEdit from '../../containers/Vouchers/FolioEdit/FolioEdit';
import CreditNotesGenerate from '../../containers/Vouchers/CreditNotesGenerate/CreditNotesGenerate';
import CreditNotesAdd from '../../containers/Vouchers/CreditNotesAdd/CreditNotesAdd';
import TicketsGenerate from '../../containers/Vouchers/TicketsGenerate/TicketsGenerate';
import TicketsAdd from '../../containers/Vouchers/TicketsAdd/TicketsAdd';
import VouchersView from '../../containers/Vouchers/View/View';
import VouchersViewDetails from '../../containers/Vouchers/VouchersViewDetails/VouchersViewDetails';
import TicketsEdit from '../../containers/Vouchers/TicketsEdit/TicketsEdit';
import TicketsEditDetails from '../../containers/Vouchers/TicketsEditDetails/TicketsEditDetails';
import VouchersSummary from '../../containers/Vouchers/VouchersSummary/VouchersSummary';
import VouchersSummaryDetails from '../../containers/Vouchers/VouchersSummaryDetails/VouchersSummaryDetails';

import Currencies from '../../containers/Currencies/Currencies';
import CurrencyDetails from '../../containers/Currencies/Detail/Detail';
import EditCurrency from '../../containers/Currencies/Edit/Edit';

import ItemLocations from '../../containers/ItemLocations/ItemLocations';
import CreateItemLocation from '../../containers/ItemLocations/Create/Create';
import ItemLocationDetails from '../../containers/ItemLocations/Detail/Detail';
import EditItemLocation from '../../containers/ItemLocations/Edit/Edit';

import Inventories from '../../containers/Inventories/Inventories';
import CreateInventory from '../../containers/Inventories/Create/Create';
import InventoryDetail from '../../containers/Inventories/Detail/Detail';
import EditInventory from '../../containers/Inventories/Edit/Edit';
import AddItemsToInventory from '../../containers/Inventories/AddItems/AddItems';
import InventoryItemDetail from '../../containers/Inventories/ItemDetail/ItemDetail';
import EditInventoryItem from '../../containers/Inventories/ItemEdit/ItemEdit';
import SupplyInventory from '../../containers/Inventories/Supply/Supply';
import ExtractItemsFromInventory from '../../containers/Inventories/TakeOut/TakeOut';
import DepleteItemsFromInventory from '../../containers/Inventories/Deplete/Deplete';
import AdjustItemsInInventory from '../../containers/Inventories/Adjust/Adjust';
import InventoryActions from '../../containers/Inventories/InventoryActions/InventoryActions';
import InventoryActionsDetails from '../../containers/Inventories/InventoryActions/InventoryActionsDetails';
import InventoryItemActions from '../../containers/Inventories/InventoryItemActions/InventoryItemActions';
import InventoryItemActionsDetails from '../../containers/Inventories/InventoryItemActions/InventoryActionsDetails';

import Products from '../../containers/Products/Products';
import CreateProduct from '../../containers/Products/Create/Create';
import ProductDetails from '../../containers/Products/Detail/Detail';
import EditProduct from '../../containers/Products/Edit/Edit';
import AddItem from '../../containers/Products/AddItem/AddItem';
import RemoveItem from '../../containers/Products/RemoveItem/RemoveItem';
import ProductPrices from '../../containers/Products/Prices/Prices';
import AddPrice from '../../containers/Products/AddPrice/AddPrice';

import Items from '../../containers/Items/Items';
import CreateItem from '../../containers/Items/Create/Create';
import ItemDetails from '../../containers/Items/Detail/Detail';
import EditItem from '../../containers/Items/Edit/Edit';

import Rooms from '../../containers/Rooms/Rooms';
import CreateRoom from '../../containers/Rooms/Create/Create';
import RoomDetails from '../../containers/Rooms/Detail/Detail';
import EditRoom from '../../containers/Rooms/Edit/Edit';

import RoomTypes from '../../containers/RoomTypes/RoomTypes';
import CreateRoomType from '../../containers/RoomTypes/Create/Create';
import RoomTypeDetails from '../../containers/RoomTypes/Detail/Detail';
import EditRoomType from '../../containers/RoomTypes/Edit/Edit';
import RoomTypeSchedule from '../../containers/RoomSchedules/RoomType/RoomType';
import CreateRoomTypeSchedule from '../../containers/RoomSchedules/RoomType/Create/Create';
import RoomTypeScheduleDetails from '../../containers/RoomSchedules/RoomType/Detail/Detail';

import RoomPermissions from '../../containers/RoomPermissions/RoomPermissions';
import CreateRoomPermission from '../../containers/RoomPermissions/Create/Create';
import RoomPermissionDetails from '../../containers/RoomPermissions/Detail/Detail';
import EditRoomPermission from '../../containers/RoomPermissions/Edit/Edit';

import RoomPromotions from '../../containers/RoomPromotions/RoomPromotions';
import CreateRoomPromotion from '../../containers/RoomPromotions/Create/Create';
import RoomPromotionDetails from '../../containers/RoomPromotions/Detail/Detail';
import EditRoomPromotion from '../../containers/RoomPromotions/Edit/Edit';

import HotelRoomSchedules from '../../containers/RoomSchedules/Hotel/Hotel';
import HotelRoomScheduleDetails from '../../containers/RoomSchedules/Hotel/Detail/Detail';

//Maintenance components
import Utilities from '../../containers/Maintenance/Utilities';
import UtilityDetails from '../../containers/Maintenance/UtilityDetails/UtilityDetails';
import CreateUtilityEvent from '../../containers/Maintenance/CreateUtilityEvent/CreateUtilityEvent';


const { Content } = Layout;


const AppBase = () => {

    return (
        <Layout style={{width: '100wh', minHeight: '100vh'}}>
            <SideBar/>
            <Layout className="site-layout" style={{marginLeft: 200}}>
                <TopBar />

                <Content className="site-layout-background" style={{width: '100%', height: '100%', padding: 20, overflow: 'initial'}}>
                    <Users />
                    <Switch>
                            <Route exact path="/app" component={Dashboard} />

                            <Route path="/app/reports"
                                render={({ match: { url } }) => (
                                    <div>
                                        <Route exact path={`${url}/`} component={Reports} />
                                        <Route exact path={`${url}/shiftbalances`} component={ShiftBalances} />
                                        <Route exact path={`${url}/shiftbalancesummary`} component={ShiftBalanceSummary} />
                                        <Route exact path={`${url}/shiftbalancesummary/:hotelid/:sbType/:startdate/:enddate`} component={ShiftBalanceSummaryDetails} />
                                        <Route exact path={`${url}/shiftbalances/:id/:hotelId/:sbType`} component={ShiftBalanceDetail} />
                                        <Route exact path={`${url}/deposits`} component={DepositList} />
                                        <Route exact path={`${url}/dailyrentreport`} component={DailyRentReport} />
                                        <Route exact path={`${url}/dailyrentreport/:hotel/:timestamp/calculate`} component={CalculateDailyRentReport} />
                                        <Route exact path={`${url}/dailyrentreport/:hotel/:timestamp/view`} component={ViewDailyRentReport} />
                                        <Route exact path={`${url}/dailyservicereport`} component={DailyServiceReport} />
                                        <Route exact path={`${url}/supplyorder`} component={SupplyOrder} />
                                        <Route exact path={`${url}/purchaseorder`} component={PurchaseOrder} />

                                        <Route exact path={`${url}/dailyproductsreport`} component={DailyProductsReport} />
                                        <Route exact path={`${url}/dailyproductsreport/:hotelid/:startdate/:enddate`} component={DailyProductsReportDetails} />

                                        <Route exact path={`${url}/itemactionsreport`} component={ItemActionsReport} />
                                        <Route exact path={`${url}/itemactionsreport/:hotelId/:startdate/:enddate`} component={ItemActionsReportDetails} />

                                        {/*<Route exact path={`${url}/sync`} component={Syncing} />
                                        <Route exact path={`${url}/sync/:id`} component={SyncDetails} />*/}

                                        <Route exact path={`${url}/roomstatesdashboard`} component={RoomStatesDashboard} />
                                        <Route exact path={`${url}/roomstatesdashboard/:id`} component={RoomStatesDashboardDetail} />
                                    </div>
                                )}
                            />

                            
                            <Route path="/app/payments/currencies"
                                render={({ match: { url } }) => (
                                    <div>
                                        <Route exact path={`${url}/`} component={Currencies} />
                                        <Route exact path={`${url}/details/:id`} component={CurrencyDetails} />
                                        <Route exact path={`${url}/details/:id/edit`} component={EditCurrency} />
                                    </div>
                                )}
                            />
                            <Route path="/app/vouchers"
                                render={({ match: { url } }) => (
                                    <div>
                                        <Route exact path={`${url}/`} component={Vouchers} />
                                        <Route exact path={`${url}/folios`} component={Folios} />
                                        <Route exact path={`${url}/folios/:id`} component={FolioDetails} />
                                        <Route exact path={`${url}/folios/:id/edit`} component={FolioEdit} />

                                        <Route exact path={`${url}/nt_generate`} component={CreditNotesGenerate} />
                                        <Route exact path={`${url}/nt_generate/:id`} component={CreditNotesAdd} />

                                        <Route exact path={`${url}/tk_generate`} component={TicketsGenerate} />
                                        <Route exact path={`${url}/tk_generate/:id`} component={TicketsAdd} />

                                        <Route exact path={`${url}/edit`} component={TicketsEdit} />
                                        <Route exact path={`${url}/edit/:hotelid/:date`} component={TicketsEditDetails} />

                                        <Route exact path={`${url}/view`} component={VouchersView} />
                                        <Route exact path={`${url}/view/:hotelid/:startdate/:enddate`} component={VouchersViewDetails} />

                                        <Route exact path={`${url}/summary`} component={VouchersSummary} />
                                        <Route exact path={`${url}/summary/:hotelid/:startdate/:enddate`} component={VouchersSummaryDetails} />
                                    </div>
                                )}
                            />
                            <Route path="/app/items/locations"
                                render={({ match: { url } }) => (
                                    <div>
                                        <Route exact path={`${url}/`} component={ItemLocations} />
                                        <Route exact path={`${url}/create`} component={CreateItemLocation} />
                                        <Route exact path={`${url}/details/:id`} component={ItemLocationDetails} />
                                        <Route exact path={`${url}/details/:id/edit`} component={EditItemLocation} />
                                    </div>
                                )}
                            />
                            <Route path="/app/products"
                                render={({ match: { url } }) => (
                                    <div>
                                        <Route exact path={`${url}/`} component={Products} />
                                        <Route exact path={`${url}/create`} component={CreateProduct} />
                                        <Route exact path={`${url}/details/:id`} component={ProductDetails} />
                                        <Route exact path={`${url}/details/:id/edit`} component={EditProduct} />
                                        <Route exact path={`${url}/details/:id/add`} component={AddItem} />
                                        <Route exact path={`${url}/details/:id/remove`} component={RemoveItem} />
                                        <Route exact path={`${url}/details/:id/prices`} component={ProductPrices} />
                                        <Route exact path={`${url}/details/:id/prices/add`} component={AddPrice} />
                                    </div>
                                )}
                            />
                            <Route path="/app/items"
                                render={({ match: { url } }) => (
                                    <div>
                                        <Route exact path={`${url}/`} component={Items} />
                                        <Route exact path={`${url}/create`} component={CreateItem} />
                                        <Route exact path={`${url}/details/:id`} component={ItemDetails} />
                                        <Route exact path={`${url}/details/:id/edit`} component={EditItem} />
                                    </div>
                                )}
                            />
                            <Route path="/app/inventories"
                                render={({ match: { url } }) => (
                                    <div>
                                        <Route exact path={`${url}/`} component={Inventories} />
                                        <Route exact path={`${url}/create`} component={CreateInventory} />
                                        <Route exact path={`${url}/details/:id`} component={InventoryDetail} />
                                        <Route exact path={`${url}/details/:id/add`} component={AddItemsToInventory} />
                                        <Route exact path={`${url}/details/:id/edit`} component={EditInventory} />
                                        <Route exact path={`${url}/details/:id/supply`} component={SupplyInventory} />
                                        <Route exact path={`${url}/details/:id/extract`} component={ExtractItemsFromInventory} />
                                        <Route exact path={`${url}/details/:id/deplete`} component={DepleteItemsFromInventory} />
                                        <Route exact path={`${url}/details/:id/adjust`} component={AdjustItemsInInventory} />
                                        <Route exact path={`${url}/details/items/:id`} component={InventoryItemDetail} />
                                        <Route exact path={`${url}/details/items/:id/actions`} component={InventoryItemActions} />
                                        <Route exact path={`${url}/details/items/:id/actions/:startdate/:enddate`} component={InventoryItemActionsDetails} />
                                        <Route exact path={`${url}/details/items/:id/edit`} component={EditInventoryItem} />
                                        <Route exact path={`${url}/details/:id/actions`} component={InventoryActions} />
                                        <Route exact path={`${url}/details/:id/actions/:startdate/:enddate`} component={InventoryActionsDetails} />
                                    </div>
                                )}
                            />
                            <Route path="/app/rooms/types"
                                render={({ match: { url } }) => (
                                    <div>
                                        <Route exact path={`${url}/`} component={RoomTypes} />
                                        <Route exact path={`${url}/create`} component={CreateRoomType} />
                                        <Route exact path={`${url}/details/:id`} component={RoomTypeDetails} />
                                        <Route exact path={`${url}/details/:id/edit`} component={EditRoomType} />
                                        <Route exact path={`${url}/details/:id/schedules`} component={RoomTypeSchedule} />
                                        <Route exact path={`${url}/details/:id/schedules/details/:id`} component={RoomTypeScheduleDetails} />
                                        <Route exact path={`${url}/details/:id/schedules/create/:type`} component={CreateRoomTypeSchedule} />
                                    </div>
                                )}
                            />
                            <Route path="/app/rooms/permissions"
                                render={({ match: { url } }) => (
                                    <div>
                                        <Route exact path={`${url}/`} component={RoomPermissions} />
                                        <Route exact path={`${url}/create`} component={CreateRoomPermission} />
                                        <Route exact path={`${url}/details/:id`} component={RoomPermissionDetails} />
                                        <Route exact path={`${url}/details/:id/edit`} component={EditRoomPermission} />
                                    </div>
                                )}
                            />
                            <Route path="/app/rooms/promotions"
                                render={({ match: { url } }) => (
                                    <div>
                                        <Route exact path={`${url}/`} component={RoomPromotions} />
                                        <Route exact path={`${url}/create`} component={CreateRoomPromotion} />
                                        <Route exact path={`${url}/details/:id`} component={RoomPromotionDetails} />
                                        <Route exact path={`${url}/details/:id/edit`} component={EditRoomPromotion} />
                                    </div>
                                )}
                            />
                            <Route path="/app/rooms"
                                render={({ match: { url } }) => (
                                    <div>
                                        <Route exact path={`${url}/`} component={Rooms} />
                                        <Route exact path={`${url}/create`} component={CreateRoom} />
                                        <Route exact path={`${url}/details/:id`} component={RoomDetails} />
                                        <Route exact path={`${url}/details/:id/edit`} component={EditRoom} />
                                    </div>
                                )}
                            />
                            <Route path="/app/settings/schedules"
                                render={({ match: { url } }) => (
                                    <div>
                                        <Route exact path={`${url}/`} component={HotelRoomSchedules} />
                                        <Route exact path={`${url}/details/:id`} component={HotelRoomScheduleDetails} />
                                        {/*<Route exact path={`${url}/details/:id/edit`} component={EditUserOp} />*/}
                                    </div>
                                )}
                            />
                            <Route path="/app/maintenance"
                                render={({ match: { url } }) => (
                                    <div>
                                        <Route exact path={`${url}/utilities`} component={Utilities} />
                                        <Route exact path={`${url}/utilities/details`} component={UtilityDetails} />
                                        <Route exact path={`${url}/utilities/create`} component={CreateUtilityEvent} />
                                    </div>
                                )}
                            />
                        </Switch>
                </Content>
            </Layout>
        </Layout>
    );
}

export default AppBase;
