import React, { Component } from 'react';
import { API, Auth } from 'aws-amplify';
import { Button, Layout, Row, Card, Table } from 'antd';
import { LeftOutlined } from '@ant-design/icons';

import EditCheckoutScheduleModal from '../../../../components/UI/Modals/EditCheckoutScheduleModal/EditCheckoutScheduleModal';
import EditHoursScheduleModal from '../../../../components/UI/Modals/EditHoursScheduleModal/EditHoursScheduleModal';

const { Header, Content } = Layout;
const ButtonGroup = Button.Group

const columns = [
    {
        title: 'Día',
        dataIndex: 'day',
        key: 'day'
    },
    {
        title: '',
        dataIndex: 'hour',
        key: 'hour'
    }
];


class Detail extends Component {
    state = {
        loading: false,
        scheduleHotel: null,
        scheduleName: null,
        scheduleType: null,
        scheduleCreated: null,
        scheduleEdit: null,
        scheduleData: []
    }

    idToken = '';

    loadData() {
        this.setState({loading: true});
        const id = this.props.match.params.id;

        API.get('benackoAPI', '/room-schedules/' + id, {
                headers: {
                    Authorization: this.idToken
                }
            })
            .then( async response => {
                const scheduleId = response[0].id;
                const scheduleType = response[0].scheduleType;
                const hotel = response[0].hotelId;
                const created = new Date(response[0].created).toLocaleString();
                const updated = new Date(response[0].updated).toLocaleString();

                var type;
                if (scheduleType === 1) {
                    type = 'Salidas';
                }

                if (scheduleType === 2) {
                    type = 'Horas';
                }

                var checkoutBlocks = await this.getScheduleDays(scheduleId, scheduleType);
                
                this.setState({
                    scheduleName: type,
                    scheduleType: scheduleType,
                    scheduleHotel: hotel,
                    scheduleCreated: created,
                    scheduleEdit: updated,
                    scheduleData: checkoutBlocks,
                    loading: false
                });
            })
            .catch( errRoomSchedules => {
                console.log('Error loading room schedule:', errRoomSchedules);

                this.setState({loading: false});
            });
    }

    async getScheduleDays(scheduleId, scheduleType) {
        let schedule = await API.get('benackoAPI', '/room-schedules/' + scheduleId + '/blocks?scheduleType=' + scheduleType, { headers: { Authorization: this.idToken } });
        var scheduleBlocks = [];

        schedule.forEach( block => {

            if (scheduleType === 1) {
                scheduleBlocks.push({
                    key: block.day,
                    day: this.getDayString(block.day),
                    hour: block.checkoutTime + ' hrs'
                });
            }

            if (scheduleType === 2) {
                scheduleBlocks.push({
                    key: block.day,
                    day: this.getDayString(block.day),
                    hour: block.hours + ' hrs'
                });
            }
        });

        return scheduleBlocks;
    }

    getDayString(dayId) {
        var dayString = '';
        
        switch(parseInt(dayId, 10)) {
            case 1:
                dayString = 'lunes';
                break;
            case 2:
                dayString = 'martes';
                break;
            case 3:
                dayString = 'miércoles';
                break;
            case 4:
                dayString = 'jueves';
                break;
            case 5:
                dayString = 'viernes';
                break;
            case 6:
                dayString = 'sábado';
                break;
            case 7:
                dayString = 'domingo';
                break;
            default:
                dayString = '?';
                break;
        }

        return dayString;
    }

    componentDidMount() {
        Auth.currentSession()
            .then( response => {
                this.idToken = response.idToken.jwtToken;

                this.loadData();
            })
            .catch( error => {
                console.log(error);
            });
    }

    reloadData = () => {
        this.loadData();
    }

    goBackHandler = () => {
        this.props.history.goBack();
    }

    render() {
        return(
            <Layout>
                <Header style={{ background: '#fff', padding: 0 }}>
                    <Row>
                        <ButtonGroup>
                            <Button type="primary" onClick={this.goBackHandler}>
                                <LeftOutlined />Regresar
                            </Button>
                            <Button type="primary" onClick={this.reloadData}>
                                Refrescar
                            </Button>
                        </ButtonGroup>
                    </Row>
                </Header>
                <Content style={{ margin: 0, padding: 0, background: '#ffff', paddingTop: 15 }}>
                    <Card loading={this.state.loading} bordered={false} title={this.state.scheduleName}>
                        <p><strong>Creado en:</strong> {this.state.scheduleCreated}</p>
                        <p><strong>Última actualización:</strong> {this.state.scheduleEdit}</p>
                    </Card>
                    <Table 
                        columns={columns} 
                        dataSource={this.state.scheduleData}
                        size="middle"
                        onRow={ (record) => {
                            return {
                                onClick: () => {
                                    const dayString = this.getDayString(record.key);

                                    if (this.state.scheduleType === 1) {
                                        this.checkoutEdit.showEditModal(record.key, dayString);
                                    }

                                    if (this.state.scheduleType === 2) {
                                        this.hoursEdit.showEditModal(record.key, dayString);
                                    }
                                }
                            };
                        }}
                        pagination={{
                            pageSize: 7,
                            showTotal: (total) => `Total ${total} días`
                        }}
                        locale={{
                            emptyText: 'No se encontraron datos'
                        }} 
                        loading={{
                            spinning: this.state.loading,
                            delay: 500,
                            size: 'large'
                        }}/>
                        <EditCheckoutScheduleModal ref={ instance => { this.checkoutEdit = instance } } scheduleId={this.props.match.params.id} scheduleType={this.state.scheduleType} />
                        <EditHoursScheduleModal ref={ instance => { this.hoursEdit = instance } } scheduleId={this.props.match.params.id} scheduleType={this.state.scheduleType} />
                </Content>
            </Layout>
        );
    }
}


export default Detail;