import React, { Component } from 'react';
import { API } from 'aws-amplify';
import { Button, Form, Input, Layout, Row, Col, Spin, Select } from 'antd';
import { LeftOutlined, TableOutlined } from '@ant-design/icons';

const { Header, Content } = Layout;
const ButtonGroup = Button.Group;
const Option = Select.Option;



class Edit extends Component {
    state = {
        loading: false,
        inventory: null,
        sourceOptions: [],
        selectedSource: 0,
        sourceName: '',
        name: ''
    }


    componentDidMount() {
        this.loadData();
    }

    loadData() {
        const id = this.props.match.params.id;

        API.get('benackoAPI', '/inventories/' + id, {
                headers: {
                    Authorization: this.idToken
                }
            })
            .then( inventoryRes => { console.log('inventoryRes:', inventoryRes);
                this.setState({
                    loading: false,
                    inventory: inventoryRes
                });

                this.setSourceInventoriesHandler(inventoryRes[0].hotelId, inventoryRes[0].id);

                this.setState({selectedSource: inventoryRes[0].name})
            })
            .catch( errInventory => {
                console.log('Error loading inventory: ', errInventory);

                this.setState({ loading: false });
            });
    }

    setSourceInventoriesHandler(hotelId, inventoryId) {
        API.get('benackoAPI', '/inventories?hotelId=' + hotelId, {
                headers: {
                    Authorization: this.idToken
                }
            })
            .then( response => {
                var options = [];

                response.forEach( inventory => {
                    if (inventory.id !== inventoryId) {
                        options.push(<Option key={inventory.id} >{inventory.name}</Option>);
                    }
                });

                this.setState({ sourceOptions: options, loading: false });
            })
            .catch( errInventories => {
                console.log('Error loading inventories: ', errInventories);

                this.setState({ loading: false });
            });
    }

    editInventoryHandler = (event) => {
        this.setState({loading: true});

        const id = this.props.match.params.id;

        const sourceInv = (this.state.selectedSource) ? this.state.selectedSource : 0;
        const beautifiedName = this.state.name.trim().toLowerCase();

        API.put('benackoAPI', '/inventories/' + id, {
                body: {
                    name: beautifiedName,
                    sourceInventoryId: sourceInv
                }
            })
            .then( () => {
                this.setState({loading: false});
                this.props.history.goBack();
            })
            .catch( error => {
                console.log(error);
                this.setState({loading: false});
            });
    }

    goBackHandler = () => {
        this.props.history.goBack();
    }

    render() {
        return(
            <Layout>
                <Header style={{ background: '#fff', padding: 0 }}>
                    <Row>
                        <ButtonGroup>
                            <Button type="primary" onClick={this.goBackHandler}>
                                <LeftOutlined />Regresar
                            </Button>
                        </ButtonGroup>
                    </Row>
                </Header>
                <Content style={{ margin: 0, padding: 0, background: '#ffff' }}>
                    <Spin spinning={this.state.loading} size="large">
                        <Form className="login-form">
                            <Row style={{marginTop: 10}}>
                                <Col span={20} offset={2}>
                                    <p>Inventario fuente:</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={20} offset={2}>
                                    <Select style={{width: '100%'}} onSelect={(e) => this.setState({selectedSource: e})}>
                                        {this.state.sourceOptions}
                                    </Select>
                                </Col>
                            </Row>
                            <Row style={{marginTop: 10}}>
                                <Col span={20} offset={2}>
                                    <p>Nombre:</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={20} offset={2}>
                                    <Input style={{width: '100%'}} onChange={(e) => this.setState({name: e.target.value})} prefix={<TableOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Name" />
                                </Col>
                            </Row>
                            <Row style={{marginTop: 10}}>
                                <Col span={4} offset={2}>
                                    <Button type="primary" 
                                        onClick={this.editInventoryHandler}
                                        disabled={this.state.name == null || this.state.name.length <= 1} className="login-form-button"
                                    >
                                        Editar
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </Spin>
                </Content>
            </Layout>
        );
    }
}

export default Edit;