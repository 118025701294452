import React, { Component } from 'react';
import { API, Auth } from 'aws-amplify';
import { Button, Layout, Row, Tabs, Card, Table } from 'antd';
import { LeftOutlined } from '@ant-design/icons';

import DisableModal from '../../../components/UI/Modals/DisableModal/DisableModal';
import PrintInventoryItemsList from '../../../components/UI/Modals/PrintInventoryItemsList/PrintInventoryItemsList';

const { Header, Content } = Layout;
const ButtonGroup = Button.Group
const TabPane = Tabs.TabPane;

const columns = [
    {
        title: 'Nombre',
        dataIndex: 'name',
        key: 'name'
    },
    {
        title: 'Cantidad',
        dataIndex: 'qty',
        key: 'qty'
    },
    {
        title: 'Cantidad estándar',
        dataIndex: 'stdQty',
        key: 'stdQty'
    },
    {
        title: 'Cantidad mínima',
        dataIndex: 'minQty',
        key: 'minQty'
    }
];

const actionsColumns = [{
    title: 'Fecha',
    dataIndex: 'timestamp'
}, {
    title: 'Acción',
    dataIndex: 'action'
}, {
    title: 'Usuario',
    dataIndex: 'user'
}, {
    title: 'Artículo',
    dataIndex: 'item'
}, {
    title: 'Cantidad',
    dataIndex: 'quantity'
}, {
    title: '',
    dataIndex: 'description'
}];


class Detail extends Component {
    state = {
        loading: true,
        loadingItemsTable: true,
        loadingActionsTable: true,
        modalVisible: false,
        modalConfirmLoading: false,
        inventoryName: "",
        inventoryHotel: "",
        sourceInventory: "",
        inventoryCreateDate: null,
        inventoryEnabled: false,
        articles: [],
        actions: [],
        printItems: false
    }

    loadData() {
        this.setState({loading: true});
        const id = this.props.match.params.id;

        API.get('benackoAPI', '/inventories/' + id, {
                headers: {
                    Authorization: this.idToken
                }
            })
            .then( async response => {
                const name = response[0].name;
                const source = await this.getInventoryName(response[0].sourceInventoryId);
                const hotel = await this.getHotelName(response[0].hotelId);
                const createDate = new Date(response[0].created).toLocaleString();
                const enabled = response[0].enabled;

                this.setState({
                    loading: false,
                    inventoryName: name,
                    sourceInventory: source,
                    inventoryHotel: hotel,
                    inventoryCreateDate: createDate,
                    inventoryEnabled: enabled
                });
            })
            .catch( errInventories => {
                console.log('Error loading inventories: ', errInventories);

                this.setState({ loading: false });
            });

            API.get('benackoAPI', '/v2/inventory-items?inventoryId=' + id, {
                    headers: {
                        Authorization: this.idToken
                    }
                })
                .then( itemsRes => {
                    var items = [];

                    // TRY PUTTING ALL STEPS INTO PROMISES, AND THEN SAVING THE STATE
                    itemsRes.forEach( async item => {
                        items.push({
                            key: item.id,
                            name: item.name,
                            qty: item.quantity,
                            stdQty: item.standardQuantity,
                            minQty: item.minimumQuantity
                        });
                    });

                    this.setState({articles: items, loadingItemsTable: false});
                })
                .catch( errInventoryItems => {
                    console.log('Error loading inventory items: ', errInventoryItems);
                    this.setState({ loading: false });
                });

            const today = new Date();
            const endTimestamp = this.getUpperDateLimit(today);
            var dayDecrement = today;
            dayDecrement.setDate(dayDecrement.getDate() - 7);
            const startTimestamp = this.getUpperDateLimit(dayDecrement);

            API.get('benackoAPI', '/v2/inventory-actions?inventoryId=' + id + '&startTimestamp=' + startTimestamp + '&endTimestamp=' + endTimestamp, {
                    headers: {
                        Authorization: this.idToken
                    }
                })
                .then(inventoryActionsRes => {
                    var actions = [];

                    inventoryActionsRes.Items.forEach(action => {
                        actions.push({
                            key: action.SK,
                            timestamp: new Date(action.SK).toLocaleString(),
                            action: action.action,
                            user: action.user,
                            item: action.item.name,
                            quantity: action.quantity,
                            description: action.description
                        });
                    });

                    this.setState({actions: actions, loadingActionsTable: false});
                })
                .catch(errInventoryActions => {
                    console.log('Error loading inventory actions:', errInventoryActions);
                    this.setState({loadingActionsTable: false});
                });
    }

    getUpperDateLimit(date) {
        var tomorrow = date;
        tomorrow.setHours(23);
        tomorrow.setMinutes(59);
        tomorrow.setSeconds(59);
        tomorrow.setMilliseconds(999);
        
        return tomorrow.getTime();
    }

    async getHotelName(hotelId) {
        let hotel = await API.get('benackoAPI', '/hotels/' + hotelId, { headers: { Authorization: this.idToken } });
        let hotelName = hotel[0].name;

        return hotelName;
    }

    async getInventoryName(inventoryId) {
        if (inventoryId === 0) {
            return 'Ninguno';
        } else {
            let inventory = await API.get('benackoAPI', '/inventories/' + inventoryId, { headers: { Authorization: this.idToken } });
            let inventoryName = inventory[0].name;

            return inventoryName;
        }
    }

    async getItemName(itemId) {
        let item = await API.get('benackoAPI', '/items/' + itemId, { headers: { Authorization: this.idToken } });
        let itemName = item[0].name;

        return itemName;
    }

    async getActionLabel(actionId) {
        let action = await API.get('benackoAPI', '/base-actions/' + actionId, { headers: { Authorization: this.idToken } });
        let actionName = action[0].name;

        return actionName;
    }

    async getInventoryItemItemName(inventoryItemId) {
        let itemName = await API.get('benackoAPI', '/inventory-items/' + inventoryItemId, 
            { 
                headers: { 
                    Authorization: this.idToken 
                } 
            })
            .then( async invItem => {
                const name = await this.getItemName(invItem[0].id);

                return name;
            })
            .catch( error => {
                console.log('Error loading inventory item name:', error);
            });

        return itemName;
    }

    async getUserName(userId) {
        let user = await API.get('benackoAPI', '/users/details/' + userId, { headers: { Authorization: this.idToken } });
        let userName = user[0].username;

        return userName;
    }

    componentDidMount() {
        Auth.currentSession()
            .then( response => {
                this.idToken = response.idToken.jwtToken;

                this.loadData();
            })
            .catch( error => {
                console.log(error);
            });
    }

    goBackHandler = () => {
        this.props.history.goBack();
    }

    goEditHandler = () => {
        this.props.history.push('/app/inventories/details/' + this.props.match.params.id + '/edit')
    }

    goSupplyHandler = () => {
        this.props.history.push('/app/inventories/details/' + this.props.match.params.id + '/supply')
    }

    goAddItemsHandler = () => {
        this.props.history.push('/app/inventories/details/' + this.props.match.params.id + '/add')
    }

    gotTakeOutItemsHandler = () => {
        this.props.history.push('/app/inventories/details/' + this.props.match.params.id + '/extract')
    }

    goDepleteItemsHandler = () => {
        this.props.history.push('/app/inventories/details/' + this.props.match.params.id + '/deplete')
    }

    goToActionsDateRangeSelection = () => {
        this.props.history.push('/app/inventories/details/' + this.props.match.params.id + '/actions')
    }

    goAdjustItemsHandler = () => {
        this.props.history.push('/app/inventories/details/' + this.props.match.params.id + '/adjust')
    }

    // ADD DEPLETION FUNCTION

    showDisableModal = () => {
        this.setState({modalVisible: true});
    }

    disableInventory = () => {
        this.setState({modalConfirmLoading: true});

        const id = this.props.match.params.id;

        API.del('benackoAPI', '/inventories/' + id, {
                headers: {
                    Authorization: this.idToken
                }
            })
            .then( () => {
                this.setState({
                    modalVisible: false,
                    modalConfirmLoading: false,
                });

                this.props.history.goBack();
            })
            .catch( error => {
                console.log(error);

                this.setState({
                    modalVisible: false,
                    modalConfirmLoading: false,
                });
            });
    }

    closeDisableModal = () => {
        this.setState({modalVisible: false});
    }

    printItemsHandler = () => {
        this.setState({
            printItems: true
        });
    }

    closePrintItems = () => {
        this.setState({
            printItems: false
        });
    }

    render() {
        const takeOutButton = (
            <Button type="default" onClick={this.gotTakeOutItemsHandler} disabled={!this.state.inventoryEnabled}>Sacar</Button>
        );

        const depletionButton = (
            <Button type="default" onClick={this.goDepleteItemsHandler} disabled={!this.state.inventoryEnabled}>Mermar</Button>
        );

        const itemTable = (
            <Table 
                columns={columns} 
                dataSource={this.state.articles}
                onRow={ (record) => {
                    return {
                        onClick: () => {
                            this.props.history.push('/app/inventories/details/items/' + record.key);
                        }
                    };
                }}
                size="small" 
                pagination={false}
                locale={{
                    emptyText: 'No se encontraron artículos'
                }} 
                loading={{
                    spinning: this.state.loadingItemsTable,
                    delay: 500,
                    size: 'large'
                }}
            />
        );

        const actionsTable = (
            <Table
                columns={actionsColumns}
                dataSource={this.state.actions}
                size="small" 
                pagination={{
                    pageSize: 16
                }}
                locale={{
                    emptyText: 'No se encontraron acciones'
                }} 
                loading={{
                    spinning: this.state.loadingActionsTable,
                    delay: 500,
                    size: 'large'
                }}
            />
        );

        return(
            <Layout>
                <Header style={{ background: '#fff', padding: 0 }}>
                    <Row>
                        <ButtonGroup>
                            <Button type="primary" onClick={this.goBackHandler}>
                                <LeftOutlined />Regresar
                            </Button>
                            <Button type="primary" onClick={this.goSupplyHandler} disabled={!this.state.inventoryEnabled}>
                                Surtir
                            </Button>
                            <Button type="primary" onClick={this.goAddItemsHandler} disabled={!this.state.inventoryEnabled}>
                                Agregar artículos
                            </Button>
                            <Button type="primary" onClick={this.printItemsHandler} disabled={!this.state.inventoryEnabled}>
                                Imprimir artículos
                            </Button>

                            { this.state.sourceInventory === 'Ninguno' && takeOutButton }
                            { this.state.sourceInventory !== 'Ninguno' && depletionButton }

                            <Button type="danger" onClick={this.goAdjustItemsHandler} disabled={!this.state.inventoryEnabled}>
                                Ajustar
                            </Button>
                            <Button type="primary" onClick={this.goToActionsDateRangeSelection} disabled={!this.state.inventoryEnabled}>
                                Acciones por rango fecha
                            </Button>
                        </ButtonGroup>
                    </Row>
                </Header>
                <Content style={{ margin: 0, padding: 0, background: '#ffff', paddingTop: 15 }}>
                    <Tabs defaultActiveKey="1" tabPosition="right">
                        <TabPane tab="General" key="1">
                            <Card loading={this.state.loading} bordered={false} title={this.state.inventoryName}>
                                <p><strong>Inventario fuente:</strong> {this.state.sourceInventory}</p>
                                <p><strong>Hotel:</strong> {this.state.inventoryHotel}</p>
                                <p><strong>Creado:</strong> {this.state.inventoryCreateDate}</p>
                            </Card>
                        </TabPane>
                        <TabPane key="2" tab="Artículos">
                            {itemTable}
                        </TabPane>
                        <TabPane key="3" tab="Acciones">
                            {actionsTable}
                        </TabPane>
                    </Tabs>
                </Content>
                <DisableModal 
                    visible={this.state.modalVisible}
                    confirmLoading={this.state.modalConfirmLoading}
                    onCancel={this.closeDisableModal}
                    onOk={this.disableInventory}
                    modalText="¿Deseas deshabilitar el inventario?"
                />

                <PrintInventoryItemsList
                    visible={this.state.printItems}
                    onCancel={this.closePrintItems}
                    itemTable={itemTable}
                />
            </Layout>
        );
    }
}


export default Detail;