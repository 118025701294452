import React, { Component } from 'react';
import { connect } from 'react-redux';
import { API } from 'aws-amplify';
import { Button, Form, Input, InputNumber, Layout, Row, Col, Spin, Select, Switch } from 'antd';
import { LeftOutlined, TableOutlined } from '@ant-design/icons';

const { Header, Content } = Layout;
const ButtonGroup = Button.Group;
const Option = Select.Option;


class Create extends Component {
    state = {
        loading: false,
        hotelsOptions: [],
        selectedHotel: 0,
        temporary: false,
        name: '',
        price: 0.0,
        breakfastNumber: 0
    }

    loadData() {
        this.setState({loading: true});
        this.setHotelsMenu();
        this.setState({loading: false});
    }

    setHotelsMenu() {
        const enabledHotels = this.props.hotels;
        var hotels = [];

        for (var i in enabledHotels) {
            hotels.push(<Option key={i}>{enabledHotels[i]}</Option>);
        }

        this.setState({hotelsOptions: hotels});
    }

    componentDidMount() {
        this.loadData();
    }

    createTypeHandler = () => {
        this.setState({loading: true});

        const beautifiedName = this.state.name.trim().toLowerCase();

        API.post('benackoAPI', '/room-types', {
            body: {
                name: beautifiedName,
                price: this.state.price,
                breakfastNumber: this.state.breakfastNumber,
                hotelId: this.state.selectedHotel,
                isTemporary: this.state.temporary
            },
            headers: {
                Authorization: this.idToken
            }
        })
        .then( () => {
            this.setState({loading: false});
            this.props.history.goBack();
        })
        .catch( error => {
            console.log(error);
            this.setState({loading: false});
        });
    }

    goBackHandler = () => {
        this.props.history.goBack();
    }

    render() {
        return(
            <Layout>
                <Header style={{width: '100%', background: '#fff', padding: 0, paddingLeft: 20}}>
                    <Row>
                        <ButtonGroup>
                            <Button type="primary" onClick={this.goBackHandler}>
                                <LeftOutlined />Regresar
                            </Button>
                        </ButtonGroup>
                    </Row>
                </Header>
                <Content style={{width: '100%', margin: 0, padding: 0, background: '#fff', paddingLeft: 20 }}>
                    <Spin spinning={this.state.loading} size="large">
                        <Form className="login-form">
                            <Row style={{marginTop: 30}}>
                                <Col span={20} offset={2}>
                                    <p>Hotel:</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={20} offset={2}>
                                    <Select onSelect={(e) => this.setState({selectedHotel: e})} style={{width: '100%'}}>
                                        {this.state.hotelsOptions}
                                    </Select>
                                </Col>
                            </Row>
                            <Row style={{marginTop: 10}}>
                                <Col span={20} offset={2}>
                                    <p>Es temporal:</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={20} offset={2}>
                                    <Switch onChange={(e) => this.setState({temporary: e})} defaultChecked={!true} />
                                </Col>
                            </Row>
                            <Row style={{marginTop: 10}}>
                                <Col span={20} offset={2}>
                                    <p>Nombre:</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={20} offset={2}>
                                    <Input onChange={(e) => this.setState({name: e.target.value})} prefix={<TableOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Nombre" />
                                </Col>
                            </Row>
                            <Row style={{marginTop: 10}}>
                                <Col span={20} offset={2}>
                                    <p>Precio:</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={20} offset={2}>
                                    <InputNumber style={{width: 150}} onChange={(e) => this.setState({price: e})} min={0} prefix={<TableOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Precio" />
                                </Col>
                            </Row>

                            <Row style={{marginTop: 10}}>
                                <Col span={20} offset={2}>
                                    <p>Número de desayunos:</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={20} offset={2}>
                                    <InputNumber style={{width: 150}} onChange={(e) => this.setState({breakfastNumber: e})} min={0} prefix={<TableOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Desayunos" />
                                </Col>
                            </Row>
                            <Row style={{marginTop: 10}}>
                                <Col span={4} offset={2}>
                                    <Button type="primary" 
                                        onClick={this.createTypeHandler}
                                        disabled={this.state.selectedHotel === 0 || this.state.name == null || this.state.name.length <= 1 || this.state.price == null} className="login-form-button"
                                    >
                                        Crear
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </Spin>
                </Content>
            </Layout>
        );
    }
}

const mapStateToProps = state => {
    return {
        hotels: state.auth.hotels
    };
};


export default connect(mapStateToProps, null)(Create);