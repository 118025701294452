import React, { useState, useEffect } from 'react';
import { Row, Col, Spin } from 'antd';
import { ZoomInOutlined, PlusOutlined } from '@ant-design/icons';
import { GiWaterDrop, GiFuelTank, GiElectric } from 'react-icons/gi';

import Title from 'antd/lib/typography/Title';

import './UtilityPanel.css';


const UtilityPanel = ({loading = true, utility = '', data = '', unit = '', timestamp = '', onClick = () => console.log('No function has been set')}) => {
    const [icon, setIcon] = useState(null);
    const [title, setTitle] = useState('');
    const [beautifiedTimestamp, setBeautifiedTimestamp] = useState('');

    useEffect(() => {
        if (utility === 'Agua') {
            setIcon(<GiWaterDrop color={'black'} size={28} />);
        } else if (utility === 'Gas') {
            setIcon(<GiFuelTank color={'black'} size={28} />);
        } else if (utility === 'Electricidad') {
            setIcon(<GiElectric color={'black'} size={28} />);
        }

        setTitle(utility);
        setBeautifiedTimestamp(timestamp?.slice(0, 19).replace('T', ' ') ?? '');
    }, [utility, timestamp]);

    return (
        <Row style={{width: '100%', height: '90px', border: '1px solid lightgray', borderRadius: 3, marginBottom: 20}}>
            <Col span={2} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                {icon && icon}
            </Col>
            <Col offset={1} span={3} style={{display: 'flex', alignItems: 'center', height: '100%'}}>
                <Title level={3} style={{margin: 0}}>{title}</Title>
            </Col>
            <Col offset={1} span={13}>
                <Row style={{width: '100%', height: '100%'}}>
                    <Col span={24}>
                        <Spin spinning={loading}>
                            <Row style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end', width: '100%', height: '60px'}}>
                                <Title level={3} style={{margin: 0, marginRight: 40}}>{data} {unit}</Title>
                            </Row>
                            <Row style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end', width: '100%', height: '30px'}}>
                                <span style={{fontSize: 12, marginRight: 40}}>{beautifiedTimestamp}</span>
                            </Row>
                        </Spin>
                    </Col>
                </Row>
            </Col>
            <Col className="utility-panel-row" span={4} onClick={onClick}>
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%'}}><ZoomInOutlined style={{fontSize: 24}} /></div>
            </Col>
            {/*<Col className="utility-panel-row" span={3}>
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%'}}><PlusOutlined style={{fontSize: 24}} /></div>
            </Col>*/}
        </Row>
    );
}

export default UtilityPanel;