import React, { Component } from 'react';
import { API, Auth } from 'aws-amplify';
import { Button, Layout, Row, Col, Spin, Select } from 'antd';
import { LeftOutlined } from '@ant-design/icons';

const { Header, Content } = Layout;
const ButtonGroup = Button.Group;
const Option = Select.Option;


class Edit extends Component {
    state = {
        loading: false,
        roomTypeOptions: [],
        allRoomTypes: [],
        roomType: null
    }

    idToken = '';


    loadData() {
        this.setState({loading: true});
        const id = this.props.match.params.id;

        API.get('benackoAPI', '/rooms/' + id, {
                headers: {
                    Authorization: this.idToken
                }
            })
            .then( response => {
                const hotelId = response[0].hotelId;

                API.get('benackoAPI', '/room-types?hotelId=' + hotelId, {
                        headers: {
                            Authorization: this.idToken
                        }
                    })
                    .then(response => {
                        var options = [];
                        var allTypes = [];
        
                        response.forEach( type => {
                            allTypes.push(type.id);
                            options.push(<Option key={type.id} value={type.id}>{type.name}</Option>);
                        });
        
                        this.setState({roomTypeOptions: options, allRoomTypes: allTypes, loading: false});
                    })
                    .catch(errRoomTypes => {
                        console.log('Error loading room types:', errRoomTypes);
                        this.setState({ loading: false });
                    });
            })
            .catch( error => {
                console.log(error);
                this.setState({loading: false});
            });
    }

    componentDidMount() {
        Auth.currentSession()
            .then( response => {
                this.idToken = response.idToken.jwtToken;

                this.loadData();
            })
            .catch( error => {
                console.log(error);
            });
    }

    editRoomHandler = async (event) => {
        event.preventDefault();

        this.setState({loading: true});
        const id = this.props.match.params.id;
        const allRoomTypes = this.state.allRoomTypes;
        const roomType = this.state.roomType;

        allRoomTypes.forEach(async type => {
            const roomId = this.props.match.params.id;
            const rentTypeId = type;
            const isAllowed = (roomType.indexOf(type) === -1) ? false : true;

            const payload = {
                rentTypeId: rentTypeId,
                isAllowed: isAllowed
            };

            await API.post('benackoAPI', '/v2/rooms/configurations/' + roomId, {
                    body: payload,
                    headers: {
                        Authorization: this.idToken
                    }
                })
                .catch( error => {
                    console.log(error);
                    this.setState({loading: false});
                });
        });

        setTimeout(() => {
            this.setState({loading: false});
            this.props.history.goBack();
        }, 3000);
    }

    goBackHandler = () => {
        this.props.history.goBack();
    }

    setRoomType = (rt) => {
        this.setState({roomType: rt});
    }

    render() {
        return(
            <Layout>
                <Header style={{ background: '#fff', padding: 0 }}>
                    <Row>
                        <ButtonGroup>
                            <Button type="primary" onClick={this.goBackHandler}>
                                <LeftOutlined />Regresar
                            </Button>
                        </ButtonGroup>
                    </Row>
                </Header>
                <Content style={{ margin: 0, padding: 0, background: '#ffff' }}>
                    <Spin spinning={this.state.loading} size="large">
                        <Row style={{marginTop: 30}}>
                            <Col span={20} offset={2}>
                                <p>Selecciona los tipos de renta habilitados para la habitación:</p>
                            </Col>
                        </Row>
                            <Row>
                                <Col span={20} offset={2}>
                                    <Select mode="multiple" onChange={this.setRoomType} style={{ width: '100%' }}>
                                        {this.state.roomTypeOptions}
                                    </Select>
                                </Col>
                            </Row>
                            <Row style={{marginTop: 20}}>
                                <Col span={4} offset={2}>
                                    <Button type="primary" disabled={(this.state.roomType == null || this.state.roomType.length <= 0)} onClick={this.editRoomHandler}>
                                        Habilitar tipos de renta
                                    </Button>
                                </Col>
                            </Row>
                    </Spin>
                </Content>
            </Layout>
        );
    }
}

export default Edit;