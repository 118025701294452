import React, { Component } from 'react';
import { connect } from 'react-redux';
import { API } from 'aws-amplify';
import { Button, Form, Input, Layout, Row, Col, Spin, Select } from 'antd';
import { LeftOutlined, TableOutlined } from '@ant-design/icons';

const { Header, Content } = Layout;
const ButtonGroup = Button.Group;
const Option = Select.Option;


class Create extends Component {
    state = {
        loading: false,
        hotelsOptions: [],
        selectedHotel: null,
        locationName: ''
    }

    loadData() {
        this.setState({loading: true});
        this.setHotelsMenu();
        this.setState({loading: false});
    }

    componentDidMount() {
        this.loadData();
    }

    setHotelsMenu() {
        const enabledHotels = this.props.hotels;
        var hotels = [];

        for (var i in enabledHotels) {
            hotels.push(<Option key={i}>{enabledHotels[i]}</Option>);
        }

        this.setState({hotelsOptions: hotels});
    }

    createTypeHandler = () => {
        this.setState({loading: true});

        const beautifiedName = this.state.locationName.trim().toLowerCase();

        API.post('benackoAPI', '/product-locations', {
            body: {
                name: beautifiedName,
                hotelId: this.state.selectedHotel
            }
        })
            .then( () => {
                this.setState({loading: false});
                this.props.history.goBack();
            })
            .catch( error => {
                console.log(error);
                this.setState({loading: false});
            });
    }

    goBackHandler = () => {
        this.props.history.goBack();
    }

    render() {
        return(
            <Layout>
                <Header style={{width: '100%', background: '#fff', padding: 0, paddingLeft: 20}}>
                    <Row>
                        <ButtonGroup>
                            <Button type="primary" onClick={this.goBackHandler}>
                                <LeftOutlined />Regresar
                            </Button>
                        </ButtonGroup>
                    </Row>
                </Header>
                <Content style={{width: '100%', margin: 0, padding: 0, background: '#fff', paddingLeft: 20}}>
                    <Spin spinning={this.state.loading} size="large">
                        <Form className="login-form">
                            <Row style={{marginTop: 30}}>
                                <Col span={20} offset={2}>
                                    <p>Hotel:</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={20} offset={2}>
                                    <Select style={{width: '100%'}} onSelect={(e) => this.setState({selectedHotel: e})}>
                                        {this.state.hotelsOptions}
                                    </Select>
                                </Col>
                            </Row>
                            
                            <Row style={{marginTop: 10}}>
                                <Col span={20} offset={2}>
                                    <p>Nombre:</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={20} offset={2}>
                                    <Input style={{width: '100%'}} onChange={(e) => this.setState({locationName: e.target.value})} prefix={<TableOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Nombre" />
                                </Col>
                            </Row>
                            <Row style={{marginTop: 10}}>
                                <Col span={4} offset={2}>
                                    <Button type="primary" 
                                        onClick={this.createTypeHandler}
                                        disabled={this.state.locationName == null || this.state.locationName.length <= 2 || this.state.selectedHotel == null || this.state.selectedHotel === 0} className="login-form-button"
                                    >
                                        Crear
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </Spin>
                </Content>
            </Layout>
        );
    }
}

const mapStateToProps = state => {
    return {
        hotels: state.auth.hotels
    };
};


export default connect(mapStateToProps, null)(Create);