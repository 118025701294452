import React, { Component } from 'react';
import { connect } from 'react-redux';
import { API, Auth } from 'aws-amplify';
import { Layout, Row, Col, Spin, Card, Button, InputNumber, Input, Modal, Radio, Switch, Alert, Tag } from 'antd';

import PrintAllTickets from '../../../components/UI/Modals/PrintAllTickets/PrintAllTickets';

const { Content } = Layout;
const ButtonGroup = Button.Group;


class TicketsAdd extends Component {
    state = {
        loading: false,
        awsPk: '',
        awsSk: '',
        vouchersHotel: '',
        vouchersDate: '',
        tkCanBeAdded: false,
        tkId: 0,
        tkHeader: '',
        tkFolio: 0,
        tkrId: 0,
        tkrHeader: '',
        tkrFolio: 0,
        tickets: [],
        ticketsContents: [],
        folioKeys: 0,
        ticketsTotalAmount: 0,
        ticketsHaveBeenAdded: false,
        hotel: '',
        visiblePrintAllTickets: false,
        printableTickets: [],
        processedTicks: []
    }

    idToken = '';


    componentDidMount() {
        Auth.currentSession()
            .then( response => {
                this.idToken = response.idToken.jwtToken;

                this.loadData();
            })
            .catch( error => {
                console.log(error);
            });
    }

    loadData() {
        this.setState({loading: true});

        const queryId = this.props.match.params.id;

        const companyId = this.props.companyId;
        const hotelId = queryId.split('h').pop()[0];
        const yyyymmdd = queryId.split('d').pop();
        const type = 'tk';

        const queryString = 'companyId=' + companyId + '&hotelId=' + hotelId + '&timestamp=' + yyyymmdd + '&type=' + type;

        API.get('benackoAPI', '/vouchers?' + queryString, {
                headers: {
                    Authorization: this.idToken
                }
            })
            .then( vouchersRes => {
                const year = yyyymmdd.substring(0, 4);
                const month = yyyymmdd.substring(4, 6);
                const day = yyyymmdd.substring(6, 8);

                const vouchersDate = new Date(year, (month - 1), day).toLocaleDateString();
                const vouchersHotel = this.props.hotels[hotelId];

                var tkCanBeAdded = false;
                if (vouchersRes.Items[0].vouchers.length <= 0) {
                    tkCanBeAdded = true;
                }

                const awsPk = vouchersRes.Items[0].PK;
                const awsSk = vouchersRes.Items[0].SK;

                this.setState({
                    loading: false,
                    awsPk: awsPk,
                    awsSk: awsSk,
                    tkCanBeAdded: tkCanBeAdded,
                    vouchersHotel: vouchersHotel,
                    vouchersDate: vouchersDate
                });
            })
            .then( () => {
                API.get('benackoAPI', '/system/tickets?hotelId=' + hotelId, {
                        headers: {
                            Authorization: this.idToken
                        }
                    })
                    .then( foliosRes => {
                        const activeFolio = foliosRes.find( fr => fr.type === 'frontDeskRentMG' );
                        const tkId = activeFolio.id;
                        const tkHeader = activeFolio.folio_header;
                        const tkFolio = activeFolio.folio_number;

                        const activeResFolio = foliosRes.find( fr => fr.type === 'frontDeskReservationMG' );
                        const tkrId = activeResFolio.id;
                        const tkrHeader = activeResFolio.folio_header;
                        const tkrFolio = activeResFolio.folio_number;

                        this.setState({
                            tkId: tkId,
                            tkHeader: tkHeader,
                            tkFolio: tkFolio,
                            tkrId: tkrId,
                            tkrHeader: tkrHeader,
                            tkrFolio: tkrFolio
                        });
                    })
                    .catch( errFolios => {
                        console.log('Error loading folios:', errFolios);
                    });
            })
            .then( () => {
                var firstTicket = [];
                firstTicket.push(
                    <Row key={0} style={{width: '100%', marginTop: 50}}>
                        <Row style={{width: '100%'}}>
                            <Col span={6}>
                                <span>Comprobante {1}</span>
                            </Col>
                            <Col span={4}>
                                <strong>Cantidad: </strong>
                                <InputNumber min={1} defaultValue={1} step={1} precision={0} onChange={(value) => this.updateTicketQuantity(value, 0)} />
                            </Col>
                            <Col offset={2} span={8}>
                                <strong>Tipo:</strong>
                                <Radio.Group defaultValue={1} onChange={(value) => this.updateTicketType(value, 0)}>
                                    <Radio value={1}>Normal</Radio>
                                    <Radio value={2}>Reservación</Radio>
                                </Radio.Group>
                            </Col>
                            <Col span={4}>
                                <strong>Facturado: </strong>
                                <Switch onChange={(value) => this.updateInvoiced(value, 0)} />
                            </Col>
                        </Row>
                        <Row style={{width: '100%', marginTop: 15}}>
                            <Col offset={6} span={9}>
                                <strong>Concepto: </strong>
                                <Input defaultValue="habitación" style={{ width: '50%' }} onChange={(value) => this.updateTicketConcept(value, 0)} />
                            </Col>
                            <Col offset={1} span={4}>
                                <strong>Precio unitario: </strong>
                                <InputNumber min={0} defaultValue={0} onChange={(value) => this.updateTicketPrice(value, 0)} />
                            </Col>
                        </Row>
                    </Row>
                );

                var firstTicketContents = [];
                firstTicketContents.push({
                    key: 0,
                    type: 1,
                    invoiced: false,
                    quantity: 1,
                    concept: 'habitación',
                    price: 0
                });

                this.setState({
                    tickets: firstTicket,
                    ticketsContents: firstTicketContents
                });
            })
            .catch( errVouchers => {
                console.log('Error loading vouchers:', errVouchers);

                this.setState({loading: false});
            });

        API.get('benackoAPI', '/hotels/' + hotelId, {
                headers: {
                    Authorization: this.idToken
                }
            })
            .then(hotelRes => {
                const hotelDetails = hotelRes[0];

                const hotel = {
                    legalName: hotelDetails.legalName,
                    address: hotelDetails.address,
                    rfc: hotelDetails.rfc,
                    city: hotelDetails.city,
                    state: hotelDetails.state,
                    country: hotelDetails.country,
                    zipCode: hotelDetails.zipCode,
                    phoneNumber: hotelDetails.phoneNumber
                };

                this.setState({hotel: hotel, loading: false});
            })
            .catch(errHotel => {
                console.log('Error loading hotel:', errHotel);
            });//FOR LIST
    }

    addTicket = () => {
        var folioCounter = this.state.folioKeys;
        folioCounter += 1;

        var tickets = this.state.tickets;
        tickets.push(
            <Row key={folioCounter} style={{width: '100%', marginTop: 30}}>
                <hr />
                <Row style={{width: '100%'}}>
                    <Col span={6}>
                        <span>Comprobante {folioCounter + 1}</span>
                    </Col>
                    <Col span={4}>
                        <strong>Cantidad: </strong>
                        <InputNumber min={1} defaultValue={1} step={1} precision={0} onChange={(value) => this.updateTicketQuantity(value, folioCounter)} />
                    </Col>
                    <Col offset={2} span={8}>
                        <strong>Tipo:</strong>
                        <Radio.Group defaultValue={1} onChange={(value) => this.updateTicketType(value, folioCounter)}>
                            <Radio value={1}>Normal</Radio>
                            <Radio value={2}>Reservación</Radio>
                        </Radio.Group>
                    </Col>
                    <Col span={4}>
                        <strong>Facturado: </strong>
                        <Switch onChange={(value) => this.updateInvoiced(value, folioCounter)} />
                    </Col>
                </Row>
                <Row style={{width: '100%', marginTop: 15}}>
                    <Col offset={6} span={9}>
                        <strong>Concepto: </strong>
                        <Input defaultValue="habitación" style={{ width: '50%' }} onChange={(value) => this.updateTicketConcept(value, folioCounter)} />
                    </Col>
                    <Col offset={1} span={6}>
                        <strong>Precio unitario: </strong>
                        <InputNumber min={0} defaultValue={0} onChange={(value) => this.updateTicketPrice(value, folioCounter)} />
                    </Col>
                </Row>
            </Row>
        );

        var ticketsContents = this.state.ticketsContents;
        ticketsContents.push({
            key: folioCounter,
            type: 1,
            invoiced: false,
            quantity: 1,
            concept: 'habitación',
            price: 0
        });

        this.setState({
            folioKeys: folioCounter,
            tickets: tickets,
            ticketsContents: ticketsContents
        });
    }

    updateTicketType = (value, key) => {
        var ticketsContents = this.state.ticketsContents;

        ticketsContents.find( tk => tk.key === key).type = value.target.value;

        this.setState({ticketsContents: ticketsContents});
    }

    updateInvoiced = (value, key) => {
        var ticketsContents = this.state.ticketsContents;
        
        ticketsContents.find( tk => tk.key === key).invoiced = value;

        this.setState({ticketsContents: ticketsContents});
    }

    updateTicketQuantity = (value, key) => {
        var ticketsContents = this.state.ticketsContents;

        ticketsContents.find( tk => tk.key === key).quantity = value;

        //this.setState({ticketsContents: ticketsContents});
        this.setState({ticketsContents: ticketsContents}, () => {
            this.calculateTicketsTotalAmount();
        });
    }

    updateTicketConcept = (value, key) => {
        var ticketsContents = this.state.ticketsContents;
        const beautifiedValue = value.target.value.toLowerCase().trim();

        ticketsContents.find( tk => tk.key === key).concept = beautifiedValue;

        this.setState({ticketsContents: ticketsContents});
    }

    updateTicketPrice = (value, key) => {
        var ticketsContents = this.state.ticketsContents;

        ticketsContents.find( tk => tk.key === key).price = value;

        this.setState({ticketsContents: ticketsContents}, () => {
            this.calculateTicketsTotalAmount();
        });
    }

    calculateTicketsTotalAmount() {
        var totalAmount = 0;
        this.state.ticketsContents.forEach(ticket => {
            totalAmount += (parseInt(ticket.quantity, 10) * parseInt(ticket.price, 10));
        });

        this.setState({ticketsTotalAmount: totalAmount});
    }

    showErrorModal(noteKey) {
        var addedKey = noteKey + 1;
        Modal.error({
            title: 'Error en comprobante ' + addedKey,
            content: 'No puede haber campos vacíos en los comprobantes'
        });
    }

    viewAllPrintableTickets = () => {
        const tickets = this.state.printableTickets;
        var preProcessedTicks = [];

        tickets.forEach(voucher => {
            preProcessedTicks.push(
                <div key={voucher.folio} style={{ marginBottom: 30 }}>
                    {this.state.hotel.legalName}
                    <br />
                    RFC: {this.state.hotel.rfc}
                    <br />
                    {this.state.hotel.address}
                    <br />
                    {this.state.hotel.city}, {this.state.hotel.state}, {this.state.hotel.country}
                    <br />
                    C.P.: {this.state.hotel.zipCode}
                    <br />
                    Tel: {this.state.hotel.phoneNumber}
                    <hr />
                    {
                        (voucher.type === 'nt') &&
                            <p>NOTA DE CRÉDITO</p>
                    }
                    Fecha: {voucher.date}
                    <br />
                    Cantidad: {
                        voucher.quantity !== 0 &&
                            voucher.quantity
                    }
                    <br />Concepto: {voucher.concept}
                    <br />
                    Monto: ${ 
                        voucher.price !== 0 &&
                            voucher.price
                    }
                    <br />
                    Total: ${voucher.quantity * voucher.price}
                    <hr />
                    Folio {voucher.folio}
                    <br />
                    COMPROBANTE SIMPLIFICADO NO DEDUCIBLE PARA EFECTOS FISCALES
                    < br/><br />
                    ORIGINAL
                    <br /><br />
                    {
                        (voucher.inv === true) &&
                            <p>FACTURADO</p>
                    }
                </div>
            );
        });

        this.setState({
            visiblePrintAllTickets: true,
            processedTicks: preProcessedTicks
        });
    }

    closeAllPrintableTickets = () => {
        this.setState({
            visiblePrintAllTickets: false,
            processedTicks: []
        });
    }

    generateTicketsHandler = () => {
        const tickets = this.state.ticketsContents;
        const awsPk = this.state.awsPk;
        const awsSk = this.state.awsSk;
        const tkHeader = this.state.tkHeader;
        var tkFolio = this.state.tkFolio;
        const tkrHeader = this.state.tkrHeader;
        var tkrFolio = this.state.tkrFolio;

        var validatedTickets = true;

        tickets.forEach( note => {
            if (note.quantity <= 0 || note.quantity === null || note.concept.length <= 0 || note.concept === null || note.price < 0 || note.price === null) {
                validatedTickets = false;
                this.showErrorModal(note.key);
            }
        });

        if (validatedTickets) {
            this.setState({loading: true});
            var preTickets = [];

            tickets.forEach( note => {
                if (note.type === 1) {
                    const newFolio = tkHeader + tkFolio;
                    note['folio'] = newFolio;
                    note['edited'] = false;
                    tkFolio += 1;
                } else {
                    const newFolio = tkrHeader + tkrFolio;
                    note['folio'] = newFolio;
                    note['edited'] = false;
                    tkrFolio += 1;
                }

                preTickets.push({
                    key: note.folio,
                    date: '' + this.state.vouchersDate,
                    folio: note.folio,
                    quantity: note.quantity,
                    concept: note.concept,
                    price: note.price,
                    invoiced: (note.invoiced) ? <Tag color="geekblue">Facturado</Tag> : '',
                    edited: (note.edited) ? <Tag color="red">Editado</Tag> : '',
                    inv: (note.invoiced !== true) ? false : true,
                    type: 'tk'
                });//FOR LIST
            } );

            API.put('benackoAPI', '/vouchers', {
                    body: {
                        pk: awsPk,
                        sk: awsSk,
                        vouchers: tickets
                    },
                    headers: {
                        Authorization: this.idToken
                    }
                })
                .then( () => {
                    API.put( 'benackoAPI', '/system/tickets/' + this.state.tkId, {
                            body: {
                                folioNumber: tkFolio
                            },
                            headers: {
                                Authorization: this.idToken
                            }
                        } )
                        .catch( errFolios => {
                            console.log('Error increasing folios', errFolios);

                            this.setState({loading: false});
                        } );

                    API.put( 'benackoAPI', '/system/tickets/' + this.state.tkrId, {
                            body: {
                                folioNumber: tkrFolio
                            },
                            headers: {
                                Authorization: this.idToken
                            }
                        } )
                        .catch( errFoliosR => {
                            console.log('Error increasing r folios', errFoliosR);

                            this.setState({loading: false});
                        } );
                } )
                .then( () => {
                    this.setState({loading: false, printableTickets: preTickets, ticketsHaveBeenAdded: true});
                    //this.props.history.push('/app/vouchers');
                } )
                .catch( errNotes => {
                    console.log('Error adding tickets:', errNotes);

                    this.setState({loading: false});
                } );
        }
    }

    render() {
        return(
            <Row style={{width: '100%'}}>
                <Content style={{width: '100%', margin: 0, padding: 0, background: '#fff', paddingLeft: 20}}>
                    <Spin spinning={this.state.loading} size="large">
                        <Card loading={false} bordered={false} title="Comprobantes">
                            <p><strong>Hotel: </strong>{this.state.vouchersHotel}</p>
                            <p><strong>Fecha: </strong>{this.state.vouchersDate}</p>
                        </Card>  
                        <Col span={24}>
                            <Row style={{width: '100%'}}>
                                <ButtonGroup>
                                    <Button type="default" disabled={this.state.ticketsHaveBeenAdded} onClick={this.addTicket}>Agregar otro comprobante</Button>
                                    <Button type="danger" disabled={this.state.ticketsHaveBeenAdded} onClick={this.generateTicketsHandler}>Generar comprobantes</Button>
                                    <Button type="primary" disabled={!this.state.ticketsHaveBeenAdded} onClick={this.viewAllPrintableTickets}>Imprimir comprobantes</Button>
                                </ButtonGroup>
                            </Row>
                            <Row>
                                <Alert
                                    type="info" 
                                    message={'Cantidad total: $' + this.state.ticketsTotalAmount + ' en ' + this.state.tickets.length + ((this.state.tickets.length === 1) ? ' comprobante' : ' comprobantes')}
                                    style={{ marginTop: 30, marginBottom: 30 }}
                                    showIcon
                                />
                            </Row>
                            <Row style={{width: '100%'}}>
                                {this.state.tickets}
                            </Row>
                        </Col>

                        <PrintAllTickets
                            visible={this.state.visiblePrintAllTickets}
                            onCancel={this.closeAllPrintableTickets}
                            hotel={this.state.hotel}
                            processedTicks={this.state.processedTicks}
                        />
                    </Spin>
                </Content>
            </Row>
        );
    }
}

const mapStateToProps = state => {
    return {
        companyId: state.auth.companyId,
        hotels: state.auth.hotels
    };
};


export default connect(mapStateToProps, null)(TicketsAdd);