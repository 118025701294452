import React, { Component } from 'react';
import { API } from 'aws-amplify';
import { connect } from 'react-redux';
import { Button, Col, Layout, Row, Select, Steps, Spin, Table } from 'antd';

const { Content } = Layout;
const ButtonGroup = Button.Group;
const Option = Select.Option;
const Step = Steps.Step;

const columns = [
    {
        title: 'Nombre',
        dataIndex: 'name',
        key: 'name'
    }
];


class Rooms extends Component {
    state = {
        loading: false,
        loadingStepOne: false,
        loadingStepTwo: false,
        currentStep: 0,
        hotelsOptions: [],
        selectedHotel: [],
        roomsData: []
    }

    userHotels = [];

    loadData() {
        this.setState({loading: true});
        this.setHotelsMenu();
        this.setState({loading: false});
    }

    setHotelsMenu() {
        const enabledHotels = this.props.hotels;
        var hotels = [];

        for (var i in enabledHotels) {
            hotels.push(<Option key={i}>{enabledHotels[i]}</Option>);
        }

        this.setState({hotelsOptions: hotels});
    }

    selectHotelHandler = (value) => {
        this.setState({selectedHotel: value});
    }

    goToSecondStep = async () => {
        this.setState({ loading: true });

        API.get('benackoAPI', '/rooms?hotelId=' + this.state.selectedHotel)
            .then( response => {
                var rooms = [];

                response.forEach( room => {
                    rooms.push({
                        key: room.id,
                        name: room.name,
                        status: room.roomStatusId
                    });
                });

                this.setState({ roomsData: rooms, currentStep: 1, loading: false });
            })
            .catch( errRooms => {
                console.log('Error loading rooms: ', errRooms);

                this.setState({ loading: false });
            });
    }

    goPreviousStep = () => {
        const newStep = this.state.currentStep - 1;
        this.setState({currentStep: newStep});
    }

    componentDidMount() {
        this.loadData();
    }

    render() {
        const stepOne = (
            <Spin spinning={this.state.loading} size="large">
                <Row style={{width: 600}}>
                    <Select
                        style={{width: '100%'}}
                        placeholder="Selecciona el hotel"
                        onChange={this.selectHotelHandler}
                    >
                        {this.state.hotelsOptions}
                    </Select>
                </Row>
                <Row style={{ marginTop: 20 }}>
                    <Button type="primary" onClick={this.goToSecondStep} disabled={(this.state.selectedHotel.length <= 0) ? true : false}>Siguiente</Button>
                </Row>
            </Spin>
        );

        const stepTwo = (
            <Spin spinning={this.state.loading} size="large">
                <Row style={{width: 600}}>
                    <Table 
                        style={{width: '100%'}}
                        columns={columns} 
                        dataSource={this.state.roomsData}
                        onRow={ (record) => {
                            return {
                                onClick: () => {
                                    this.props.history.push('/app/rooms/details/' + record.key);
                                }
                            };
                        }}
                        size="middle" 
                        pagination={{
                            pageSize: 8,
                            showTotal: (total) => `Total: ${total} habitaciones`
                        }}
                        locale={{
                            emptyText: 'No se encontraron habitaciones'
                        }} 
                        loading={{
                            spinning: this.state.loadingStepTwo,
                            delay: 500,
                            size: 'large'
                        }}/>
                </Row>
                <Row style={{ marginTop: 40 }}>
                    <ButtonGroup>
                        <Button type="primary" onClick={this.goPreviousStep}>Regresar</Button>
                    </ButtonGroup>
                </Row>
            </Spin>
        );

        let renderedStep = null;
        if (this.state.currentStep === 0) {
            renderedStep = stepOne;
        }

        if (this.state.currentStep === 1) {
            renderedStep = stepTwo;
        }

        return (
            <Layout>
                <Content style={{width: '100%', margin: 0, padding: 0, background: '#fff', paddingTop: 15, paddingLeft: 20}}>
                    <Steps size="small" current={this.state.currentStep}>
                        <Step title="Seleccionar hotel" />
                        <Step title="Habitaciones" />
                    </Steps>
                    <Row style={{width: '100%', marginTop: 50}}>
                        <Spin spinning={this.state.loading} size="large">
                        <Col span={20} offset={2}>
                            {renderedStep}
                        </Col>
                        </Spin>
                    </Row>
                </Content>
            </Layout>
        );
    }
}

const mapStateToProps = state => {
    return {
        hotels: state.auth.hotels
    };
};


export default connect(mapStateToProps, null)(Rooms);