import React, { Component } from 'react';
import { API } from 'aws-amplify';
import { Button, Layout, Row, Card } from 'antd';
import { LeftOutlined } from '@ant-design/icons';

import DisableModal from '../../../components/UI/Modals/DisableModal/DisableModal';

const { Header, Content } = Layout;
const ButtonGroup = Button.Group


class Detail extends Component {
    state = {
        loading: true,
        modalVisible: false,
        modalConfirmLoading: false,
        permissionName: "",
        permissionCreateDate: null,
        permissionEnabled: false
    }

    loadData() {
        this.setState({loading: true});
        const id = this.props.match.params.id;

        API.get('benackoAPI', '/room-permissions/' + id)
        .then( response => {
            this.setState({
                loading: false,
                permissionName: response.Item.name,
                permissionCreateDate: new Date(response.Item.createdOn).toLocaleString(),
                permissionEnabled: response.Item.enabled
            });
        })
        .catch( error => {
            console.log(error);
            this.setState({loading: false});
        });
    }

    componentDidMount() {
        this.loadData();
    }

    goBackHandler = () => {
        this.props.history.goBack();
    }

    goEditHandler = () => {
        this.props.history.push('/app/rooms/permissions/details/' + this.props.match.params.id + '/edit')
    }

    showDisableModal = () => {
        this.setState({modalVisible: true});
    }

    disablePermission = () => {
        this.setState({modalConfirmLoading: true});
        
        const id = this.props.match.params.id;

        API.del('benackoAPI', '/room-permissions/' + id)
        .then(() => {
            this.setState({
                modalVisible: false,
                modalConfirmLoading: false,
            });

            this.props.history.goBack();
        })
        .catch(function (error) {
            console.log(error);

            this.setState({
                modalVisible: false,
                modalConfirmLoading: false,
            });
        });
    }

    closeDisableModal = () => {
        this.setState({modalVisible: false});
    }

    render() {
        return(
            <Layout>
                <Header style={{ background: '#fff', padding: 0 }}>
                    <Row>
                        <ButtonGroup>
                            <Button type="primary" onClick={this.goBackHandler}>
                                <LeftOutlined />Back
                            </Button>
                            <Button type="primary" onClick={this.goEditHandler} disabled={!this.state.permissionEnabled}>
                                Edit
                            </Button>
                            <Button type="danger" onClick={this.showDisableModal} disabled={!this.state.permissionEnabled}>
                                Disable
                            </Button>
                        </ButtonGroup>
                    </Row>
                </Header>
                <Content style={{ margin: 0, padding: 0, background: '#ffff', paddingTop: 15 }}>
                    <Card loading={this.state.loading} bordered={false} title={this.state.permissionName}>
                        <p><strong>Created on:</strong> {this.state.permissionCreateDate}</p>
                        <p><strong>Status:</strong> {(this.state.permissionEnabled) ? "Enabled" : "Disabled"}</p>
                    </Card>
                </Content>
                <DisableModal 
                    visible={this.state.modalVisible}
                    confirmLoading={this.state.modalConfirmLoading}
                    onCancel={this.closeDisableModal}
                    onOk={this.disablePermission}
                    modalText="Are you sure you want to disable the room permission?"
                />
            </Layout>
        );
    }
}

export default Detail;