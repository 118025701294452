import React, { Component } from 'react';
import { connect } from 'react-redux';
import { API, Auth } from 'aws-amplify';
import { Button, Form, Input, InputNumber, Layout, Row, Col, Select, Spin } from 'antd';
import { LeftOutlined } from '@ant-design/icons';

const { Header, Content } = Layout;
const FormItem = Form.Item;
const ButtonGroup = Button.Group;
const Option = Select.Option;


function hasErrors(fieldsError) {
    return Object.keys(fieldsError).some(field => fieldsError[field]);
}


class Create extends Component {
    state = {
        loading: false,
        hotelsOptions: [],
        roomTypeOptions: []
    }

    idToken = '';

    loadData() {
        this.setHotelsMenu();
    }

    componentDidMount() {
        this.props.form.validateFields();

        Auth.currentSession()
            .then( response => {
                this.idToken = response.idToken.jwtToken;

                this.loadData();
            })
            .catch( error => {
                console.log(error);
            });
    }

    createPromotionHandler = (event) => {
        event.preventDefault();

        this.setState({loading: true});
        
        this.props.form.validateFields((err, values) => {
            if (!err) {

                const beautifiedName = values.name.trim().toLowerCase();

                API.post('benackoAPI', '/room-promotions', {
                    body: {
                        name: beautifiedName,
                        roomTypeId: values.roomType,
                        price: values.price,
                        daysCondition: values.daysCondition,
                        hotelId: values.hotel,
                    },
                    headers: {
                        Authorization: this.idToken
                    }
                })
                .then(() => {
                    this.setState({loading: false});
                    this.props.history.goBack();
                })
                .catch(function (error) {
                    console.log(error);
                });
            }
        });
    }

    goBackHandler = () => {
        this.props.history.goBack();
    }

    setHotelsMenu() {
        const enabledHotels = this.props.hotels;
        var hotels = [];

        for (var i in enabledHotels) {
            hotels.push(<Option key={i}>{enabledHotels[i]}</Option>);
        }

        this.setState({hotelsOptions: hotels});
    }

    loadRoomTypes = (optionSelected) => {
        API.get('benackoAPI', '/room-types?hotelId=' + optionSelected, {
                headers: {
                    Authorization: this.idToken
                }
            })
            .then( response => {
                var options = [];

                response.forEach( type => {
                    options.push(<Option key={type.id}>{type.name}</Option>);
                });

                this.setState({ roomTypeOptions: options, loading: false });
            })
            .catch( errRoomTypes => {
                console.log('Error loading room types:', errRoomTypes);
                this.setState({ loading: false });
            });
    }

    render() {
        const { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched } = this.props.form;

        // Only show error after a field is touched.
        const hotelError = isFieldTouched('hotel') && getFieldError('hotel');
        const roomTypeError = isFieldTouched('roomType') && getFieldError('roomType');
        const nameError = isFieldTouched('name') && getFieldError('name');
        const priceError = isFieldTouched('price') && getFieldError('price');
        const daysConditionError = isFieldTouched('daysCondition') && getFieldError('daysCondition');

        return(
            <Layout>
                <Header style={{ background: '#fff', padding: 0 }}>
                    <Row>
                        <ButtonGroup>
                            <Button type="primary" onClick={this.goBackHandler}>
                                <LeftOutlined />Back
                            </Button>
                        </ButtonGroup>
                    </Row>
                </Header>
                <Content style={{ margin: 0, padding: 0, background: '#ffff' }}>
                    <Spin spinning={this.state.loading} size="large">
                        <Form onSubmit={this.createPromotionHandler} className="login-form">
                            <Row style={{marginTop: 30}}>
                                <Col span={20} offset={2}>
                                    <p>Hotel:</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={20} offset={2}>
                                    <FormItem
                                        validateStatus={hotelError ? 'error' : ''}
                                        help={hotelError || ''}
                                    >
                                        {getFieldDecorator('hotel', {
                                            rules: [{ required: true, message: 'Selecciona un hotel' }]
                                        })(
                                            <Select onChange={this.loadRoomTypes}>
                                                {this.state.hotelsOptions}
                                            </Select>
                                        )}
                                    </FormItem>
                                </Col>
                            </Row>
                            <Row style={{marginTop: 30}}>
                                <Col span={20} offset={2}>
                                    <p>Tipo de habitación:</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={20} offset={2}>
                                    <FormItem
                                        validateStatus={roomTypeError ? 'error' : ''}
                                        help={roomTypeError || ''}
                                    >
                                        {getFieldDecorator('roomType', {
                                            rules: [{ required: true, message: 'Selecciona un tipo de habitación' }]
                                        })(
                                            <Select>
                                                {this.state.roomTypeOptions}
                                            </Select>
                                        )}
                                    </FormItem>
                                </Col>
                            </Row>
                            <Row style={{marginTop: 50}}>
                                <Col span={20} offset={2}>
                                    <p>Nombre:</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={20} offset={2}>
                                    <FormItem
                                        validateStatus={nameError ? 'error' : ''}
                                        help={nameError || ''}
                                    >
                                        {getFieldDecorator('name', {
                                            rules: [{ required: true, message: 'Introduzca el nombre de la promoción' }],
                                        })(
                                            <Input prefix={<TableOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Nombre" />
                                        )}
                                    </FormItem>
                                </Col>
                            </Row>
                            <Row style={{marginTop: 10}}>
                                <Col span={20} offset={2}>
                                    <p>Precio:</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={20} offset={2}>
                                    <FormItem
                                        validateStatus={priceError ? 'error' : ''}
                                        help={priceError || ''}
                                    >
                                        {getFieldDecorator('price', {
                                            rules: [{ required: true, message: 'Introduce un precio' }],
                                        })(
                                            <InputNumber min={0} prefix={<TableOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Precio" />
                                        )}
                                    </FormItem>
                                </Col>
                            </Row>

                            <Row style={{marginTop: 10}}>
                                <Col span={20} offset={2}>
                                    <p>Número de días:</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={20} offset={2}>
                                    <FormItem
                                        validateStatus={daysConditionError ? 'error' : ''}
                                        help={daysConditionError || ''}
                                    >
                                        {getFieldDecorator('daysCondition', {
                                            rules: [{ required: true, message: 'Introduce un número de días' }],
                                        })(
                                            <InputNumber min={0} prefix={<TableOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Días" />
                                        )}
                                    </FormItem>
                                </Col>
                            </Row>
                            <Row style={{marginTop: 20}}>
                                <Col span={4} offset={2}>
                                    <FormItem>
                                        <Button type="primary" 
                                            htmlType="submit" 
                                            disabled={hasErrors(getFieldsError())} className="login-form-button"
                                        >
                                            Crear
                                        </Button>
                                    </FormItem>
                                </Col>
                            </Row>
                        </Form>
                    </Spin>
                </Content>
            </Layout>
        );
    }
}

const mapStateToProps = state => {
    return {
        hotels: state.auth.hotels
    };
};


export default connect(mapStateToProps, null)(Create);