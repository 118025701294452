import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Row, Col, Input, Table, Space } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import ReloadButton from '../../../components/UI/Buttons/ReloadButton/ReloadButton';
import CreateButton from '../../../components/UI/Buttons/CreateButton/CreateButton';
import { errorNotification } from '../../../components/UI/Notifications/Notifications';

import UserService from '../../../services/users';
import { disableHotelSelector, enableHotelSelector } from '../../../store/actions';


const UsersList = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const cid = useSelector(state => state.base.cid ?? '');
    const hid = useSelector(state => state.ui.selectedHotel ?? 0);
    const canCreate = useSelector(state => state.base.entitlements?.users?.CanCreateUsers ?? false);

    const [loading, setLoading] = useState(true);
    const [users, setUsers] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [inputValue, setInputValue] = useState('');

    const userColumns = [
        {title: 'Nombre de usuario', dataIndex: 'Username', key: 'Username', defaultSortOrder: 'ascend', sorter: (a,b) => a.Username.localeCompare(b.Username)},
        {title: 'Rol', dataIndex: 'RoleName', key: 'RoleName'}
    ];


    useEffect(() => {
        dispatch(enableHotelSelector());

        return () => { dispatch(disableHotelSelector()) };
    }, []);

    useEffect(() => {
        if (hid !== 0) {
            getUsers();
        }
    }, [hid]);

    useEffect(() => {
        if (users != null && users.length > 0) {
            clearCurrentUsers();
            setFilteredUsers(users);
        }
    }, [users]);

    useEffect(() => {
        if (inputValue !== null && inputValue !== undefined && inputValue !== '') {
            const newUsers = inputValue ? users.filter(i => (i.Username.toLowerCase().indexOf(inputValue) > -1) || (i.RoleName.toLowerCase().indexOf(inputValue) > -1)) : users;
            setFilteredUsers(newUsers);
        } else {
            setFilteredUsers(users);
        }
    }, [inputValue]);


    const getUsers = () => {
        setLoading(true);

        UserService.getUsers(cid, hid)
            .then(res => {
                setUsers(res);
                setLoading(false);
            })
            .catch(err => {
                errorNotification(err);
                setLoading(false);
            });
    };

    const clearCurrentUsers = () => {
        setFilteredUsers([]);
        setInputValue('');
    };

    const onChangeText = (text) => {
        setInputValue(text);
    };

    const goToCreateUser = () => {
        history.push('/app/users/create');
    };
    const goToUserDetails = (id) => {
        history.push('/app/users/details/' + id);
    };

    return (
        <Row style={{display: 'flex', flexFlow: 'column', width: '100%'}}>
            <Row style={{width: '100%', height: 40, marginBottom: 40}}>
                <Col span={12} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <Input
                        size="middle"
                        prefix={<SearchOutlined />}
                        placeholder={'Buscar'}
                        allowClear={true}
                        value={inputValue}
                        onChange={(ev) => onChangeText(ev.target.value)}
                    />
                </Col>
                <Col offset={10} span={2} style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                    <Space>
                        <CreateButton disabled={!canCreate} onClick={goToCreateUser} loading={loading} />
                        <ReloadButton onClick={getUsers} loading={loading} />
                    </Space>
                </Col>
            </Row>

            <Row style={{width: '100%', height: '100%', flexGrow: 1, marginBottom: 20}}>
                <Col span={24}>
                    <Table
                        style={{cursor: 'pointer'}}
                        size={'small'}
                        bordered={true}
                        loading={loading}
                        columns={userColumns}
                        dataSource={filteredUsers}
                        pagination={false}
                        rowKey={'Key'}
                        scroll={{y: '100%'}}
                        locale={{
                            emptyText: 'No se encontraron datos',
                            cancelSort: 'Haz clic para cancelar la ordenación',
                            triggerAsc: 'Haz clic para ordenar ascendentemente',
                            triggerDesc: 'Haz clic para ordenar descendentemente'
                        }}
                        onRow={(row) => ({
                            onClick: () => goToUserDetails(row.Key)
                        })}
                    />
                </Col>
            </Row>
        </Row>
    );
};

export default UsersList;