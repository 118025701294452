import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Row } from 'antd';

import UtilityEvents from './UtilityEvents/UtilityEvents';
import { useDispatch } from 'react-redux';
import { clearMaintenanceData } from '../../../store/actions';


const UtilityDetails = () => {
    const dispatch = useDispatch();
    const util = useLocation().search;
    const [utilityType, setUtilityType] = useState(null);

    useEffect(() => {

        return () => {
            dispatch(clearMaintenanceData());
        };
    }, []);

    useEffect(() => {
        setUtilityType(util.substring(6));
    }, [util]);


    return (
        <Row style={{display: 'flex', flexFlow: 'column', height: '100%'}}>
            <UtilityEvents utility={utilityType} />
        </Row>
    );
}

export default UtilityDetails;