import * as actions from './actionTypes';
import MaintenanceService from '../../services/maintenance';


//GET CURRENT UTILITIES
export const getCurrentUtilities = (hid = -1) => {
    return dispatch => {
        return new Promise((resolve, reject) => {
            return MaintenanceService.getUtilitiesCurrentValue(hid)
                .then(response => {
                    if (response === null || response === undefined) {
                        dispatch(getCurrentUtilityFail('Invalid response from server'));
                        reject();
                    } else {
                        dispatch(getCurrentUtilitySuccess(response));
                        resolve();
                    }
                })
                .catch(err => {
                    let errorMessage = err.message ?? 'Invalid response from server';
                    dispatch(getCurrentUtilityFail(errorMessage));
                    reject();
                });
        });
    };
};

export const getCurrentUtilitySuccess = (utilities) => {
    return {
        type: actions.GET_CURRENT_UTILITY,
        currentUtilities: utilities
    }
};

export const getCurrentUtilityFail = (error) => {
    return {
        type: actions.GET_CURRENT_UTILITY_FAIL,
        error: error
    }
};


//CREATE UTILITY EVENT
export const createUtilityEvent = (hid = -1, utility, data) => {
    return dispatch => {
        return new Promise((resolve, reject) => {
            return MaintenanceService.createUtilityEvent(hid, utility, data)
                .then(response => {
                    if (response === null || response === undefined) {
                        dispatch(createUtilityEventFail('Invalid response from server'));
                        reject();
                    } else {
                        dispatch(createUtilityEventSuccess(response));
                        resolve();
                    }
                })
                .catch(err => {
                    let errorMessage = err.message ?? 'Invalid response from server';
                    dispatch(createUtilityEventFail(errorMessage));
                    reject();
                });
        });
    };
};

export const createUtilityEventSuccess = (events) => {
    return {
        type: actions.CREATE_UTILITY_EVENT
    }
};

export const createUtilityEventFail = (error) => {
    return {
        type: actions.CREATE_UTILITY_EVENT_FAIL,
        error: error
    }
};


//GET UTILITY EVENTS
export const getUtilityEvents = (hid = -1, utility) => {
    return dispatch => {
        return new Promise((resolve, reject) => {
            return MaintenanceService.getUtilityEvents(hid, utility)
                .then(response => {
                    if (response === null || response === undefined) {
                        dispatch(getUtilityEventsFail('Invalid response from server'));
                        reject();
                    } else {
                        dispatch(getUtilityEventsSuccess(response));
                        resolve();
                    }
                })
                .catch(err => {
                    let errorMessage = err.message ?? 'Invalid response from server';
                    dispatch(getUtilityEventsFail(errorMessage));
                    reject();
                });
        });
    };
};

export const getUtilityEventsSuccess = (events) => {
    return {
        type: actions.GET_UTILITY_EVENTS,
        events: events
    }
};

export const getUtilityEventsFail = (error) => {
    return {
        type: actions.GET_UTILITY_EVENTS_FAIL,
        error: error
    }
};


//CLEAR MAINTENANCE DATA
export const clearMaintenanceData = () => {
    return dispatch => {
        return new Promise((resolve) => {
            dispatch(clearMaintenanceDataComplete());
            resolve();
        });
    };
};

export const clearMaintenanceDataComplete = () => {
    return {
        type: actions.CLEAR_MAINTENANCE_DATA
    }
};
